import { DeleteChildren, DeleteProducts, GetProducts, PromoteChildProduct } from "../../../connection/products";
import { Action, AsyncAction } from "../../../types/actions";
import { Product } from "../../../types/working-model";

export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export function loadProducts(token: string, channel: number): AsyncAction {
	return {
		type: LOAD_PRODUCTS,
		promise: GetProducts(token, channel)
	};
}

export const ADD_PRODUCT = "ADD_PRODUCT";
export function addProduct(product: Product): Action {
	return {
		type: ADD_PRODUCT,
		payload: product
	};
}

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export function updateProduct(product: Product): Action {
	return {
		type: UPDATE_PRODUCT,
		payload: product
	};
}

export const UPDATE_MULTIPLE_PRODUCTS = "UPDATE_MULTIPLE_PRODUCTS";
export function updateMultipleProducts(products: Product[]): Action {
	return {
		type: UPDATE_MULTIPLE_PRODUCTS,
		payload: products
	};
}

export const TOGGLE_PRODUCT_CHECK = "TOGGLE_PRODUCT_CHECK-Product-Reducer";
export function toggleProductCheck(id: number): Action {
	return {
		type: TOGGLE_PRODUCT_CHECK,
		payload: id
	};
}

export const CHECK_ALL_PRODUCTS = "CHECK_ALL_PRODUCTS-Product-Reducer";
export function checkAllProducts(idList: number[]): Action {
	return {
		type: CHECK_ALL_PRODUCTS,
		payload: idList
	};
}

export const CLEAR_ALL_PRODUCTS = "CLEAR_ALL_PRODUCTS-Product-Reducer";
export function clearAllProducts(): Action {
	return {
		type: CLEAR_ALL_PRODUCTS,
		payload: null
	};
}

export const TOGGLE_TAG_FILTER = "TOGGLE_TAG_FILTER-Product-Reducer";
export function toggleTagFilter(tag: string): Action {
	return {
		type: TOGGLE_TAG_FILTER,
		payload: tag
	};
}

export const CLEAR_ALL_TAGS = "CLEAR_ALL_TAGS-Product-Reducer";
export function clearAllTags(): Action {
	return {
		type: CLEAR_ALL_TAGS,
		payload: null
	};
}

export const DELETE_PRODUCTS = "DELETE_PRODUCTS-Product-reducer";
export function deleteProducts(
	productIDs: number[],
	token: string,
	channel: number
): AsyncAction {
	return {
		type: DELETE_PRODUCTS,
		promise: DeleteProducts(productIDs, token, channel)
	};
}

export const DELETE_CHILD_PRODUCTS = "DELETE_CHILD_PRODUCTS";
export function deleteChildProducts(
	token: string,
	event_id: number,
	module_ids: number[]
): AsyncAction {
	return {
		type: DELETE_CHILD_PRODUCTS,
		promise: DeleteChildren(token, module_ids, event_id)
	};
}

export const CLEAR_PRODUCTS_ERROR = "CLEAR_PRODUCTS_ERROR=Products-reducer";
export function clearProductsError(): Action {
	return {
		type: CLEAR_PRODUCTS_ERROR,
		payload: null
	};
}

export const PROMOTE_CHILD_PRODUCT = "PROMOTE_CHILD_PRODUCT";
export function promoteChildProduct(token: string, channel: number, module_ids: number[]): AsyncAction {
	return {
		type: PROMOTE_CHILD_PRODUCT,
		promise: PromoteChildProduct(token, channel, module_ids)
	};
}
