import { Get, Post } from "./helpers";
import { IvsStream } from "../types/working-model";

export function StartStream(token: string, session_uuid: string): Promise<any> {
	return Post(`/v3/admin/events/session/ivs/stream/start/${session_uuid}`, token, {});
}

export function StopStream(token: string, session_uuid: string): Promise<any> {
	return Post(`/v3/admin/events/session/ivs/stream/stop/${session_uuid}`, token, {});
}

export function GetStream(token: string, session_uuid: string, language: string): Promise<IvsStream> {
	return Get(`/v3/admin/events/session/ivs-stream/${session_uuid}/${language}`, token);
}