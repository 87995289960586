import { UniqueIdentifier } from "@dnd-kit/core";

import { eCDNData, eCDNTypes, KollectiveConfiguration, eCDNConfiguration } from './ecdn';
import { LeaderboardSettings } from "../components/admin/events/leaderboard/types";
import { ICONS } from "../components/general-ui/icon";
import { TIconName } from "../components/general-ui/icon/icon.types";
import { HeaderAllTemplates } from "./template-layouts";
import { GDPRSettings } from "./gdpr";
import * as ThemePackTypes from "./theme-packs";

export enum EBreakoutRoomStatus {
	deleted = 0,
	active = 1,
}

export interface PresenterAuthType {
	channelId: number;
	authType: AuthType;
	auth_integration: ChannelSSOIntegration | ChannelOauthIntegration | null;
}

export enum AuthConsumer {
	Greenroom = 'Greenroom',
	Platform = 'Platform',
}

export interface OauthFlowToken {
	user: number;
	email: string;
	channels: number[];
	authConsumer: AuthConsumer;
	authType: AuthType;
}

export interface WorkingPasscodeList {
	type: PASSCODE_LIST_TYPE;
	name: string;
	max_uses: number;
	master_passcode: string;
	url: string;
}

export interface ConsumerRedirectConfig {
	[key: string]: {
		allowedRedirects: string[],
		defaultRedirect: string,
	}
}

export interface MemberProps {
	member: PasscodeListMemberType;
	type: number;
	passcodeListId: number;
	fromEventView: boolean;
	fromDetailsView: boolean;
	setDeletePasscodesModalOpen?: (isOpen: boolean) => void;
	mlDownload?: (member: PasscodeListMemberType) => Promise<void>;
}

export interface ListItemProps {
	member: PasscodeListMemberType;
	type: number;
	magicLink?: MagicLink;
}
export interface MagicLink {
	code: string;
	event_uuid: string;
	passcode_list_member: number;
	passcode_list: number;
	email: string;
	passcode: string | null;
	uses: number;
	created_date: string;
	modified_date: string | null;
	last_use: string | null;
	imported_profile: {
		first_name: string | null;
		last_name: string | null;
	} | null;
	first_name: string | null;
	last_name: string | null;
	event: string;
	magic_link: string;
	status: number;
}

export enum EMagicLinkStatus {
	deleted = 0,
	active,
	used
}

export interface GoogleMeetVideoSettings {
	name: string;
	meetingUri: string;
}

export interface ZoomVideoSettings {
	id: number;
	enabled: boolean;
	join_url: string;
}

export interface BreakoutVideoSettings {
	zoom?: ZoomVideoSettings,
	google_meet?: GoogleMeetVideoSettings
}

export interface BreakoutRoom {
	id?: number;
	uuid: string;
	session: number;
	name: string;
	max_users: number;
	roomAtCapacity?: boolean;
	type: 'interest' | 'random' | 'assigned';
	description?: string;
	image: string | undefined;
	video_settings: any;
	imageUploading: boolean;
	nameFocused: boolean;
	closed: boolean;
	name_translations?: TranslateString;
	description_translations?: TranslateString;
	// New parameters must be optional or nullable
}

export interface BreakoutRoomPatch {
	id: number;
	uuid?: string;
	session: number;
	name?: string;
	max_users?: number;
	type?: 'interest' | 'random' | 'assigned';
	description?: string;
	image?: string | undefined;
	video_settings?: any;
	imageUploading?: boolean;
	nameFocused?: boolean;
	closed?: boolean;
	// New parameters must be optional or nullable
}

export interface ZoomData {
	id: number | string;
	join_url: string;
	enabled: boolean;
	// New parameters must be optional or nullable
}

export interface BreakoutSession {
	hosts: string[];
	create_room_when_full?: boolean;	// Not used when Zoom BRs
	rooms: BreakoutRoom[];
	use_zoom: boolean;
	use_google_meet?: boolean;
	google_meet_code?: string; // passed when creating a new session to get google refresh token
	google_meet_site_url?: string; // needed for decoding the 'code'
	google_meet_use_service_account?: boolean; // use service account for creating meets rather than user account
	// New parameters must be optional or nullable
}

export interface BreakoutSessionHost {
	session: number;
	email: string;
	// New parameters must be optional or nullable
}

export interface CreateSession {
	title: string;
	channel: number;
	translated_title?: { [key: string]: string; };
	start_timestamp?: number;
	end_timestamp?: number;
	languages: string[];
	default_language: string;
	tracks: string[];
	session_type: SessionTypesEnum;
	// Keeping on_demand for backwards compatability, but it's not used anywhere.
	// To completely remove it from the app, we will need to remove it from the database so it doesn't throw a non-null error
	on_demand?: boolean;
	uid?: string;
	module_grouping?: PageModuleGroupModules[];
	breakout_session?: BreakoutSession;
	firesides_hosts?: string[];
	streaming_options: {
		single_stream: boolean;
	};
	replay_on: boolean | null;
	current_language: string;
	custom_url_enabled?: boolean;
	custom_url?: string;
	session_chat_single_language?: boolean;

	/* not a column on the table, reference to table fireside_session_settings */
	fireside_session_settings?: Partial<FiresideSessionSettings>;

	// New parameters must be optional or nullable
}

export interface CreateEventRequest {
	channel: number;
	name: string;
	template: number;
	sessions: CreateSession[];
	homepage: boolean;
	registration_on: boolean;
	event_type?: EventType;
	defaultLanguage?: string;
	event_image: string | null;
	homepage_hidden?: boolean;
	event_description?: string | null;
	is_test_event?: boolean;
	// New parameters must be optional or nullable
}

export interface Dictionary {
	[key: string]: any;
}

export enum SessionVideoTypes {
	greenroom,
	custom
}

export interface SessionVideoCustomizations {
	backgroundColor?: ThemePackTypes.ColorKeys;
	backgroundImage?: string;
	// New parameters must be optional
}

export interface SessionVideo {
	url?: string;
	compiled_url?: string; // url + '/' + fileds.Key
	fields?: {
		Key?: string;
		bucket?: string;
		[key: string]: any;
	};
	type?: SessionVideoTypes;
	customizations?: SessionVideoCustomizations;
	// New parameters must be optional or nullable
}

export enum EBroadcastTypes {
	greenroom = 'greenroom',
	rtmps = 'rtmps',
	embed = 'embed',
	fireside = 'fireside'
}

//database doesn't store everything deeply nested - will need to be assembled to match Session below
export interface DBSession {
	session: number;
	channel: number;
	title: TranslateString;
	description: TranslateString | null;
	default_language: string;
	languages: string[];
	session_type: SessionTypesEnum;
	on_demand?: boolean; // will eventually be removed in place of the session_type
	timestamp: number | null;
	tracks: string[];
	layout?: any;
	moderation?: any;
	video: SessionVideo | null;
	on_demand_video: CreateVideo | null;
	end_timestamp: number | null;
	modules: number[];
	uuid: string;
	// ivs_channel?: IvsChannel;
	module_grouping?: PageModuleGroupModules[];
	broadcast_type: EBroadcastTypes;
	breakout_rooms?: BreakoutRoom[];
	breakout_session_hosts?: any;
	breakout_sessions?: IBreakoutSession[];
	should_record: boolean | null;
	opentok_id: string | null;
	opentok_id_issued_timestamp: number | null;
	enable_feedback_survey?: boolean;
	enable_share?: boolean;
	// New parameters must be optional or nullable
}

export interface DBBrandliveEvent {
	event?: number;
	channel: number;								//client id still in mysql - not migrating
	status: 0 | 1 | 2;							// 0 = deleted, 1 = public, 2 = private
	template: number;
	name: string;
	settings?: any;
	registration_settings?: Registration;
	registration_questions?: number[];
	registration_on: boolean;
	registration_steps?: RegistrationStep[] | null;
	url?: string;
	homepage: number | null;
	sessions: number[];
	uuid: string;
	terms_id: number;
	privacy_policy: number;
	custom_css: string | null;
	social_settings: SocialSettings;
	custom_css_compiled: string | null;
	use_safe_css: boolean; //default false in database, will be defined
	translation_manifests: Record<string, string>;
	is_template: boolean;  // default false in database, will always be defined
	event_image?: string;
	event_description?: string;
	// New parameters must be optional or nullable
}

export interface EventPreview {
	uuid: string;
	password: string;
	url: string;
}

export interface ILogos {
	white: string | null;
	color: string | null;
	gray: string | null;
	favicon: string | null;
	watermark: string | null;
	// New parameters must be optional or nullable
}

// Nullish max_featured values remove the limit such that either all will be shown or whatever the template default is
export type MaxFeaturedType = 3 | 6 | 12 | 24 | null;

export const maxFeaturedOptions: MaxFeaturedType[] = [3, 6, 12, 24, null];

export enum ContentEditors {
	quill = 'quill',
	draft = 'draft',
}

export interface Language {
	label: string;
	code: string;
}

export interface I18NSettings {
	base: string;
}

export interface IFeedbackSurveyText {
	title?: TranslateString;
}

export interface EventSettings {
	design: {
		/** color_theme is the theme used for the color pack, light/dark, etc. or potentially a custom theme set by users  */
		color_theme?: ThemePackTypes.EPaletteModes;
		/** if this is true, then all pages (including sessions) use the event settings color_theme */
		use_project_theme?: boolean;
		colors: ThemePackTypes.ThemePack;
		style: {
			style: DesignStyle;
			shape: DesignShape;
		};
		/** @deprecated in favor of theme packs. Please use design.colors.font_pack, if it doesn't exist you may try and fallback to this. */
		font: ThemePackTypes.FontPack;
		/** @deprecated in favor of theme packs. Please use design.colors.custom_styles, if it doesn't exist you may try and fallback to this. */
		custom?: ThemePackTypes.ICustomDesignSettings;
	},
	branding?: {
		removeBrandliveLogo: boolean;
		customURL: string;
		favicon: string;
	},
	customCSS?: string;
	email?: {
		sender?: string;
		colors?: ThemePackTypes.ThemePack;
		hideBrandliveLogo?: boolean;
		color_palette_mode?: ThemePackTypes.EPaletteModes;
		font?: ThemePackTypes.SafeFonts;
		detachedEmailFromEventThemePack?: boolean;
	},
	event_navbar?: EventNavbar;
	display_session_footer?: boolean;
	display_sessions?: boolean;
	display_landing_page?: boolean;
	display_homepage?: boolean;
	max_featured_sessions?: MaxFeaturedType;
	max_featured_speakers?: MaxFeaturedType;
	max_featured_products?: MaxFeaturedType;
	pageGating?: PageGating;
	personalizedAttendeeList: boolean;
	show_add_to_cal_btn?: boolean;
	personalizedAttendeeListModal: boolean;
	vodOrder?: number[] | null;
	content_editor?: ContentEditors;
	attendeeInPersonEnabled?: boolean;
	i18n?: I18NSettings;
	translation_version?: number;
	display_session_language_dropdown?: boolean;
	disable_session_thumbnails?: boolean;
	leaderboard_settings?: LeaderboardSettings;
	feedback_survey_text?: IFeedbackSurveyText;
	gdpr?: GDPRSettings;
	description?: string;
	template?: string;
	is_module_grouping_v2?: boolean;
	is_registration_v2?: boolean;
	invert_display_agenda_toggle?: boolean;
	tracks_translations?: IEventTracksTranslations;
	pm_managed?: boolean;
	// New parameters must be optional or nullable
}

interface IEventTracksTranslations {
	[key: string]: TranslateString;
}

//the main event object that holds all data (live_chats analog)
export interface BrandliveEvent {
	event: number;
	channel: number; //client id still in mysql - not migrating
	status: 0 | 1 | 2; // 0 = deleted, 1 = public, 2 = private
	template: Template;
	name: string;
	settings: EventSettings;
	registration_settings?: Registration;
	// the original use of BrandliveEvent.registration_questions was deprecated
	// it is now being used to consolidate for reference all questions asked during registration and all fields shown in user profiles
	registration_questions: RegistrationQuestion[];
	registration_steps?: RegistrationStep[];
	required_registration_questions: number[] | null;
	registration_on: boolean;
	homepage: EventHomepage | null;
	sessions: Session[];
	uuid: string;
	created_date: Date;
	terms_id: number;
	privacy_policy: number;
	sessionsPreviews?: SessionPreview[];
	custom_css: string | null;
	channel_settings?: any;
	social_sharing?: {
		image?: string;
		description?: string;
	};
	playbackUrls?: Dictionary;
	channel_logos?: ILogos;
	favicon?: string | null;
	paidSessions?: string[];
	custom_pages?: CustomPage[];
	secondaryVideos?: Dictionary;
	social_settings?: SocialSettings;
	custom_css_compiled: string | null;
	use_safe_css: boolean; // default false in database, will always be defined
	event_groups?: string[];
	modified_at: null | string;
	translation_manifests: Record<string, string>;
	tags?: IEventTag[];
	automatic_session_filter_by_user_language?: boolean,
	event_preview: EventPreview | null;
	is_preview: boolean | null;
	channel_event_icons?: ChannelCustomizedIcon[];
	is_template?: boolean;
	associated_event_groups?: EventGroupAssociation[];
	channel_features: ChannelFeaturesEnum[];
	event_type?: EventType;
	event_image?: string;
	event_description?: string;
	template_theme?: ITemplateEventTheme;
	is_module_grouping_v2?: boolean;
	announcements?: AnnouncementDB[];
	dma_id?: string | null;
	is_publish_locked?: boolean;
	is_test_event?: boolean;
	ecdn_data?: eCDNData[];
	ecdn_configuration?: eCDNConfiguration;
	// New parameters must be optional or nullable
}

export interface ITemplateEventTheme {
	name: string;
	preview_image?: string;
}

export type BrandliveEventListItem = Pick<BrandliveEvent, 'event' | 'name' | 'uuid' | 'registration_on' | 'required_registration_questions' | 'created_date'>;

export interface CreateEvent extends BrandliveEvent {
	event: never;
}

export enum EventListSortType {
	dateAsc = 'dateAsc',
	dateDesc = 'dateDesc',
	alphaAsc = 'alphaAsc',
	alphaDesc = 'alphaDesc',
	published = 'published',
	unpublished = 'unpublished',
	registrationAsc = 'registrationAsc',
	registrationDesc = 'registrationDesc',
	eventTypeAsc = 'eventTypeAsc',
	eventTypeDesc = 'eventTypeDesc',
	sessionCountAsc = 'sessionCountAsc',
	sessionCountDesc = 'sessionCountDesc'
}

export interface EventsTotals {
	live: number;
	ended: number;
	upcoming: number;
	on_demand: number;
	total: number;
	on_demand_including_ended_sessions: number;
	test_events: number;
	total_template_events?: number;
}

export interface EventGroup {
	uuid: string;
	name: string;
	channel?: number;
	event_uuids: string[];
	adminIds: number[];
}

export interface EventGroupAssociation {
	name?: string;
	channel?: number;
	id?: number;
	created_at?: string;
	group_uuid: string;
	event_uuid: string;
}

export interface SocialSettings {
	directory: DirectorySettings;
	profiles: ProfileSettings;
	messaging: boolean;
	topicGroupChats: boolean;
	eventChatChannels: IEventChatChannel[];
	// New parameters must be optional or nullable
}

export interface DirectorySettings {
	isOn: boolean;
	allowBookmarking: boolean;
	allowFilters: boolean;
	filters: Array<DirectoryFilter>;
	// New parameters must be optional or nullable
}

export interface DirectoryFilter {
	registrationQuestionId: number;
	// New parameters must be optional or nullable
}

export interface ProfileSettings {
	isOn: boolean; // Controls if profile displays are accessible (popup, page)
	displayAvatar: boolean; // Determine if avatar should be displayed globally for event
	displayType: ProfileDisplayType;
	sections: Array<ProfileSection>;
	// New parameters must be optional or nullable
}

export enum ProfileDisplayType {
	popup,
	page
}

export enum EProfileSectionTypes {
	GeneralInformation,
	About,
	Topics,
	Custom,
}

export interface ProfileSection {
	uuid: string;
	isOn: boolean;
	edited: boolean; // Should match registration steps until edited
	title: TranslateString;
	fields: Question[]; // Registration Question IDs
	type: EProfileSectionTypes;
}

export interface Question {
	questionKey: number;
	icon?: string;
}

export interface SelectedBrandliveEvent extends BrandliveEvent {
	_sessions: Session[] | null;
	_homepage: EventHomepage | null;
	// New parameters must be optional or nullable
}

export interface SessionPreview {
	title: TranslateString;
	main_event_banner: PageModule;
	uuid: string;
	timestamp: number;
	end_timestamp: number;
	session_type: SessionTypesEnum;
	languages: string[];
	tracks: string[];
	image?: string | null;
	description?: TranslateString;
	session: number;
	addedToMyList?: boolean;
	replay_on?: boolean;
	tags?: Session['tags']; /** uuid[] of event tag */
	modules?: PageModule[];
	// New parameters must be optional or nullable
	custom_url_enabled?: boolean;
	custom_url?: string;
	/** @deprecated sessions now follow the event settings.design.color_theme mode */
	session_chat_dark_mode_enabled?: boolean;
	calendar_settings?: {
		content?: {
			[key: string]: { // Language code
				title?: string,
				description?: string
			}
		}
	};
}

export enum RegistrationStepType {
	general = "general",
	profile = "profile",
	avatar = "avatar",
	ticketing = "ticketing",
}
export const REGISTRATION_STEP_NAME_MAP = {
	[RegistrationStepType.general]: "General Info",
	[RegistrationStepType.profile]: "Profile Info",
	[RegistrationStepType.avatar]: "Avatar",
	[RegistrationStepType.ticketing]: "Ticketing",
};


export const REGISTRATION_TAB_URLS_MAP = {
	[REGISTRATION_STEP_NAME_MAP.general]: "general-information",
	[REGISTRATION_STEP_NAME_MAP.avatar]: "avatar",
	[REGISTRATION_STEP_NAME_MAP.profile]: "profile",
	[REGISTRATION_STEP_NAME_MAP.ticketing]: "ticketing",
};

export interface VideoProgressData {
	duration?: number | null;
	playedSeconds: number;
}

export interface TicketingSet {
	name: string;
	price?: number; /** @deprecated */
	currency?: string;  /** @deprecated */
	prices?: GlobalPrice[];
	quantity: number;
	uuid: string;
	description?: string;
	sessions: number[];
	session_uuids: string[];
	name_translations?: TranslateString;
	description_translations?: TranslateString;
	// New parameters must be optional or nullable
}

export interface GlobalPrice {
	price: number;
	currency: string;
	multiplier: number;
	// New parameters must be optional or nullable
}

export interface GlobalCurrency {
	code: string;
	name: string;
	stripe_multiplier: number;
	accepted: boolean;
	// New parameters must be optional or nullable
}

export interface ExchangeResponse {
	base: string;
	last_updated: number;
	conversion_rates: { [country_code: string]: number; };
	// New parameters must be optional or nullable
}

export interface RegistrationStep {
	type: RegistrationStepType;
	isOn: boolean;
	questions?: RegistrationQuestion[];
	ticketing?: TicketingSet[];
	title?: TranslateString;
	styling_override?: TextOverrides;
	// New parameters must be optional or nullable
}

export interface ChannelPermission {
	[key: string]: string[];
}

export interface Profile {
	first_name: string | null;
	last_name: string | null;
	location: string | null;
	picture: string | null;
	bg_image: string | null;
	job_title: string | null;
	phone: string | null;
	temp_phone: string | null;
	[key: string]: any;
	// New parameters must be optional or nullable
}

export enum AdminProfileFields {
	first_name = "first_name",
	last_name = "last_name",
	location = "location",
	picture = "picture",
	bg_image = "bg_image",
	job_title = "job_title",
	phone = "phone",
	temp_phone = "temp_phone",
}

export interface ReducedUser {
	email: string,
	channels_using_sso: number[],
	channelId: number,
	channels?: ChannelPermission | undefined,
}

export interface BLAdmin {
	id: number;
	email: string;
	password?: string;
	channels: ChannelPermission;
	active_channel: number;
	is_super_admin: boolean;
	created_date: Date | string;
	onboarded: string[];
	password_reset_date: Date | string | null;
	profile: Profile;
	verified: boolean;
	groups?: string[];
	// array of channels this admin has been set to use sso
	channels_using_sso?: number[] | null;
	last_login?: string | null;
	dma_acknowledgement_date?: string | null;

	// channels that can be accessed in the admin console by this user 
	// ie: not stuff they can only get to in greenroom
	channels_can_admin?: number[];

	// New parameters must be optional or nullable
}

//this extension is needed to use the connected-users methods built into the socket.io server
//it indexes users by their brandlive_profile
export interface ConnectedAdmin {
	brandlive_profile: number;
	id: number;
	profile: any;
	email: string;
	current_event: string | null;
	socketId?: string;
	// New parameters must be optional or nullable
}

export enum DesignStyle {
	Solid
}

export enum DesignShape {
	Rounded,
	Square,
}

export interface IPostRegisterHomeModule {
	is_on: boolean;
	module_id: number;
}

export interface DBEventHomepage {
	homepage: number;
	header: HomepageHeader;				//lives only within this event - does not require a connection to another collection
	use_header: boolean;					//keep data and on/off separate in case user toggles them on/off we don't want to lose data
	footer: HomepageFooter;
	use_footer: boolean;
	modules: number[];
	post_register_home_modules?: IPostRegisterHomeModule[];
	languages: string[];
	event_main_nav_items?: IHomepageMainNavItems[];
	event_profile_nav_items?: IHomepageProfileNavItems[];
	// New parameters must be optional or nullable
}

export enum EGroupType {
	lobby,
	custom,
}

export interface IEventChatChannel {
	created_at: Date | string;
	title: string | null;
	uuid: string;
	channelImageUrl: string;
	group_type: EGroupType;
	// New parameters must be optional or nullable
}

/**
 *

 DO NOT reorder these

 If you need to add another module type, add them to the bottom
 we have stuff that depends on this enumerator being in this order

 */
export enum PageModuleType {
	main_event_banner,
	speakers,
	agenda,
	about,
	products,
	videos,
	sponsors,
	callout,
	documents,
	feed,
	embed_widget,
	slideshows,
	questions,
	quizzes,
	survey,
	broadcast,
	description,
	similar_sessions,
	blank,
	people,
	breakout_room,
	accordion,
	comment_box_prompt
}

export const PageModuleEngageMap = {
	survey: PageModuleType.survey,
	poll: PageModuleType.survey,
	quiz: PageModuleType.survey,
	question_prompt: PageModuleType.questions,
	comment_box_prompt: PageModuleType.comment_box_prompt,
	// feedback: PageModuleType.feed
};

// Do not change string names
export enum ModuleGroups {
	Overview = 'Overview',
	QuizzesAndPolls = 'Quizzes and Polls',
	Documents = 'Documents',
	QuestionsAndPosts = 'Questions and Posts',
	Quizzes = "Quizzes",
	Surveys = "Surveys",
	BreakoutRooms = "Breakout Rooms",
	Engage = 'Engage',
	Details = 'Details',
	Extras = 'Extras',
	Credits = 'Credits',
}

export const ModuleGroupingTypes = {
	Breakouts: "breakout",
	Overview: "overview",
	Engage: "engage",
	Extras: "extras",
	Education: "education",
	Details: "details",
	Polls: "polls",
	Documents: "documents",
	Questions: "questions",
	Surveys: "surveys",
	custom: "custom",
	CommentsBox: "comment box"

};

export interface PageModuleGroupModules {
	icon?: string;
	uuid: string;
	name: ModuleGroups | TranslateString;
	is_on: boolean;
	modules: number[]; // array of module ids representing the display order
	type?: string;
	// New parameters must be optional or nullable
}

export const GroupToModuleMappings: { [key: number]: ModuleGroups; } = {
	[PageModuleType.description]: ModuleGroups.Overview,
	[PageModuleType.speakers]: ModuleGroups.Overview,
	[PageModuleType.products]: ModuleGroups.Overview,
	[PageModuleType.similar_sessions]: ModuleGroups.Overview,
	[PageModuleType.quizzes]: ModuleGroups.QuizzesAndPolls,
	[PageModuleType.survey]: ModuleGroups.QuizzesAndPolls,
	[PageModuleType.documents]: ModuleGroups.Documents,
	[PageModuleType.questions]: ModuleGroups.QuestionsAndPosts,
	[PageModuleType.comment_box_prompt]: ModuleGroups.QuestionsAndPosts
};

interface ModuleMapType {
	[key: number]: string;
}

export const ModuleMap: ModuleMapType = {
	[PageModuleType.main_event_banner]: "Main Banner",
	[PageModuleType.speakers]: "Speakers",
	[PageModuleType.agenda]: "Sessions & Content",
	[PageModuleType.about]: "About",
	[PageModuleType.videos]: "Videos",
	[PageModuleType.callout]: "Callout",
	[PageModuleType.sponsors]: "Sponsors",
	[PageModuleType.broadcast]: "Broadcast",
	[PageModuleType.questions]: "Questions",
	[PageModuleType.quizzes]: "Quizzes",
	[PageModuleType.survey]: "Surveys",
	[PageModuleType.products]: "Products",
	[PageModuleType.slideshows]: "Slideshows",
	[PageModuleType.documents]: "Documents",
	[PageModuleType.similar_sessions]: "Suggested",
	[PageModuleType.feed]: "Feed",
	[PageModuleType.embed_widget]: "Embed Widget",
	[PageModuleType.description]: "Description",
	[PageModuleType.blank]: "Blank Section",
	[PageModuleType.people]: "People",
	[PageModuleType.accordion]: "Accordion",
	[PageModuleType.comment_box_prompt]: "Comment Box",
};

export interface SessionDisplayOptions {
	[identifer: string]: number[];
}

export interface SessionDisplayAll {
	liveSessions: boolean;
	onDemandSessions: boolean;
	[identifer: string]: boolean;
}

export interface ModuleDisplayInfo {
	session_display_options?: SessionDisplayOptions;
	content_track_order?: string[];
	session_display_all?: SessionDisplayAll;
}

export interface PageModule {
	id?: number;
	type: PageModuleType;
	content: any;
	is_on: boolean;
	content_modules?: any[];
	modules?: any[];
	is_custom?: boolean;
	styling_overrides?: {
		background?: {
			color: string; //keyof ColorOptions with "-background" appended
			//eg.("backgroundColor-background", "accentColor-background", "customColor0-background")
			image: {
				is_on: boolean,
				src: string;
			};
		};
		content?: {
			start_time?: TextOverrides;
			title?: TextOverrides;
			description?: TextOverrides;
			custom_heading?: TextOverrides;
			modules?: ModuleOverrides;
			registration_page_stream_date?: TextOverrides;
			registration_page_speakers_lineup?: TextOverrides;
		};
	};
	ordinal?: number;
	is_edited: boolean | null;
	module_display_info?: ModuleDisplayInfo | null;
	// New parameters must be optional or nullable
}

export interface ModuleOverrides {
	title?: TextOverrides;
	description?: TextOverrides;
	custom_heading?: TextOverrides;

	primary_button: ButtonOverrides;
	secondary_button: ButtonOverrides;
	dropdowns: ButtonOverrides;
	// New parameters must be optional or nullable
}

export interface TextOverrides {
	color?: string; //keyof ColorOptions with "-color" appended
	//eg.("bodyTextColor-color", "accentColor-color", "customColor0-color")
	lineHeight?: string; //string with "line-height-" prepended
}

export interface ButtonOverrides {
	backgroundColor: string; //keyof ColorOptions with "-background" appended
	//eg.("backgroundColor-background", "accentColor-background", "customColor0-background")
	borderColor: string; //keyof ColorOptions with "-border" appended
	//eg.("borderColor-border", "accentColor-border", "customColor0-border")
	text: TextOverrides;
}

export interface Module {
	module_id?: number;
	channel: number;
	status: ModuleStatus;
	archived: boolean;
	base_language: string;
	created_by: number;
	created_at: Date;
	modified_by: number;
	modified_at: string;
	// New parameters must be optional or nullable
}

export enum ModuleStatus {
	deleted,
	active
}

// languages should eventually use a more fixed type instead of string
export type LanguagesAbbr = 'af' | 'sq' | 'am' | 'ar' | 'hy' | 'az' | 'eu' | 'be' | 'bn' | 'bs' | 'bg' | 'ca' | 'ceb' | 'zh' | 'zh-TW' | 'co' | 'hr' | 'cs' | 'da' | 'nl' | 'en' | 'eo' | 'et' | 'fi' | 'fr' | 'fy' | 'gl' | 'ka' | 'de' | 'el' | 'gu' | 'ht' | 'ha' | 'haw' | 'he' | 'hi' | 'hmn' | 'hu' | 'is' | 'ig' | 'id' | 'ga' | 'it' | 'ja' | 'jv' | 'kn' | 'kk' | 'km' | 'rw' | 'ko' | 'ku' | 'ky' | 'lo' | 'la' | 'lv' | 'lt' | 'lb' | 'mk' | 'mg' | 'ms' | 'ml' | 'mt' | 'mi' | 'mr' | 'mn' | 'my' | 'ne' | 'no' | 'ny' | 'or' | 'ps' | 'fa' | 'pl' | 'pt' | 'pa' | 'ro' | 'ru' | 'sm' | 'gd' | 'sr' | 'st' | 'sn' | 'sd' | 'si' | 'sk' | 'sl' | 'so' | 'es' | 'su' | 'sw' | 'sv' | 'tl' | 'tg' | 'ta' | 'tt' | 'te' | 'th' | 'tr' | 'tk' | 'uk' | 'ur' | 'ug' | 'uz' | 'vi' | 'cy' | 'xh' | 'yi' | 'yo' | 'zu';

export interface EventHomepage {
	homepage: number;
	header: HomepageHeader;				//lives only within this event - does not require a connection to another collection
	use_header: boolean;					//keep data and on/off separate in case user toggles them on/off we don't want to lose data
	footer: HomepageFooter;
	use_footer: boolean;
	modules: PageModule[];
	post_register_home_modules: PageModule[];
	languages: string[]; // should eventually use LanguagesAbbr instead
	event_main_nav_items?: IHomepageMainNavItems[];
	event_profile_nav_items?: IHomepageProfileNavItems[];
	// New parameters must be optional or nullable
}

export enum EventSessionTabs {
	Live = 'Live',
	OnDemand = 'On demand',
	Breakout = 'Breakout',
	MyList = 'My list'
}

export enum SessionTypesEnum {
	onDemand = 'on-demand',
	broadcast = 'broadcast',
	breakoutRooms = 'breakout-rooms',
	fireside = 'fireside'
}

export enum SessionStatusTypes {
	BeforeLive = 'before-live',
	WaitingRoom = 'waiting-room',
	Live = 'live',
	End = 'end',
}

export interface IBreakoutChatRoom {
	title: string;
	participants_joined: Profile[];
	presenters?: Profile[];
	time_before_switch?: number;
	max_participants?: number;
	// New parameters must be optional or nullable
}

export interface ISessionTypesFeaturesItems {
	has_start_time: boolean;
	name: SessionTypesEnum;
	display_name: string;
	icon_name: string;
	show_set_rooms: boolean;
	description: string;
	// New parameters must be optional or nullable
}

export interface ITranslateLangs {
	[key: string]: string;
}

export interface ISessionTypesFeatures {
	[key: string]: ISessionTypesFeaturesItems;
}

export const SessionTypesFeatures: ISessionTypesFeatures = {
	[SessionTypesEnum.onDemand]: {
		has_start_time: false,
		name: SessionTypesEnum.onDemand,
		display_name: 'On Demand',
		icon_name: ICONS.ON_DEMAND,
		show_set_rooms: false,
		description: "A video on-demand uploaded or embedded for audience viewing at any time"
	},
	[SessionTypesEnum.broadcast]: {
		has_start_time: true,
		name: SessionTypesEnum.broadcast,
		display_name: 'Broadcast',
		icon_name: ICONS.VIEWERS_EYE,
		show_set_rooms: false,
		description: "A one-to-many broadcast session streamed from Greenroom or a 3rd party tool"

	},
	[SessionTypesEnum.breakoutRooms]: {
		has_start_time: true,
		name: SessionTypesEnum.breakoutRooms,
		display_name: 'Breakout rooms',
		icon_name: ICONS.BREAKOUT_ROOMS,
		show_set_rooms: true,
		description: "Interactive 2-way audio and video sessions with rooms for participants to chat"
	},
	[SessionTypesEnum.fireside]: {
		has_start_time: true,
		name: SessionTypesEnum.fireside,
		display_name: 'Fireside',
		icon_name: ICONS.FIRESIDE,
		show_set_rooms: false,
		description: "A hosted broadcast session with functionality for attendees to join the stage and interact with presenters"
	}
};

export interface IvsChannel {	//TODO: Remove everything from here that's not 100% required for the app to function on the front end.
	rtmps_address?: string; // this is made as follows: `rtmps://${session.ivs_channel?.channel?.ingestEndpoint}:443/app/${session.ivs_channel?.streamKey?.value}`
	channel?: {
		arn?: string;
		name?: string;
		latencyMode?: "NORMAL" | "LOW" | string;
		type?: "BASIC" | "STANDARD" | string;
		ingestEndpoint?: string;
		playbackUrl?: string;
		authorized?: boolean;
		tags?: { [key: string]: string; };
		recordingConfiguration?: {
			arn: string;
			name?: string;
			destinationConfiguration: {
				s3?: {
					bucketName: string;
				};
			};
			state: "CREATING" | "CREATE_FAILED" | "ACTIVE" | string;
			tags?: { [key: string]: string; };
		};
	};
	streamKey?: {
		arn?: string;
		value?: string;
		channelArn?: string;
		tags?: { [key: string]: string; };
	};
	// New parameters must be optional or nullable
}

export interface ISessionModeration {
	posts: boolean; // determines if the posts require moderator approval or not
	questions: boolean; // determines if the questions require moderator approval or not
	chat: boolean; // determines if the chat requires moderator approval or not
	use_blocked_words_filter?: boolean; // should use word filter
}

export interface FiresideSessionHosts {
	id?: number;
	session?: number;
	channel?: number;
	email: string;
	type?: string;
	bl_admin?: number | null;
	bl_profile?: number | null;
	timestamp?: string;
	// New parameters must be optional or nullable
}

export interface SessionStreamingOptions {
	test_stream?: boolean;
	single_stream: boolean;
	// New parameters must be optional or nullable
}

export interface Session {
	addedToMyList?: boolean;
	/** @deprecated use custom_url */
	backup_url?: string;
	/** @deprecated use custom_url_enabled */
	backup_url_enabled?: boolean;
	breakout_room_order?: number[];
	breakout_rooms?: BreakoutRoom[];
	breakout_session?: IBreakoutSession;
	breakout_session_hosts?: any;
	broadcast_type: EBroadcastTypes;
	channel: number;
	current_language: string;
	custom_css?: string;
	custom_css_compiled: string | null;
	custom_url_enabled?: boolean;
	custom_url?: string;
	default_language: string; // should eventually use LanguagesAbbr instead
	description: TranslateString | null;
	enable_feedback_survey?: boolean;
	enable_share?: boolean;
	end_timestamp: number | null;
	firesides_hosts: string[];
	hosts?: Profile[];
	image?: string | null;
	is_on?: boolean;
	// ivs_channel?: IvsChannel;
	languages: string[];
	layout: SessionLayout;
	moderation: ISessionModeration | null;
	module_grouping?: PageModuleGroupModules[];
	modules: PageModule[];
	/** @deprecated on_demand will eventually be removed in place of session_type */
	on_demand?: boolean; // will eventually be removed in place of session_type
	on_demand_video: CreateVideo[] | null;
	opentok_id: string | null;
	opentok_id_issued_timestamp: number | null;
	post_broadcast_thumbnail?: string | null;
	pre_broadcast_thumbnail?: string | null;
	reaction_settings?: ReactionSettings;
	replay_on?: boolean;
	roomBackground?: string;
	rooms?: IBreakoutChatRoom[];
	session: number;
	session_chat_dark_mode_enabled: boolean;
	session_chat_enabled: boolean;
	session_chat_single_language?: boolean;
	session_type: SessionTypesEnum;
	should_record: boolean | null;
	streaming_options: SessionStreamingOptions;
	tags?: string[]; /** uuid[] of event tag */
	timestamp: number | null;
	title: TranslateString;
	tracks: string[] | null;
	translate_all?: boolean;
	use_different_thumbnails?: boolean;
	use_safe_css: boolean; // default false in database, will always be defined
	uuid: string;
	video: SessionVideo;
	video_duration?: number;
	video_player_controls_settings?: VideoPlayerControlsSettings;
	waitingRoom?: {
		slides: string[];
		startBefore: number;
	};
	calendar_settings?: {
		auto_send_calendar_invite?: boolean | null;
		content?: {
			[key: string]: { // Language code
				title?: string,
				description?: string
			}
		}
	};
	fireside_session_settings?: FiresideSessionSettings;
	session_chat_style?: SessionTabsChatStyles | null;
	internal_descriptor?: string | null;
	chat_content_settings?: SessionChatContentSettings | null;
	// New parameters must be optional or nullable
}

export interface DeprecatedOnDemandSession extends Omit<Session, 'on_demand_video'> {
	on_demand_video: CreateVideo;
}

export interface SessionLayout {
	/** @deprecated withMenu was used for determining the tab layout, now the tabs field is managing that. Keeping it as to not break previous events */
	withMenu: boolean;
	overlayChat: boolean;
	tabs: SessionTabsLayout;
	styling_overrides?: {
		background?: { /** background color should only apply to tab header portion of page */
			color?: string;
			image?: {
				is_on: boolean,
				src: string;
			};
		},
		content?: any;
		/** session colors override event colors on session pages if they exist */
		colors?: ThemePackTypes.ISessionColors;
	};
	display_viewer_count?: boolean;
	custom_social_links?: CustomSocialLinks
	// New parameters must be optional or nullable
}

export enum SessionTabsLayoutTypes {
	NoTabs = 'No Tabs',
	Horizontal = 'Horizontal',
	Vertical = 'Vertical',
	BelowTheFold = 'Below the Fold',
	AboveTheFold = 'Above the Fold'
}
export enum SessionTabsThemeTypes {
	LightMode = 'Light Mode',
	DarkMode = 'Dark Mode'

}
export enum SessionTabsChatTypes {
	NoChat = 'None',
	Overlay = 'Overlay',
	SidePanel = 'Side Panel'
}
export enum SessionTabsChatStyles {
	Default = 'default',
	Conversational = 'conversational',
	/**  @deprecated */
	HighVolume = 'high_volume', // This has become Default
	/**  @deprecated */
	Standard = 'standard' // This has become Conversational
}

export interface SessionTabsLayout {
	layout: SessionTabsLayoutTypes;
	show_icons: boolean;
	// New parameters must be optional or nullable
}

export interface VideoPlayerControlsSettings {
	color_theme: 'default' | ThemePackTypes.EPaletteModes.Light;
}

export enum ECustomSocialLinks {
	X = 'X',
	LinkedIn = 'LinkedIn',
	Facebook = 'Facebook',
	Share = 'Share'
}

export interface CustomSocialLinks {
	[ECustomSocialLinks.X]?: string;
	[ECustomSocialLinks.LinkedIn]?: string;
	[ECustomSocialLinks.Facebook]?: string;
	[ECustomSocialLinks.Share]?: string;
}


export interface ReactionSettings {
	enabled: boolean;
	show_descriptions?: boolean;
	reactions: ReactionConfig[];
	// New parameters must be optional or nullable
}

export interface ReactionConfig {
	enabled: boolean;
	color: string;
	opacity: number;
	icon?: string; // Defined if non-custom reaction
	url?: string; // S3 url if custom reaction
	name: string;
	description?: string;
	// New parameters must be optional or nullable
}

export interface ReactionConfigWithCount extends ReactionConfig {
	count: number;
}

// Do not change the order of enum Templates
// if creating new templates, append to the end
export enum Templates {
	Apollo = 1, //was "Classic"
	"Studio 54", //was "Bold"
	O2, //was "Fun"
	'Limelight', // Above and Below the fold 
}

export enum TemplateStatus {
	deleted,
	active
}

export interface Template {
	uuid: string;
	template: number;
	name: string;
	description: string;
	channel: number;
	preview_image: string;
	properties: {
		registrationImage?: string;
		/**
		 * @deprecated
		 * All font/colors now comes from ThemePack.
		 * Future templates will strictly be used for UI layouts, not styles.
		 * 
		 * @see ThemePackTypes.ThemePack
		 * */
		design: {
			font: number;
			colors: number;
			// style: //not being used right now
		};
	};
	css?: string | null;
	scss?: string | null;
	created_at?: Date;
	status?: TemplateStatus;
	custom_template?: boolean;
	// New parameters must be optional or nullable
}

export interface RegistrationQuestionEntry {
	field: RegistrationQuestion["registration_question"],
	required: boolean;
	// New parameters must be optional or nullable
}

export enum SingleSignOnTypes {
	Google = "Google",
	Facebook = "Facebook",
	LinkedIn = "LinkedIn",
	SAML = "SAML",
	OIDC = "OIDC",
	oAuth = "oAuth",
	OAUTH2 = "OAUTH2",
}

export enum SurveyCSVType {
	aggregate = 'aggregate',
	pass_fail = 'pass_fail',
	detail = 'detail'
}

export enum MarketingOptInTypes {
	singleOptIn = 'single-opt-in',
	doubleOptIn = 'double-opt-in',
	noOptIn = 'no-opt-in',
}

export interface Registration {
	type: RegistrationTypes;
	enableSocial: boolean;
	singleSignOn: SingleSignOnSettings;
	sessionReminders: boolean;
	registrationConfirmation: boolean; /** toggle Require Email Confirmation */
	sendEmailConfirmation: boolean; /** toggle Send Email Confirmation */
	registration_confirmation_email?: IScheduledEmailCustomizations;
	registration_email_settings?: IScheduledEmailSettings;
	registration_email_timezone?: string;
	postEventFeedbackSurvey: boolean;
	useBlockedDomainEmails: boolean;
	blockedDomainEmails: null | string[];
	requirements: RequirementMap;
	title?: TranslateString;
	description?: TranslateString;
	image?: string;
	logo?: string;
	style?: ERegistrationStyle;
	customPasscodeLabel?: TranslateString;
	customRejectedPasscodeMessage?: TranslateString;
	enableRecaptcha?: boolean;
	enableOverviewContent?: boolean;
	enableSpeakers?: boolean;
	isClosed?: boolean; // does not toggle registration off, but disables registration form for new attendees
	adminInPersonAttendeeModeEnabled?: boolean;
	emailLinkLimit?: number | null; // if null or undefined, email link can be used an unlimited number of times
	marketing_email_options?: MarketingOptInTypes | null;
	externalRegistration?: {
		isOn?: boolean;
		url?: string | null;
		isRSVP?: boolean;
	};
	registration_questions_translations?: IRegistrationQuestionTranslations;
	marked_attended_by_admin?: MarkedAttendedRegistration;
	skipEmailOnDuplicateRegistration?: boolean;
	registration_limit_is_on?: boolean;
	registration_limit?: number | null;
	password_gating_enabled?: boolean;
	// New parameters must be optional or nullable
}

export interface ERegistrationStyle {
	styling_overrides?: PageModule["styling_overrides"];
	layout?: RegistrationLayoutTypes;
	hero_size?: HeroSizes;
}

export type HeroSizes = "small" | "large";
export enum EHeroSizes {
	small = "small",
	large = "large",
}
export type RegistrationLayoutTypes =
	"form-left" |
	"form-right" |
	"above-the-fold-l" |
	"above-the-fold-r" |
	"hero-l" |
	"hero-r" |
	"landing-l" |
	"landing-r" |
	"modal";

export enum ERegistrationLayoutTypes {
	formLeft = "form-left",
	formRight = "form-right",
	aboveTheFoldL = "above-the-fold-l",
	aboveTheFoldR = "above-the-fold-r",
	heroL = "hero-l",
	heroR = "hero-r",
	landingL = "landing-l",
	landingR = "landing-r",
	modal = "modal",

}

export enum ERegistrationLayoutFullNameTypes {
	aboveTheFoldL = "Above the fold L",
	aboveTheFoldR = "Above the fold R",

	heroL = "Hero Image L",
	heroR = "Hero Image R",

	landingL = "Full Page L",
	landingR = "Full Page R",

	modal = "Modal",
}


export type MarkedAttendedRegistration = 'in-person' | '' | null;

export interface IRegistrationQuestionTranslations {
	[questionId: number]: {
		name?: TranslateString;
		placeholder?: TranslateString;
		options?: {
			[optionId: number]: TranslateString;
		}
	}
}

export interface SingleSignOnSettings {
	isOn: boolean;
	displayTop: boolean;
	singleSignOns?: Array<string>;
	// New parameters must be optional or nullable
}

export interface RequirementMap {
	[GateTypes.domain_email]?: Requirement;
	[GateTypes.shared_passcode]?: Requirement;
	[GateTypes.passcode_list]?: Requirement[];
	[GateTypes.ticketing]?: Requirement;
	[GateTypes.custom_page_gating]?: Requirement;
}

export type StepsCompleted = {
	[key in RegistrationStepType]?: number[];
};

export interface RegistrationResponse {
	registration_id: number;
	event: BrandliveEvent["event"];
	brandlive_profile: number | null;
	created: string | Date;
	email: string | null;
	values: { [key: string]: string; };
	legacy_reg_id?: string;
	from_saml: boolean;
	// New parameters must be optional or nullable
}

export enum QuestionType {
	text = "text",
	textarea = "textarea",
	checkbox = "checkbox",
	select = "select",
	numeric = "numeric",
	// date = "date", For Future Use - this type should not be used until fully implemented
	email = "email",
	multiselect = "multiselect",
	customtext = "customtext"
}

export interface RegistrationQuestion {
	registration_question: number;
	channel: number;
	name: string;
	type: QuestionType;
	options: string[] | null;
	global: boolean;
	persistent: boolean;
	default_value: string | number | null;
	required?: boolean;
	name_translation?: TranslateString;
	options_translation?: TranslateString[];
	placeholder?: TranslateString;
	deleted?: boolean;
	is_required?: boolean;
	// New parameters must be optional or nullable
}

export enum RegFieldsEnum {
	first_name = 1,
	last_name = 2,
	email = 3,
	bio = 4,
	job_title = 5,
	company_name = 6,
	location = 7,
	store_name = 8,
	fun_fact = 9,
	passcode = 10,
	comments = 11,
	attend_in_person = 12,
	interests = 17,
	state = 18,
	postal_code = 21,
	phone_number = 38,
	age = 47,
	title = 92,
	country = 1184
}

export interface CreateRegistrationQuestion {
	name: string;
	type: QuestionType;
	options: string[] | null;
	persistent: boolean;
	default_value: string | number | null;
	name_translation?: TranslateString;
	options_translation?: TranslateString[];
	placeholder?: TranslateString;
	global?: boolean;
	is_required?: boolean;
	// New parameters must be optional or nullable
}

export enum GateTypes {
	domain_email = 1,
	shared_passcode,
	passcode_list,
	ticketing,
	custom_page_gating
}

export enum RegistrationTypes {
	none,
	open,
	gated
}

export interface Requirement {
	gatedSessions?: Session["session"][];
	type: GateTypes;
	allowedDomains?: string[];
	sharedPasscode?: string;
	passcodeList?: number[];
	passcodeLists?: any[]; // used in aws and api
	ticketing?: string[];
	editing?: boolean;
	id?: string;
	// New parameters must be optional or nullable
}

export enum AudienceListToggle {
	ALL = 1,
	INVITED,
	UNINVITED
}
export enum PASSCODE_LIST_TYPE {
	EMAILS_ONLY = 0,
	PASSCODES_ONLY,
	EMAILS_AND_PASSCODES,
	MAGIC_LINKS,
}

export interface PasscodeList extends Module {
	passcode_list?: number;
	uuid: string;
	name: string;
	max_uses: number;
	master_passcode: string | null;
	type: PASSCODE_LIST_TYPE;
	member_count: number;
	dma_id?: string | null;
	// New parameters must be optional or nullable
}

export interface PasscodeListMemberType {
	passcode_list_member: number;
	passcode_list: number;
	passcode: string | null;
	created_date: number;
	imported_profile: Record<string, string> | null;
	email: string;
	uses: number;
}

export interface NestedTranslateStrings {
	[languageCode: string]: TranslateString;
}

export interface TranslateString {
	base: string;  //the original string typed in by the creator
	changed: string;
	[key: string]: string | string[];
	//ie: { "en": "Hello World", "sp": "Hola Mundo" }
}

export enum CallToActionType {
	section, //module to link to (e.g. speakers, agenda)
	page, //e.g. registration, termsAndConditions
	url,
	session, // links to selected session
	registrationForm
}

export enum PageLinkType { //if more page links are needed add them here and call-to-action.tsx
	registration = "registration",
	landing = "landing",
	termsAndConditions = "terms-and-conditions",
	privacyPolicy = "privacy-policy",
	homepage = "homepage",
	agenda = "agenda"
}

export interface CallToAction {
	id?: string;
	type: CallToActionType;
	button_name: TranslateString;
	content: {
		isOn?: boolean; /** not used for header NavItems, items that are off are simply removed from the list */
		section?: PageModuleType; /** @deprecated use section_module_id instead. section uses module type and is used only for backwards compatabliity as of the writing of this (2022-05-05). It will not exists in any future modules moving forward */
		section_module_id?: number;
		page?: PageLinkType;
		url?: string;
		session?: string; /** session uuid */
	};
	// New parameters must be optional or nullable
}

export interface SortableCallToAction extends CallToAction {
	id: string;
}

export interface HomepageHeader {
	logo: string | null;
	layout: HeaderAllTemplates;
	is_sticky?: boolean;
	navItems: CallToAction[];
	callToAction: CallToAction;
	styling_overrides?: {
		background?: {
			color: string;
			image: {
				is_on: boolean,
				src: string;
			};
		},
		content?: {
			navItems?: TextOverrides;
			callToAction: ButtonOverrides;
		};
	};
	sign_in_button?: boolean;
	// New parameters must be optional or nullable
}
export interface StaticVideo {
	video: number | string;
	type: "wistia" | "ustream" | "embed" | "uploaded" | "url" | "recorded";
	name: string;
	image: string | null;
	duration: number | null;
	created_date: Date;
	hls_link?: string | null;
	source?: string | null;
	uuid?: string;
	convert_status?: VideoConvertStatus;
	convert_progress?: number;
	is_fireside?: boolean;
	// New parameters must be optional or nullable
}

export interface IVSRecording {
	id: number;
	playback_url: string;
	timestamp: string;
	session: number;
	recording_duration_ms: number;
	name: string;
	uuid: string;
	duration?: number | null;
	language?: string; // Language of this specific recording
	languages?: string[]; // All the languages for the session, used to determine if the session is multi language (i.e. there are other recordings of this session in different languages)
	single_stream?: boolean;
	// New parameters must be optional or nullable
}

export interface HomepageFooter {
	logo: string | null;
	navItems: CallToAction[];
	styling_overrides?: {
		background?: {
			color: string;
			image: {
				is_on: boolean,
				src: string;
			};
		},
		content?: {
			navItems?: TextOverrides;
			blLogoColor?: string;
		};
	};
	// New parameters must be optional or nullable
}

export enum VideoConvertStatus {
	progressing,
	complete,
	error
}

export interface VideoCaption {
	id: number;
	uuid: string;
	created: string;
	name: string;
	video: number;
	source: string;
	language: string;
	// New parameters must be optional or nullable
}

export interface HomepageVideo extends Module {
	video: number;
	type: "wistia" | "ustream" | "embed" | "uploaded" | "url" | "recorded";
	name: string;
	source: string | null;
	use_thumbnail: boolean;
	original_image: string;
	image: string;
	uuid: string;
	convert_job?: string;
	hls_link?: string;
	convert_status?: VideoConvertStatus;
	convert_progress?: number;
	duration?: number | null;
	is_fireside?: boolean;
	captions?: VideoCaption[];
	session_language?: string;
	// New parameters must be optional or nullable
}

export interface CreateVideo {
	type: "wistia" | "ustream" | "embed" | "uploaded" | "url" | "recorded";
	name: string;
	source: string | null;
	use_thumbnail: boolean;
	original_image: string;
	image: string;
	duration?: number;
	recording_duration_ms?: number,
	hls_link?: string;
	session_language?: string;
	captions?: VideoCaption[];
	video?: number;
	recording_id?: number;
	base_language?: string;
	// New parameters must be optional or nullable
}

export interface ArchivedVideo {
	id: number;
	name: string;
	upload_date: string | null;
	video_duration: number | null;
	url: string;
	// New parameters must be optional or nullable
}

export interface HomepageMainEventBanner {
	title: TranslateString;
	description: TranslateString;
	image: string | null;
	originalImage: string | null;
	callToAction: CallToAction;
	layout_type?: string;
	countdownIsOn?: boolean;
	enable_live_banner?: boolean;
	enableDynamicMainEventBannerHeight?: boolean;
	// New parameters must be optional or nullable
}

export enum CTAType {
	url,
	session,
	registration
}

export interface CreateSponsor {
	name: string;
	description: TranslateString;
	image: string;
	enable_cta: boolean;
	call_to_action: CallToAction;
	// New parameters must be optional or nullable
}

export interface Sponsor extends Module {
	sponsor: number;
	name: string;
	description: TranslateString;
	image: string;
	enable_cta: boolean;
	call_to_action: CallToAction;
	// New parameters must be optional or nullable
}

export interface ICreateQuestion {
	question: string; // language translation
	first_name: string;
	last_name: string;
	email: string;
	likes?: number;
	created_at: Date;
	module_id: number;
	session: number;
	channel: number;
	event: number;
	spam: boolean;
	blocked?: boolean;
	blocked_by?: number;
	blocked_at?: Date;
	blocked_reason?: string;
	status: number;
	bl_profile?: number;
	bl_profile_non_registered?: number;
	profile?: any;
	locations?: any;
	deleted_at?: Date;
	// New parameters must be optional or nullable
}

export interface IQuestion extends ICreateQuestion {
	id: number;
	total_comments?: number;
	has_liked?: boolean;
	answered?: boolean;
	// New parameters must be optional or nullable
}

export interface Product extends Module {
	product?: number;
	tab_title: TranslateString;
	product_title: TranslateString;
	images: string[];
	categories: string[];
	description: TranslateString;
	price: any;
	buy_now_link: string;
	sale_price: any;
	button_text: TranslateString;
	attributes: Attribute[] | null;
	use_attributes: boolean;
	cta_new_tab?: boolean;
	parent_id: number | null;
	event_id: number | null;
	settings?: {
		bypass_modal?: boolean;
		categories_translations?: {
			[key: string]: TranslateString;
		};
		include_utm_params?: boolean;
	};
}

export interface Attribute {
	type: string;
	value: string;
	// New parameters must be optional or nullable
}

export interface CreateProduct {
	tab_title: TranslateString;
	product_title: TranslateString;
	images: string[];
	categories: string[];
	description: TranslateString;
	price: any;
	buy_now_link: string;
	sale_price: any;
	button_text: TranslateString;
	attributes: Attribute[];
	use_attributes: boolean;
	cta_new_tab?: boolean;
	event_id?: number;
	settings?: {
		bypass_modal?: boolean;
		include_utm_params?: boolean;
	};
	// New parameters must be optional or nullable
}

export interface CreateQuestionPrompt {
	title: TranslateString;
	settings: {
		likes: boolean;
		replies: boolean;
		anonymous_replies: boolean;
		private_responses?: boolean;
	};
}

export interface CreateCommentBoxPrompt {
	title: TranslateString;
	settings: {
		likes: boolean;
		replies: boolean;
	};
}

export interface SocialLink {
	platform: SocialPlatforms | string;
	url: string;
	// New parameters must be optional or nullable
}

export interface SpeakerAttribute {
	icon: TIconName;
	attribute: TranslateString;
	value: TranslateString;
	// New parameters must be optional or nullable
}

export enum SocialPlatforms {
	facebook = 'FaceBook', //I know this isnt how its supposed to look but for some reason this is whats been set as our facebook ICON key and is already in the db everywhere.
	twitter = 'Twitter',
	linkedIn = 'LinkedIn',
	instagram = 'Instagram',
	youTube = 'YouTube',
	tiktok = 'tiktok',
	website = 'Website'
}

export interface Speaker extends Module {
	speaker: number;
	first_name: string;
	last_name: string;
	description: TranslateString;
	image: string;
	original_image: string;
	link_social: boolean;
	social_links?: SocialLink[]; // related_sessions should be in the content component of the page_module because they do not reach across events
	attributes?: SpeakerAttribute[];
	job_title: TranslateString;
	attributes_enabled: boolean;
	misc_translations?: ISpeakerMiscTranslations;
	// New parameters must be optional or nullable
}

export interface CreateSpeaker {
	first_name: string;
	last_name: string;
	description: TranslateString;
	image: string;
	original_image: string;
	link_social: boolean;
	social_links?: SocialLink[];
	attributes?: SpeakerAttribute[];
	job_title: TranslateString;
	attributes_enabled: boolean;
	misc_translations?: ISpeakerMiscTranslations;
	// New parameters must be optional or nullable
}

export interface ISpeakerMiscTranslations {
	[key: string]: TranslateString;
}

export interface SpeakerPageModuleContent {
	title: TranslateString;
	description: TranslateString;
	related_sessions: RelatedSpeakerSessions[] | null;
	custom_heading: TranslateString;
	layout_type: string;
	// New parameters must be optional or nullable
}

export interface RelatedSpeakerSessions {
	speaker: number;
	sessions: number[];
	// New parameters must be optional or nullable
}

export interface Quiz extends Module {
	quiz: number;
	title: TranslateString;
	success_threshold: number;
	success_message: TranslateString;
	failure_message: TranslateString;
	allow_retake: boolean;
	questions: QuizQuestion[];
	// New parameters must be optional or nullable
}
export interface QuizQuestion {
	quiz_question: number;
	quiz: number;
	title: TranslateString;
	multiple_choice: boolean;
	options: QuizQuestionOption[];
	// New parameters must be optional or nullable
}

export interface QuizQuestionOption {
	quiz_question_option: number;
	quiz_question: number;
	label: TranslateString;
	correct: boolean;
	// New parameters must be optional or nullable
}

export interface CreateQuiz {
	title: TranslateString;
	success_threshold: number;
	success_message: TranslateString;
	failure_message: TranslateString;
	allow_retake: boolean;
	questions: QuizQuestion[];
	// New parameters must be optional or nullable
}

export enum SurveyType {
	survey = 'survey',
	quiz = 'quiz',
	poll = 'poll',
	question_prompt = 'question_prompt',
	comment_box_prompt = 'comment_box_prompt'
}

export const SurveyTypeDisplayNames: { [key in SurveyType]: string } = {
	[SurveyType.survey]: 'Survey',
	[SurveyType.quiz]: 'Quiz',
	[SurveyType.poll]: 'Poll',
	[SurveyType.question_prompt]: 'Question',
	[SurveyType.comment_box_prompt]: 'Comment Box',
};

export interface Survey extends Module {
	survey: number;
	name: TranslateString;
	description: TranslateString;
	completion_message: TranslateString;
	allow_retake: boolean;
	show_results: boolean;
	questions: SurveyQuestion[];
	survey_type?: SurveyType;
	passing_threshold?: number | null;
	label?: TranslateString;
	misc_translations?: ISurveyMiscTranslations;
	// New parameters must be optional or nullable
}

export interface ISurveyMiscTranslations {
	[key: string]: TranslateString;
}

export type TextAnswer = {
	created_at: string;
	id: number;
	bl_profile: number;
	profile: Record<number, string>;
	text_answer: TranslateString;
}

export interface QuizSurvey extends Survey { survey_type: SurveyType.quiz; }

export interface CreateSurvey {
	name: TranslateString;
	description: TranslateString;
	completion_message: TranslateString;
	allow_retake: boolean;
	show_results: boolean;
	questions: SurveyQuestion[];
	survey_type?: SurveyType;
	passing_threshold?: number | null;
	/** label is the "type" rendered in the card pill. Ex "Survey" */
	label?: TranslateString;
	/** misc_translations are any translations outside of name/description/label. They are not included here because they were already before this feature.  */
	misc_translations?: ISurveyMiscTranslations;
	// New parameters must be optional or nullable
}

export enum SurveyQuestionType {
	text = 1,
	radio, // select one
	checkbox // multiple choice
}

export interface SurveyQuestion {
	survey_question: number;
	title: TranslateString;
	required: boolean;
	question_type: SurveyQuestionType;
	options: SurveyQuestionOption[];
	// New parameters must be optional or nullable
}

export interface IEngagementSubmit {
	type: string;
	engagementSubmits: {
		[key: string]: [string];
	}
}

export interface QuizzesStatus {
	completed: boolean;
	correctAnswers: number;
	totalAnswered: number;
	totalQuestions: number[];
	passed: boolean;
}


export interface SurveyQuestionOption {
	survey_question_option: number;
	label: TranslateString;
	is_correct?: boolean | null;
	// New parameters must be optional or nullable
}

export interface Slideshow extends Module {
	slideshow: number;
	slide_images: string[];
	title: TranslateString;
	// New parameters must be optional or nullable
}

export interface SlideshowContent {
	images: string[];
	duration: number;
	advance_by_moderator: boolean;
	// New parameters must be optional or nullable
}

export interface AgendaContent {
	custom_heading: TranslateString;
	title: TranslateString;
	description: TranslateString;
	layout_type: string;
	calendar_btn?: boolean;
	// New parameters must be optional or nullable
	show_on_demand_sessions?: boolean;
	show_broadcast_sessions?: boolean;
}

export enum EShowAgendaLists {
	show_on_demand_sessions = 'show_on_demand_sessions',
	show_broadcast_sessions = 'show_broadcast_sessions',
}

export interface AboutContent {
	custom_heading: TranslateString;
	title: TranslateString;
	description: TranslateString;
	image: string;
	originalImage: string;
	// New parameters must be optional or nullable
}

export interface VideoContent {
	custom_heading: TranslateString;
	title: TranslateString;
	description: TranslateString;
	layout_type?: string;
	// New parameters must be optional or nullable
}

export enum ThumbnailStatus {
	never,
	generating,
	done
}
export interface Document extends Module {
	document: number;
	original_name: string;
	location: string;
	thumbnail: string | null;
	thumbnail_status: ThumbnailStatus;
	filesize: number;
	display_name: TranslateString;
	generated_thumbnail: string | null;
	misc_translations?: IDocumentMiscTranslations;
	// New parameters must be optional or nullable
}

export interface IDocumentMiscTranslations {
	[key: string]: TranslateString;
}

export interface QuestionPrompt extends Module {
	question_prompt: number;
	title: TranslateString;
	settings: QuestionPromptSettings;
}

export interface CommentBoxPrompt extends Module {
	comment_box_prompt: number;
	title: TranslateString;
	settings: CommentBoxPromptSettings;
}

export interface QuestionPromptSettings {
	likes: boolean;
	replies: boolean;
	anonymous_replies: boolean;
	private_responses?: boolean;
	translations?: IQuestionPromptTranslations;
}

export interface CommentBoxPromptSettings {
	likes: boolean;
	replies: boolean;
	translations?: ICommentBoxPromptTranslations;
}

export interface IQuestionPromptTranslations {
	[key: string]: TranslateString;
}

export interface ICommentBoxPromptTranslations {
	[key: string]: TranslateString;
}

export type EngageModule = {
	/** Not unique */
	id: number;
	/** Should be unique */
	module_id: number;
	name: TranslateString;
	created_at: string;
	type: "survey" | "poll" | "quiz" | "question_prompt" // | "feedback"
}

export interface Callout {
	title: TranslateString;
	description: TranslateString;
	callToAction: CallToAction;
	layout_type?: string;
	originalImage?: string;
	image?: string;
	// New parameters must be optional or nullable
}

export interface CreateDocument {
	original_name: string;
	location: string;
	thumbnail: string | null;
	thumbnail_status: ThumbnailStatus;
	filesize: number;
	display_name: TranslateString;
	generated_thumbnail: string | null;
	// New parameters must be optional or nullable
}

export interface UstreamVideo {
	id: string;
	title: string;
	description: string;
	url: string;
	length: string;
	created_at: number;
	added_at: number;
	thumbnail: {
		default: "string";
	};
	tinyurl: string;
	// New parameters must be optional or nullable
}

export interface UstreamVideos {
	videos: UstreamVideo[];
	paging: {
		next: string;
		page_count: number;
		item_count: number;
	};
	// New parameters must be optional or nullable
}

export interface WistiaVideo {
	created: string;
	description: string;
	duration: number;
	hashed_id: string;
	id: number;
	name: string;
	progress: number;
	status: string;
	thumbnail: {
		url: string;
		width: number;
		height: number;
	};
	type: string;
	updated: string;
	// New parameters must be optional or nullable
}

export interface WistiaVideos {
	medias: WistiaVideo[];
	//has other keys in the wistia response which we really don't care about
	//look at the network response if you're really curious
}

export interface CustomHeading {
	custom_heading: TranslateString;
	// New parameters must be optional or nullable
}

export enum Status {
	Blocked,
	Approved,
	Pending,
	Reported,
	Deleted,
	Unapproved,
	Banned,
	SaveToggled
}

export type BanChatResults = {
	event: number;
	bl_profile: number | null;
	bl_profile_non_reg: number | null;
	profile: Record<number, Record<number | 'avatar', string>>;
	admin_profile: Record<string, string> | null;
	banned_at: string;
}

export enum EPresenterMessageStatus {
	RemovedFromPresenter,
	SentToPresenter
}

export enum BlockedSeverity {
	low,
	medium,
	high
}

export interface ReplyMessage {
	uuid: string;
	status: Status;
	created_at: number;
	/** @deprecated content will be replaced with comment */
	content: string;
	comment: string;
	first_name?: string;
	last_name?: string;
	email?: string;
	blocked_reason?: string;
	blocked_severity?: BlockedSeverity;
	blocked_by?: number;
	blocked_at?: number;
	deleted_at?: number;
	// New parameters must be optional or nullable
}

export interface IGif {
	title: string;
	url: string;
}

interface IImage {
	name: string;
	url: string;
}

export interface CommentProps {
	id: number;
	session: number;
	first_name: string;
	last_name: string;
	email: string;
	/** @deprecated content will be replaced with comment */
	content: string;
	comment: string;
	created_at: number;
	status: Status;
	replies?: ReplyMessage[];
	blocked_reason?: string;
	blocked_severity?: BlockedSeverity;
	blocked_by?: number;
	blocked_at?: number;
	deleted_at?: number;
	uuid: string;
	gif?: IGif;
	image?: IImage;
	// New parameters must be optional or nullable
}

export enum ESpam {
	FALSE,
	TRUE
}

export interface ISocketCommentProps {
	id: number;
	bl_profile?: number;
	bl_profile_non_registered?: number;
	profile?: any;
	locations?: any[];
	uuid: string;
	gif?: IGif;
	image?: IImage;
	reply_to_id?: number;
	reply_to?: number;
	event: number;
	channel: number;
	session: number;
	first_name: string;
	last_name: string;
	email: string;
	comment: string;
	created_at: string | number | Date;
	status: Status;
	blocked_reason?: string; // the reason it was blocked (done by moderator)
	blocked_severity?: BlockedSeverity;
	blocked_by?: number; // user id of the moderator who blocked it
	blocked_at?: number; // timestamp
	deleted_at?: number | null; // timestamp when moderator deletes the message
	language: LanguagesAbbr;
	spam: boolean;
	likes: number;
	question_id: number; // id of original question
	module_id: number;
	moderator?: number;
	moderator_reply_to_id?: number;
	moderator_reply_to?: ISocketCommentProps;
	registered_profile?: any;
	non_registered_profile?: any;
	sent_to_presenter?: boolean;
	sent_to_presenter_at?: string | null; // timestamp string when sent to presenter
	live_chat?: string; // the socket channel `session-${session_uuid}-${language}`
	is_moderator?: boolean;
	moderator_profile?: IModeratorProfileFields;
	profile_uuid?: string;
	avatar?: string;
	answered?: boolean;
	/** used for filtering messages in the embed chat for greenroom */
	saved?: boolean;
	// New parameters must be optional or nullable
}

export type IncomingSocketQuestion = {
	id: number,
	first_name: string,
	last_name: string,
	question: string,
	created_at: string,
	spam: boolean,
	blocked: false | null,
	blocked_by: number | null,
	blocked_at: string | null,
	blocked_reason: unknown | null,
	status: number,
	bl_profile: number,
	profile: Record<number, Record<number | string, string>>,
	locations: null,
	event: number,
	channel: number,
	session: number,
	deleted_at: string | null,
	module_id: number,
	bl_profile_non_registered: null,
	language: LanguagesAbbr | null,
	sent_to_presenter: boolean,
	sent_to_presenter_at: string,
	seen: boolean,
	answered: boolean,
	message_type: "question",
	live_chat: string,
	email_initials: string,
	question_id?: number;
	comment: string;
	moderator: number | null;
}

export type PaginatedQuestion = {
	answered: boolean;
	avatar: string | null;
	bl_profile: number;
	blocked: boolean | null,
	blocked_by: number | null,
	blocked_at: string | null,
	blocked_reason: unknown | null,
	comments: number;
	created_at: string;
	first_name: string;
	id: number;
	language: string;
	last_name: string;
	likes: number;
	question: string;
	spam: boolean;
	module_id: number;
	status: Status;
	pending_moderator: boolean;
}

export interface QuestionComment {
	id: number;
	bl_profile: number;
	uuid: string;
	event: number;
	channel: number;
	session: number;
	first_name: string;
	last_name: string;
	email: string;
	comment: string;
	created_at: string | number | Date;
	status: Status;
	blocked_reason?: string; // the reason it was blocked (done by moderator)
	blocked_severity?: BlockedSeverity;
	blocked_by?: number; // user id of the moderator who blocked it
	blocked_at?: number; // timestamp
	deleted_at?: number | null; // timestamp when moderator deletes the message
	language: string;
	spam: boolean;
	likes: number;
	question_id: number; // id of original question
	module_id: number;
	moderator: number;
	moderator_reply_to_question: QuestionComment;
	moderator_reply_to_question_id: number;
	moderator_reply_to_comment: QuestionComment;
	moderator_reply_to_comment_id: number;
	sent_to_presenter: boolean;
	sent_to_presenter_at: string;
	seen: boolean;
	// New parameters must be optional or nullable
}

export interface ISocketEventChatProps {
	type: EventChatSQSSocketTypes;
	content: any;
	// New parameters must be optional or nullable
}

export enum EPromotionTypes {
	raise_hand = 'raise_hand'
	// add additional types as we need
}

export interface IChatPromotion {
	icon?: string;
	message: string;
	actionButton?: ActionButton;
	dismissable?: boolean;
	type?: EPromotionTypes;
	// New parameters must be optional or nullable
}

export interface ActionButton {
	text?: string;
	icon?: string;
	action: () => void;
	class?: string;
	// New parameters must be optional or nullable
}

export interface EventContentBase {
	title: TranslateString;
	description: TranslateString;
	[key: string]: any;
	// New parameters must be optional or nullable
}

export enum PanelTabs {
	Landing = 'Landing',
	Registration = 'Registration',
	Event = 'Event'
}

export enum SessionTabs {
	EventAgenda = 'Event agenda',
	OnDemand = 'On demand'
}

export enum NavMainItems {
	Home = 'Home',
	Sessions = 'Sessions',
	Session = 'Session', // for single session events
	TellyClub = 'Telly Club',
	Notifications = 'Notifications',
	People = 'People', // now Directory, keeping for backwards compat
	Directory = 'Directory', // Previously People
	Catalog = 'Catalog',
	Social = 'Socials',
	Leaderboard = 'Leaderboard',
}

export enum NavProfileItems {
	Profile = 'Profile',
	TermsAndConditions = 'Terms and Conditions',
	PrivacyPolicy = 'Privacy Policy',
	Social = 'Socials',
	Leaderboard = 'Leaderboard',
	GDPRSettings = 'GDPR'
}

export interface IHomepageMainNavItems {
	uuid: string;
	name: NavMainItems;
	custom_name: TranslateString;
	is_on: boolean;
	custom_icon_uuid?: string;
	is_custom?: boolean;
	// New parameters must be optional or nullable
}

export interface ExtendedHomepageMainNavItems {
	uuid: string;
	name: string;
	custom_name: TranslateString;
	is_on: boolean;
	custom_icon_uuid?: string;
	is_custom?: boolean;
	linkReferenceType?: string;
}

export interface IHomepageProfileNavItems {
	uuid: string;
	name: NavProfileItems;
	custom_name: TranslateString;
	is_on: boolean;
	custom_icon_uuid?: string;
	is_custom?: boolean;
	// New parameters must be optional or nullable
}

export interface PrivacyPolicy {
	privacy_policy: number;
	channel: number;
	content: string;
	settings?: {
		content?: TextOverrides;
	};
	// New parameters must be optional or nullable
}

export interface CreatePrivacyPolicyRequest {
	eventID: string;
	channel: number;
	content: string;
	settings?: {
		content?: TextOverrides;
	};
	// New parameters must be optional or nullable
}

export interface TermsConditions {
	terms_id: number;
	channel: number;
	content: string;
	settings?: {
		content?: TextOverrides;
	};
	// New parameters must be optional or nullable
}

export interface CreateTermsConditionsRequest {
	eventID: string;
	channel: number;
	content: string;
	settings?: {
		content?: TextOverrides;
	};
	// New parameters must be optional or nullable
}

export enum AuthType {
	sso = 'sso',
	email_password = 'email_password'
}

export type ChannelSSOIntegration = {
	type: SingleSignOnTypes.OIDC | SingleSignOnTypes.SAML;
	admin_login: boolean;
	idp_name: string;
	user_pool_client_id: string;
	login_text: string | null;
	icon: string | null;
	channel: number;
	uuid: string;
	domain?: string;
};

export type ChannelOauthIntegration = {
	type: SingleSignOnTypes.OAUTH2;
	admin_login: boolean;
	login_text: string | null;
	icon: string | null;
	channel: number;
	uuid: string;
	domain?: string;
};

export interface ChannelAuthIntegrationOnly {
	channel: number;
	auth_integration?: ChannelSSOIntegration | ChannelOauthIntegration | null;
}
export interface Channel {
	channel: number;
	uuid: string;
	name: string;
	url: string;
	customizations: {
		html: {
			events: {
				all: string; //for all events
				[key: string]: string; //key is the event id for a single event
			};
			sessions: {
				all: string; //for all sessions
				[key: string]: string; //key is the session id for a single session
			};
		},
		css: {
			events: string;
			sessions: string;
		},
		[key: string]: any; 	//these can be named anything and be of any value, but html and css are constant and must exist
	};
	legacy: {
		ustream_id: string;
		stream_key: string;
		wistia_id: string;
	},
	logos: ILogos;
	primary_contact: number | null;
	colors: {
		primary: string;
	};
	email: CustomEmailSender;
	two_factor: boolean;
	enable_api_key: boolean;
	api_token: string | null;
	subscription: number; 		// will be enumerated once we know more about the subscription levels
	modules: number[];			// may be deprecated
	custom_reactions: CustomReaction[];
	enable_support_chat_widget?: boolean;
	auth_type?: AuthType[] | null;
	auth_integration?: ChannelSSOIntegration | ChannelOauthIntegration | null;
	enable_anonymous_sentiment_reporting?: boolean;
	limit_registration_question_creation?: boolean;
	enabled_features?: string[];
	ecdn_configuration?: { [key in eCDNTypes]: KollectiveConfiguration };
	// New parameters must be optional or nullable
}

export interface CustomEmailSender {
	sender: string; // name of sender
	sender_signature: string; // email address of sender
	signature_id: number | null; // postmark id of signature needed to check verification, edit, or delete
	verified: boolean; // email address verified by Postmark
	// New parameters must be optional or nullable
}

export interface CustomReaction {
	id: number;
	uuid: string;
	channel: number;
	name: string;
	url: string;
	// New parameters must be optional or nullable
}

export interface CreateNonRegistrationProfiles {
	first_name: string;
	last_name: string;
	email: string;
	session: number;
	session_uuid: string;
	event: number;
	event_uuid: string;
	channel: number;
	// New parameters must be optional or nullable
}

export interface BLChannelProfile {
	[key: string]: any; //key would be the channel id, string would be the fields for the profile
	avatar?: string | null;
	enable_messaging?: boolean;
	// New parameters must be optional or nullable
}

export interface BlProfile {
	id?: number;
	uuid: string;
	bl_profile: number;
	first_name: string;
	last_name: string;
	email: string;
	profile?: BLChannelProfile;
	locations?: any;
	verified: boolean;
	created_date: string;
	modified_date: string | null;
	saml_id: string | null;
	parent_profile?: number;
	non_registered?: boolean;
	bookmarked_profiles?: Record<number, Array<string>>; // Channel id => Array of profile uuids
	is_moderator?: boolean;
	currency?: string;
	moderator_profile?: IModeratorProfile;
	// New parameters must be optional or nullable
}

export interface IModeratorProfile {
	[channel: number]: {
		[event_uuid: string]: IModeratorProfileFields;
	};
}

export interface IModeratorProfileFields {
	first_name: string;
	last_name: string;
	avatar: string;
}

export enum BlProfileValues {
	firstName = "1",
	lastName = "2",
	email = "3",
	bio = "4",
	jobTitle = "5",
	companyName = "6",
	location = "7",
	passcode = "10",
	position = "92"
}

export interface BlProfileSearchResult {
	uuid: string;
	avatar: string | null;
	total_profiles: number;
	[key: string]: string | number | null;
	// New parameters must be optional or nullable
}

export interface IBreakoutRoomProfile extends BlProfile {
	brandlive_profile: number;
	socketId?: string;
	company?: string;
	// New parameters must be optional or nullable
}


export type SSOValue = {
	disabled: boolean;
	toggled: boolean;
};
export type SSOOptionsType = (SamlIntegration | OIDCIntegration | Oauth2Integration);

export type SSOStatus = {
	[SingleSignOnTypes.SAML]: SSOValue;
	[SingleSignOnTypes.OIDC]: SSOValue;
	[SingleSignOnTypes.OAUTH2]: SSOValue;
};

export enum SqsSocketTypes {
	CHAT = 'chat',
	QUESTION = 'question',
	QUESTION_COMMENT = 'question_comment',
	QUESTION_LIKE = 'question_like',
	QUESTION_UNLIKE = 'question_unlike',
	ANNOUNCEMENT = 'announcement',
	SURVEY = 'survey',
}

export enum EventChatSQSSocketTypes {
	CHAT_MESSAGE = 'event_chat_message',
	CREATE_CHAT = 'create_event_chat',
	JOIN_CHAT = 'join_event_chat',
	LEAVE_CHAT = 'leave_event_chat',
	EDIT_MESSAGE = 'edit_event_chat_message',
	DELETE_MESSAGE = 'delete_event_chat_message',
}

export enum EventChatMessageType {
	FILE = 'file',
	GIF = 'gif',
	TEXT = 'text',
	ENABLE_CHAT = 'enable_chat',
	DISABLE_CHAT = 'disable_chat'
}

export interface StripeIntegration {
	stripe_integration: number;		// auto-incrementing id from pg
	channel: number;
	stripe_id: string; 				// id from stripe
	status: 0 | 1;
	created_by: number;
	created_at: string;
	modified_by: number | null;
	modified_at: string | null;
	fee_percent: number; 			//defaults to 7
	primary_contact: string;
	// New parameters must be optional or nullable
}

export interface ICustomFilteredWords {
	id: number;
	event_uuid: string;
	event: number;
	language: LanguagesAbbr;
	word: string;
	// New parameters must be optional or nullable
}

export enum EPermissions {
	Owner = 'owner', // deprecated
	Admin = 'admin',
	Analytics = 'analytics',
	Moderation = 'moderation',
	Producer = "producer",
	ModeratorRegistrant = 'moderator_registrant', // deprecated
	PasscodeManager = 'passcode_manager', // deprecated
	Editor = 'editor',
	GroupAdmin = 'group_admin',
	Viewer = 'viewer', // not visible in BLP
	Builder = 'builder',
	Presenter = 'presenter' // not visible in BLP
}

export const EPermissionsDisplayNames: { [key in EPermissions]: string } = {
	[EPermissions.Owner]: 'Owner', // deprecated
	[EPermissions.Admin]: 'Admin',
	[EPermissions.Analytics]: 'Analyst',
	[EPermissions.Moderation]: 'Moderator',
	[EPermissions.Producer]: 'Producer',
	[EPermissions.ModeratorRegistrant]: 'Moderator Registrant', // deprecated
	[EPermissions.PasscodeManager]: 'Passcode Manager', // deprecated
	[EPermissions.Editor]: 'Editor',
	[EPermissions.GroupAdmin]: 'Group Manager',
	[EPermissions.Viewer]: 'Viewer', // not visible in BLP
	[EPermissions.Builder]: 'Developer',
	[EPermissions.Presenter]: 'Presenter' // not visible in BLP
};

export const PERMISSIONS: { [key: string]: EPermissions; } = {
	Owner: EPermissions.Owner, // deprecated
	Admin: EPermissions.Admin,
	Builder: EPermissions.Builder,
	Editor: EPermissions.Editor,
	GroupAdmin: EPermissions.GroupAdmin,
	Analytics: EPermissions.Analytics,
	Moderation: EPermissions.Moderation,
	Producer: EPermissions.Producer,
	PasscodeManager: EPermissions.PasscodeManager, // deprecated
	ModeratorRegistrant: EPermissions.ModeratorRegistrant, // deprecated
	Viewer: EPermissions.Viewer, // not visible in BLP
	Presenter: EPermissions.Presenter // not visible in BLP
};

export const VISIBLE_PERMISSIONS: { [key: string]: EPermissions; } = {
	Admin: EPermissions.Admin,
	Builder: EPermissions.Builder,
	Editor: EPermissions.Editor,
	GroupAdmin: EPermissions.GroupAdmin,
	Analytics: EPermissions.Analytics,
	Moderation: EPermissions.Moderation,
	Producer: EPermissions.Producer,
};

export type DownloadStringValues = "NORMAL" | "SPECIFIC" | "SPECIFIC_MAGIC" | "NORMAL_MAGIC";

export enum DownloadCSVStringValues {
	NORMAL = "NORMAL",
	SPECIFIC = "SPECIFIC",
	SPECIFIC_MAGIC = "SPECIFIC_MAGIC",
	NORMAL_MAGIC = "NORMAL_MAGIC"
}

export enum DownloadRecordedRequestStatus {
	pending = 1,
	processing = 2,
	completed = 3,
	failed = 4
}

export interface DownloadRecordedRequest {
	id?: number;
	stream_recording: string;
	status: DownloadRecordedRequestStatus;
	created_at: number;
	modified_at: number;
	email: string;
	result: string;
	aws_data: any;
	progress: number | null;
	channel?: number;
	// New parameters must be optional or nullable
}
export interface IQuestionSocket {
	question: string;
	[key: string]: any;
}

export enum EPreviewTypes {
	Landing = 'landing',
	Registration = 'registration',
	Session = 'session',
	Home = 'home',
	Agenda = 'agenda',
	CustomPage = 'custom-page',
	Directory = 'directory',
	Leaderboard = 'leaderboard',
}

export enum ENavbarTypes {
	Vertical = 'vertical_v2',
	Horizontal = 'horizontal_v2',
	VerticalOriginal = 'vertical-original',
}

export interface EventNavbar {
	navbarItems: IHomepageMainNavItems[]; // not used now but we need a unique identifier for contorls.tsx and this will likely be useful for future use (similar to header)
	styling_overrides?: {
		background?: {
			color: string;
			image: {
				is_on: boolean,
				src: string;
			};
		},
		content?: any;
	};
	navbarType?: ENavbarTypes;
	isTransparent?: boolean;
	isSticky?: boolean;
	blurLevel?: number;
	// New parameters must be optional or nullable
}

// analytics events and sessions are structured a bit differently so they get their own types - differences are noted in comments
export interface AnalyticsEvent {
	event: number;
	channel: number;
	status: 1 | 2 | 3;
	template: number; //number instead of template object
	name: string;
	event_description?: string;
	settings: {
		design: {
			font: ThemePackTypes.FontPack;
			colors: ThemePackTypes.ThemePack;
			style: {
				style: DesignStyle;
				shape: DesignShape;
			};
			custom?: ThemePackTypes.ICustomDesignSettings;
		},
		branding?: {
			removeBrandliveLogo: boolean;
			customURL: string;
			favicon: string;
		},
		customCSS?: string;
		leaderboard_settings?: {
			enabled: boolean;
		};
	};
	registration_settings?: Registration;
	registration_questions: RegistrationQuestion[] | null;
	registration_steps?: RegistrationStep[];
	required_registration_questions: number[] | null;
	registration_on: boolean;
	homepage: number | null; //number instead of event homepage object
	sessions: number[]; //array of numbers instead of sessions objects
	uuid: string;
	created_date: string; //string instead of date
	terms_id: number | null;
	privacy_policy: number | null;
	// sessionsPreviews?: SessionPreview[]; // not used
	custom_css: string | null;
	channel_settings?: any;
	social_sharing?: {
		image?: string;
		description?: string;
	};
	// properties unique to analytics
	pageviews: number;
	registrations: number;
	start_time: number;
	end_time?: number;
	url: string | null;
	custom_css_compiled: string | null;
	use_safe_css: boolean; // default false in database, will always be defined
	dma_id?: string | null;
	session_descriptors: string[] | null;
	total_events?: number;
	// New parameters must be optional or nullable
}

export interface AnalyticsSession {
	session: number;
	channel: number;
	title: TranslateString;
	description: TranslateString | null,
	tracks: string[] | null;
	default_language: string;
	languages: string[];
	session_type: SessionTypesEnum;
	on_demand?: boolean;
	timestamp: string | null; //string instead of number
	end_timestamp: string | null; //string instead of number
	video: SessionVideo; //???
	video_duration?: string; //string instead of number
	layout: SessionLayout; //???
	moderation: ISessionModeration | null; //????
	uuid: string;
	modules: number[]; // array of numbers instead of modules objects
	// translate_all?: boolean; // not used
	image?: string | null;
	module_grouping?: PageModuleGroupModules[];
	on_demand_video: CreateVideo[] | null; //???
	broadcast_type: EBroadcastTypes;
	is_on?: boolean;
	use_different_thumbnails?: boolean;
	pre_broadcast_thumbnail?: string | null;
	post_broadcast_thumbnail?: string | null;
	custom_css?: string;
	// properties unique to analytics
	pageviews: number;
	registrations: number;
	custom_css_compiled: string | null;
	use_safe_css: boolean; // default false in database, will always be defined
	// New parameters must be optional or nullable
}

export interface AnalyticsEventSessionPageViews {
	session: number;
	sessionUuid: string;
	pageViews: number;
}

export type CustomPageType = "web" | "text";

export interface CustomPage {
	custom_page: number,
	uuid: string,
	channel: number,
	icon: string,
	page_name: TranslateString,
	path: string,
	type: CustomPageType,
	is_on: boolean,
	text_content?: unknown, //currently unknown structure
	text_settings?: unknown,//currently unknown structure
	modules?: PageModule[],
	deleted?: boolean,
	// New parameters must be optional or nullable
}

export type CustomPageRequest = Omit<CustomPage, "custom_page" | "uuid" | "is_on">;

export enum EBreakoutPanels {
	attendees = 'attendees',
	chat = 'chat',
	attendee_profile = 'attendee_profile',
	direct_message = 'direct_message',
}

export enum EFiresidePanels {
	attendees = 'attendees',
	chat = 'chat',
	attendee_profile = 'attendee_profile',
	direct_message = 'direct_message',
	session_chat = 'session_chat'
}



export enum RegistrationPanelLayoutsTypes {
	Content = 'Content',
	ContentGeneralInformation = 'general',//'General Info',
	ContentGeneralInformationCreateQuestion = 'Create Registration Question',
	ContentGeneralInformationAddQuestion = 'Add Registration Question',
	ContentAvatar = 'avatar',
	ContentProfile = 'profile',
	ContentTicketing = 'ticketing',

	Layout = 'Layout',

	Settings = 'Settings',
	SettingsGating = 'Settings Gating',
	SettingsGatingNewList = 'Settings Gating New List',
	SettingsSSO = 'Settings SSO',
	SettingsEmails = 'Settings Emails',
	SettingsEmbedCode = 'Settings Embed Code',
	SettingsAudienceList = 'Settings Audience List',
	SettingsInPersonAttendance = 'Settings In Person Attendance',
	SettingsPaidTicketing = 'Settings Paid Ticketing',
	SettingsAdditionalSettings = "Additional Settings",

}
export enum SessionPanelLayoutsTypes {
	// ENGAGE
	Engage = 'Engage',
	AddEngagementQAndA = 'Add Engagement Question',
	AddEngagementCommentBox = 'Add Engagement Comment Box',
	AddEngagementSQP = 'Add Engagement SQP',
	AddEngagementFeedback = 'Add Engagement Feedback',
	ViewSingleQAndA = 'View Single Question',
	ViewSingleQAndAResponses = 'View Single Question Responses',
	ViewSingleCommentBoxResponses = 'View Single Comment Box Responses',
	ViewSingleEngage = 'View Single Engage',
	ViewSinglePollEngage = 'View Single Poll Engage',
	ViewSingleFeedback = 'View Single Engage',
	AddEngagement = 'Add Engagement',
	CreateSurvey = 'Create Survey',
	CreatePoll = 'Create Poll',
	CreateQuiz = 'Create Quiz',
	CreateQAndA = 'Create Question',
	CreateCommentBox = 'Create Comment Box',

	CreateFeedback = 'Create Feedback',
	QuizResults = 'Quiz Results',
	PollResults = 'Poll Results',
	SurveyResults = 'Survey Results',

	// DETAILS
	Segments = 'Segments',
	Transcripts = 'Transcripts',
	Summary = 'Summary',
	Speakers = 'Speakers',

	// EXTRAS
	Extras = 'Extras',
	ExtraResources = 'Extra Resources',
	ExtraProducts = 'Extra Products',
	ExtraSuggested = 'Extra Suggested',
	ExtraCreate = 'Extra Create',
	ExtraCustom = 'Extra Custom',
	ExtraSpeaker = 'Extra Speaker',
	ExtraEmbed = 'Extra Embed',
	AddExtraResource = 'Add Extra Resource',
	CreateExtraResource = 'Create Resource',
	AddExtraProduct = 'Add Extra Product',
	CreateExtraProduct = 'Create Extra Product',
	AddExtraSuggested = 'Add Extra Suggested',
	AddExtraCustom = 'Add Extra Custom',
	AddExtraSpeaker = 'Add Extra Speaker',
	CreateExtraSpeaker = 'Create Extra Speaker',
	Education = 'Education',
	EmbedSection = 'Embed Section',
	Custom = "Custom",
	CustomCreate = "Custom Create",
	CustomSpeakers = "Custom Speakers",
	CustomResources = "Custom Resources",
	CustomProducts = "Custom Products",
	CustomSuggested = "Custom Suggested",
	CustomEmbed = "Custom Embed",
	AddCustomResourceFromLibrary = 'Add Custom Resource From Library',
	AddCustomResourceFromUpload = 'Add Custom Resource From Upload',
	AddCustomProductFromLibrary = 'Add Custom Product From Library',
	AddCustomProductFromUpload = 'Add Custom Product From Upload',
	AddCustomSuggested = 'Add Custom Suggested',
	AddCustomSpeakersFromLibrary = 'Add Custom Speakers From Library',
	AddCustomSpeakersFromUpload = 'Add Custom Speakers From Upload',
	AddCustomEmbed = 'Add Custom Embed',

	BannedUsers = "Banned Users"
}

export enum SessionTabNames {
	Layout = 'Layout',
	Settings = 'Settings',

	Content_v2 = 'Content',
	Layout_v2 = 'Layout',
	Settings_v2 = 'Settings',
}

export enum ERegistrationTabNames {
	Layout = 'Layout',
	Settings = 'Settings',
	Content = 'Content',
}

export interface IBreakoutSession {
	session: number;
	create_room_when_full: boolean;
	banner_image: string | undefined;
	use_background_image: boolean;
	background_image: string | null;
	use_zoom: boolean;
	use_google_meet?: boolean;
	// New parameters must be optional or nullable
}
export enum EMessageTypes {
	Chat = 'chat',
	Question = 'question',
	QuestionComment = 'question_comment'
}

export enum EmbedChatFilter {
	All = 'all',
	Saved = 'saved',
}

export enum EAnnouncementTypes {
	event = 'event',
	registered = 'registered',
	session = 'session'
}

export enum EScheduledAnnouncementStatus {
	deleted,
	scheduled,
	sent,
	immediate,
	cancelled
}

export interface IAnnouncement {
	id?: number;
	uuid?: string;
	status?: EScheduledAnnouncementStatus;
	recipients: EAnnouncementTypes;
	eventUUID: string,
	toSessions: number[];
	messageText: string;
	buttonOn: boolean;
	buttonText: string;
	buttonLinkType: "session" | "url" | "";
	buttonLink: string;
	moderatorProfile?: BLChannelProfile;
	scheduleOn: boolean;
	scheduleDate: Date | null;
	buttonLinkSameTab?: boolean,
	displaySettings?: IAnnouncementBannerDisplaySettings
	expirationTime?: Date | null;
	messageTextMultiLanguage?: IAnnouncementMultiLanguageSettings;
	// New parameters must be optional or nullable
}

export interface AnnouncementDB {
	id: number;
	uuid: string;
	status: EScheduledAnnouncementStatus;
	recipients: EAnnouncementTypes;
	event_uuid: string;
	to_sessions: number[];
	message_text: string | TranslateString;
	button_on: boolean;
	button_text: string | TranslateString;
	button_link_type: "session" | "url" | "";
	button_link: string;
	button_link_same_tab: boolean;
	schedule_on: boolean;
	schedule_date: Date | null;
	moderator_profile?: Profile;
	display_settings?: IAnnouncementBannerDisplaySettings;
	expiration_time?: Date | null;
	message_text_multi_language: IAnnouncementMultiLanguageSettings;
}

export interface IAnnouncementMultiLanguageSettings {
	messageText: {
		[key: string]: IAnnouncementTranslateString
	};
	buttonText: {
		[key: string]: IAnnouncementTranslateString
	};
}

export interface IAnnouncementTranslateString {
	hidden: boolean;
	changed: string;
	text: string;
	// New parameters must be optional or nullable
}

export interface IAnnouncementBannerDisplaySettings {
	type: 'pop-up' | 'banner',
	backgroundColor: string;
	textColor: string;
}

export interface SecondarySessionVideo {
	uuid?: string;
	playback_url: string;
	session: number;
	// New parameters must be optional or nullable
}
export interface IAttendeeVideoSettings {
	breakoutUuid: string;
	opentok_id: string;
	bl_profile: number;
	hasAudio: boolean;
	hasVideo: boolean;
	hasScreenShare: boolean;
	// New parameters must be optional or nullable
}

export interface IDirectMessage {
	breakoutUuid?: string;
	sessionUuid?: string;
	to: BlProfile;
	from: BlProfile;
	timestamp: number;
	key: string;
	message: {
		text: string;
		image: string;
		gif: IGif | null;
	};
	// New parameters must be optional or nullable
}
export enum ActionSource {
	Landing = 'landing',
	Home = 'home',
	Agenda = 'agenda',
	CustomPage = 'custom_page',
	Directory = 'directory',
	Profile = 'profile',
	Session = 'session',
	BreakoutRoom = 'breakout_room',
	Fireside = 'fireside',
	SelfProfile = 'self_profile',
	Leaderboard = 'leaderboard',
}

export enum ActionTargetType {
	Announcement = 'announcement',
	Attendance = 'attendance',
	Document = 'document',
	Product = 'product',
	Reaction = 'reaction',
	Sponsor = 'sponsor',
	Video = 'video'
}

export type ActionDataTypes = Product;

export enum ActionEvent {
	PageView = 'pageview',
	Click = 'click',
	Download = 'download',
	End = 'end',
	Milestone = 'milestone',
	Pause = 'pause',
	Play = 'play',
	Start = 'start',
	View = 'view',
	WatchedMinute = 'watched-minute',
	PlaybackSpeedChange = 'playback-speed-change'
}

export enum ActionType {
	Active = 'active',
	Passive = 'passive'
}

/**
 * This will ultimately get sent in as stringified JSON (see ActionPayload below)
 * Used for trying to add some guarantee to the data from the client.
 */
export interface MiscellaneousActionData {
	attendance: {
		session_end: number | null;
		session_start: number | null;
		session_type: SessionTypesEnum;
		user_session_start: number;
		event_name: string;
		session_name: string;
		email: string | null;
	},
	announcement: {
		recipients: string;
		event_name: string;
		email: string;
	},
	milestone: {
		name: string;
		source: string;
	},
	reaction: {
		emoji_url: string;
		name: string;
		user_first_name: string | null;
		user_last_name: string | null;
		email: string | null;
		relative_timestamp: number | null;
		video_url: string | null;
		session_title: string | null;
	};
	valid_passcode_lists?: number[];
	// New parameters must be optional or nullable
}

export interface ActionPayload {
	action: ActionEvent;
	action_id: string;
	action_type: ActionType;
	bl_profile: number;
	channel_id: number;
	event_id: number;
	miscellaneous?: string; // stringified JSON
	source_app: string;
	source_app_version: string;
	source_id: number;
	source_type: ActionSource;
	target_id?: number;
	target_id_string?: string; // possibly UUID
	target_type?: ActionTargetType;
	registration_id: string;
	user_session_uuid: string;
	current_language?: string; // language that is being used when action occurs
	registration_language?: string; // registration.registered_language
	live_sessions?: string[]; // registration.registered_language
	// New parameters must be optional or nullable
}

export interface SurveyAnswerBody {
	session?: number;
	survey_question: number;
	module: number;
	event: number;
	text_answer: TranslateString | null;
	radio_answer: number | null;
	checkbox_answer: string[] | null;
	session_uuid?: string;
	language: string;
	userSession?: string;
	registration_id?: string;
	leaderboard_enabled?: boolean;
	channel?: number;
	event_uuid?: string;
	survey: number;
	// New parameters must be optional or nullable
}

// Fields disallowed from being shown to other attendees
// list currently under review by Product and may be updated...
export const PERSONAL_INFO_FIELDS: Array<RegFieldsEnum> = [
	RegFieldsEnum.email,
	RegFieldsEnum.passcode,
	RegFieldsEnum.location,
	RegFieldsEnum.postal_code,
	RegFieldsEnum.state,
];

// Fields that are not editable in profile (must remain as entered in registration)
export const NON_EDITABLE_FIELDS: Array<RegFieldsEnum> = [
	RegFieldsEnum.email,
	RegFieldsEnum.passcode,
];

export interface IvsStream {
	channelArn?: string;
	playbackUrl?: string;
	startTime?: Date;
	state?: string;
	health?: string;
	viewerCount?: number;
	// New parameters must be optional or nullable
}

export interface IUploadedImage {
	original: string;
	edited: string;
	// New parameters must be optional or nullable
}

export enum EChatBlockedStatus {
	blocked = 'blocked',
	unblocked = 'unblocked'
}

export interface IBlockedChatParticipant {
	id: number;
	bl_profile: number;
	blocked_bl_profile: number;
	blocked_at: Date;
	status: EChatBlockedStatus;
	channel: number;
	// New parameters must be optional or nullable
}

export interface PageGating {
	isOn: boolean;
	pageGates: PageGate[];
	// New parameters must be optional or nullable
}

export interface PageGate {
	page: CustomPage["custom_page"];
	passcodeLists: PasscodeList[];
	// New parameters must be optional or nullable
}

export enum FiresideSocketMessages {
	JOIN_REQ = 'fireside-join-req',
	JOIN_ACCEPT = 'fireside-join-accept',
	JOIN_DENY = 'fireside-join-deny'
}

export interface FeatureFlag {
	id: number;
	name: string;
	on: boolean;
	// New parameters must be optional or nullable
}

export enum FeatureFlagsEnum {
	firesides_broadcasting = 'streams.firesides_broadcasting',
	iOS_mobile_native_player = 'streams.iOS_mobile_native_player',
	emails_and_audiences = 'streams.emails_and_audiences',
	translations_v2 = 'streams.translations_v2',
	custom_templates = 'streams.custom_templates',
	greenroom_producer_url = 'streams.greenroom_producer_url',
	publishable_preview = 'streams.publishable_preview',
	quizzes = 'streams.quizzes',
	custom_mapping = 'streams.custom_mapping',
	magic_links = 'streams.magic_links',
	accordion_module = 'streams.accordion_module',
	admin_email_verification = 'streams.admin_email_verification',
	event_hierarchy = 'streams.event_hierarchy',
	jackson_sso = 'streams.jackson_sso',
	leaderboard = 'streams.leaderboard',
	event_templates = 'streams.event-templates',
	gdpr_marketing_email_double_optin = 'streams.gdpr_marketing_email_double_optin',
	event_cookie_settings = 'streams.event_cookie_settings',
	content_speakers = 'streams.content_manager_speaker',
	navbar_v2 = 'streams.navbar_v2',
	session_details_v2 = 'streams.session_details_v2',
	api_keys = 'streams.api_keys',
	translations_v3 = 'streams.translations_v3',
	video_v2 = 'streams.video_v2',
	google_meet_phase_1 = 'streams.meet_powered_breakouts_phase_1',
	integrations_ui_q3_2023 = 'streams.integrations_ui_q3_2023',
	audience_list_ui_q3_2023 = 'streams.audience_list_ui_q3_2023',
	announcements_v2_q4_2023 = 'streams.announcements_v2_q4_2023',
	metrics_api = 'streams.metrics_api',
	registration_v2_q1_2024 = 'streams.registration_v2_q1_2024',
	go_to_greenroom_for_all_broadcast_types = 'streams.go_to_greenroom_for_all_broadcast_types',
	recording_requires_jwt = 'streams.recording_requires_jwt',
	integrations_confirmation_urls = 'streams.integrations_confirmation_urls',
	color_palettes = 'streams.color_palettes',
	admin_privileges = 'platform.admin_privileges',
	unified_auth = 'platform.unified_auth',
	domain_based_sso = 'platform.domain_based_sso',
	password_gating = 'platform.password_gating',

	// tests namespace does not make it into the database, these are local only
	test_oidc_auth = 'tests.test_oidc_auth',
	all_recording_download_requests_fail = "tests.all_recording_download_requests_fail",
}

export type FeatureFlagMap = {
	[key in FeatureFlagsEnum]?: boolean;
};

export enum ChannelFeaturesEnum {
	first_flag = 'channel_feature.first_premium_feature',
	second_flag = 'channel_feature.second_premium_feature',
	cookie_opt_in = 'channel_feature.cookie_opt_in',
	enable_google_meet_breakouts = 'channel_feature.enable_google_meet_breakouts',
	sessions_v2 = 'channel_feature.sessions_v2',
	rsvp_integration = 'channel_feature.rsvp_sync_integration',
	dma_law = 'channel_feature.dma_law',
	event_publish_locking = 'channel_feature.event_publish_locking',
	google_meet_firesides = 'channel_feature.google_meet_firesides',
	ecdn_kollective = 'channel_feature.ecdn_kollective',
	video_archive = 'channel_feature.video_archive'
}

export type ChannelFeatures = {
	[key in ChannelFeaturesEnum]?: boolean;
};

export interface ICreateSamlIntegration {
	channel: number;
	enabled: boolean;
	type: SingleSignOnTypes.SAML;
	login_text: string;
	icon: string;
	xml_url: string;
	attribute_mapping: SSOAttributeMapping;
	admin_login?: boolean;
	// not from table, extended onto this type to handle testing the
	// SSO integration from the integrations page in admin console
	// as these are added onto the type and we didn't catch it because of the any type
	// and I didn't think it necessary to refactor the entire thing right now.

	// TODO: update type on the saml-integrations.tsx file to handle the types as expected
	idp_name?: string;
	user_pool_client_id?: string;
	// New parameters must be optional or nullable
}

export interface SamlIntegration extends ICreateSamlIntegration {
	uuid: string;
	created_by: number;
	created_at: string;
	modified_by: number;
	modified_at: string;
	// New parameters must be optional or nullable
}
export interface ISSOIntegration {
	uuid: string;
	channel: number;
	idp_name: string;
	enabled: boolean;
	type: SingleSignOnTypes;
	icon: string;
	login_text: string;
	user_pool_client_id: string;
	// New parameters must be optional or nullable
	integration_provider?: SSOIntegrationProviderType;
}

export interface SSOAttributeMapping {
	email: string;
	given_name: string;
	family_name: string;
	// New parameters must be optional or nullable
}

export enum SSOIntegrationProviderType {
	COGNITO = 'COGNITO',
	JACKSON = 'JACKSON'
}

export interface ICreateOIDCIntegration {
	channel: number;
	enabled: boolean;
	type: SingleSignOnTypes.OIDC;
	login_text: string;
	icon: string;
	client_id: string;
	oidc_issuer: string;
	client_secret?: string;
	attribute_mapping: SSOAttributeMapping;
	// not from table, extended onto this type to handle testing the 
	// SSO integration from the integrations page in admin console
	// as these are added onto the type and we didn't catch it because of the any type
	// and I didn't think it necessary to refactor the entire thing right now.

	// TODO: update type on the oidc-integrations.tsx file to handle the types as expected
	idp_name?: string;
	user_pool_client_id?: string;
	admin_login?: boolean;
	// New parameters must be optional or nullable
	integration_provider?: SSOIntegrationProviderType;
}

export interface OIDCIntegration extends ICreateOIDCIntegration {
	uuid: string;
	created_by: number;
	created_at: string;
	modified_by: number;
	modified_at: string;
	// New parameters must be optional or nullable
}

export interface VisibleProduct extends Product {
	selected?: boolean;
}

export interface IProductAttribute {
	type: string;
	value: string;
	// New parameters must be optional or nullable
}

export interface IProductAttributeLabel {
	label: string;
	value: string;
	// New parameters must be optional or nullable
}

export interface ISubmitData {
	text: string;
	gif: IGif | null;
	image: IImage | null;
	// New parameters must be optional or nullable
}

export enum EOauthAttributes {
	first_name = 1,
	last_name,
	email
}

export interface CreateOauth2Integration {
	channel: number;
	active: boolean;
	client_id: string;
	client_secret: string;
	authorization_server_url: string;
	token_server_url: string;
	resource_server_url: string | null;
	login_text: string | null;
	attribute_mapping: Record<EOauthAttributes, string>;
	scopes: string[];
	icon: string | null;
	query_params: Record<string, string>;
	logout_url: string | null;
	admin_login?: boolean;
	// New parameters must be optional or nullable
}


export interface Oauth2Integration extends CreateOauth2Integration {
	uuid: string;
	created_by: number;
	created_at: string;
	modified_by: number;
	modified_at: string;
	// New parameters must be optional or nullable
}

export interface ChannelEventGroup {
	name?: string;
	channel?: number;
	created_at?: number;
	// New parameters must be optional or nullable
}

export enum ScheduledEmailStatus {
	deleted,
	active
}

export interface IInsertScheduledEmailRequest {
	event_uuid: string;
	session_uuid?: string; /** only used for session level emails */
	channel: number;
	/** timestamp of delivery date and time. Time is intervals of 5, no greater than 55 */
	delivery_date: number;
	email_type: EmailTypeEnum;
	customizations: IScheduledEmailCustomizations;
	working_customizations?: IScheduledEmailCustomizations | null;
	audiences?: IMultiSelectOption[];
	sent: boolean;
	language?: LanguagesAbbr | null;
	failed_reason?: string | null;
	timezone?: string;
	settings?: IScheduledEmailSettings;
}

export interface IScheduledEmail extends IInsertScheduledEmailRequest {
	uuid: string;
	audiences: IMultiSelectOption[];
	status: ScheduledEmailStatus;
	remote_job_id: string;
}

export interface IScheduledEmailWithEventName extends IScheduledEmail {
	event_name?: string;
}

export interface IScheduledEmailCustomizations {
	[language: string]: IScheduledEmailContent;
}

export interface IScheduledEmailContent {
	base: string; // standard email content
	subject: string; // email subject line
	attachments?: IEmailAttachment[]; // email attachments
	eventUrl?: string;
	sessionUrl?: string;
	plainTextEmail?: string;
	[key: string]: string | ImageUploaderOnSaveParam | IEmailAttachment[] | undefined; // customized content
}

export interface IEmailAttachment {
	name: string;
	content: string;
	contentId: string;
	contentType?: string;
	disposition?: string;
}

export interface IScheduledEmailSettings {
	showStartTime?: boolean;
	showAddCalendar?: boolean;
	usePlainTextEmail?: boolean;
}

export interface IUnsubscribedEmailAddresses {
	id: number;
	email_address: string;
	channel: number;
	unsubscribed: boolean;
}

export enum ChipTypes {
	Primary = 'primary',
	Warning = 'warning',
	Danger = 'danger',
	Admin = 'admin',
}

export interface IMultiSelectOption {
	label: string;
	value: string;
}

// if adding a type: be sure to include the word 'session' in any session level type and 'event' in any event level type
export enum EmailTypeEnum {
	session_reminder = 'session_reminder',
	session_live = 'session_live',
	session_ended = 'session_ended',
	event_invite = 'event_invite',
	event_registration_email = 'event_registration_email'
}

export const DISPLAY_REGISTRATION_CONFIRMATION_EMAIL = "DISPLAY_REGISTRATION_CONFIRMATION_EMAIL";

export const EmailTypeDisplayNames: Record<EmailTypeEnum, string> = {
	[EmailTypeEnum.session_reminder]: 'Session reminder',
	[EmailTypeEnum.session_live]: 'Session is live',
	[EmailTypeEnum.session_ended]: 'Replay reminder',
	[EmailTypeEnum.event_invite]: 'Event invitation',
	[EmailTypeEnum.event_registration_email]: 'Event registration',
};

export enum EInviteStatus {
	added = 'added',
	scheduled = 'scheduled',
	awaiting = 'awaiting',
	registered = 'registered'
}

export interface AudienceInvitesStatus extends PasscodeList {
	invite_status: EInviteStatus;
}

export enum OptInStatus {
	optedIn = "opted-in",
	optedOut = "opted-out",
	doubleOptedIn = "double-opted-in",
	pending = "pending"
}

export interface EmailSubscriptions {
	uuid: string;
	record_type?: string;
	message_id?: string;
	server_id?: number;
	message_stream?: string;
	changed_at?: Date;
	recipient?: string;
	origin?: string;
	suppress_sending?: boolean;
	suppression_reason?: string;
	tag?: string;
	metadata?: Record<string, string>;
}

export interface SentScheduledEmails {
	uuid: string;
	triggered_at?: number;
	email: string;
	name?: string;
	language: string;
	reg_code?: string;
	registration_id?: number;
	scheduled_email_uuid: string;
	status: number;
	passcode_list?: number;
	channel: number;
	event_uuid?: string;
	session_uuid?: string;
}

export interface SentScheduledEmailsWithEventName extends SentScheduledEmails {
	event_name?: string;
	postmark_status?: PostmarkOutboundMessage;
}

export interface PostmarkRecipient {
	Email: string;
	Name: string;
}

export interface Hash<T> {
	[key: string]: T;
}

export enum LinkTrackingOptions {
	TextOnly = "TextOnly",
	HtmlOnly = "HtmlOnly",
	HtmlAndText = "HtmlAndText",
	None = "None"
}

export interface PostmarkOutboundMessage {
	MessageID: string;
	Tag?: string;
	To: PostmarkRecipient[];
	Cc: PostmarkRecipient[];
	Bcc: PostmarkRecipient[];
	Recipients: string[];
	ReceivedAt: string;
	From: string;
	Subject: string;
	Attachments: string[];
	Status: string;
	TrackOpens: boolean;
	TrackLinks: LinkTrackingOptions;
	Metadata: Hash<string>;
	MessageStream: string;
}

export enum InitialAspectRatioOptionsEnum {
	'1:1' = 0,
	'10:3' = 1,
	'5:9' = 2,
	'3:2' = 3,
	'4:3' = 4,
	'16:9' = 5,
}

export interface ImageUploaderOnSaveParam {
	original: string;
	edited: string;
}

export interface TranslationUploadWarning {
	type: string;
	value: string;
	metadata?: unknown;
}

export interface IUserSurvey {
	session_id: number;
	language?: LanguagesAbbr; /** language should be undefined if survey is being sent to all session languages */
	schedule_on: boolean;
	schedule_date?: Date;
	survey_content?: any; /** will be used in the future for customizing the content of the survey */
}

export interface IUserSurveyDB extends IUserSurvey {
	id: number;
	uuid: string;
	status: EScheduledAnnouncementStatus;
	scheduled_uuid: string | null;
	created_at: Date;
	modified_at: Date | null;
}

export interface IUserSurveyResponse {
	user_survey_id: number | null;
	language: LanguagesAbbr;
	user_profile_uuid?: string;
	user_data: IUserData;
	user_review: IUserReview;
	session_uuid?: string;
}

export interface IUserReview {
	rating: number;
	feedback?: string;
}

export interface IUserData {
	email?: string;
	first_name?: string;
	last_name?: string;
}

export enum sortQuestionsModuleFields {
	likes,
	created_at
}

export enum sortQuestionsOptions {
	newest,
	oldest,
	most_likes
}

export const mapSortQuestionsOptionsToFields = {
	[sortQuestionsOptions.newest]: sortQuestionsModuleFields.created_at,
	[sortQuestionsOptions.oldest]: sortQuestionsModuleFields.created_at,
	[sortQuestionsOptions.most_likes]: sortQuestionsModuleFields.likes,
};

export interface CreateEventResponse extends BrandliveEvent {
	error?: string;
	message?: string;
}

export interface GreenroomProducerMeeting {
	meetingCode?: number,
	meetingId?: string,
	streamsEventId?: string;
}

export interface IEventTag {
	uuid: string;
	name: string;
	color: string;
}

export interface IAdminSessionParams {
	id: string;
	language: LanguagesAbbr;
	session: string; /** session uuid  */
	tabs: 'layout' | 'settings';
}

export interface ChannelCustomizedIcon {
	channel: number,
	name: string;
	url: string;
	uuid?: string;
	status?: number;
}

export type fileType = `${string}/${string}`;

export enum ChannelIconStatus {
	deleted = 0,
	active = 1,
}

export interface AccordionContent extends EventContentBase {
	columns: AccordionColumns;
	container_order: UniqueIdentifier[];
}

export interface AccordionColumns {
	[containerKey: number]: AccordionItem[];
}

export interface AccordionItem {
	id: UniqueIdentifier;
	title: TranslateString;
	description: TranslateString;
	styling_overrides?: {
		content?: {
			title?: TextOverrides;
		};
	};
	[sectionItemKey: string]: any;
}

export interface AccordionItemUpdate {
	containerId: UniqueIdentifier;
	itemId: UniqueIdentifier;
	title: string;
	description: string;
}

export type AccordionContainersType = UniqueIdentifier[];

export interface ICreateAccordion {
	focusedContainer?: UniqueIdentifier;
}

export interface BLAudioElement extends HTMLAudioElement {
	/** Typescript is missing "setSinkId" in HTMLAudioElement, so we create our own type for it. See [mdn docs](https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/setSinkId) */
	setSinkId: (id: string) => Promise<undefined>;
}

export interface ISessionAttendeeMetric {
	attended: "On-Demand" | "Live" | "Didn't attend";
	avatar: string | null;
	bl_profile: number;
	deviceType: string;
	email: string;
	name: string;
	firstName: string;
	lastName: string;
	liveWatchtime: number;
	location: string;
	onDemandWatchtime: number;
	registeredAt: Date;
	starScore: number;
	totalWatchtime: number;
	valid_passcode_lists: number[];
	videoLengthInMinutes: number;
	registeredLanguage?: LanguagesAbbr;
}

export interface ISessionAttendeeMetricsList {
	page: number;
	totalAttendees: number;
	attendees: ISessionAttendeeMetric[];
}

export interface ReorderContentModalProps {
	isOpen: boolean;
	onClose: () => void;
	module: PageModule;
	defaultImage: string;
	isLandingPage?: boolean,
	isHomePage?: boolean,
	isCustomPage?: boolean,
	customPageId?: number;
}

export interface SortableReorderGroupProps {
	track: string;
	displayOptions: { [key: string]: number[]; };
	id: string;
	disableSessionThumbnails: boolean | undefined;
	defaultImage: string;
	setDisplayOptions: React.Dispatch<React.SetStateAction<{ [key: string]: number[]; }>>;
	isExpanded: boolean;
	setExpandedTracks: React.Dispatch<React.SetStateAction<{ [k: string]: boolean; }>>;
	tracksTranslations?: IEventTracksTranslations;
	language?: LanguagesAbbr;
}

export interface TemplateEventInfo {
	name: BrandliveEvent['name'];
	description: BrandliveEvent['settings']['description'];
	groups: EventGroup['uuid'][];
	event_image?: string;
}

export enum LoadingStatus {
	PreLoading = 'pre-loading',
	Loading = 'loading',
	Done = 'done',
	Error = 'error',
}

type DBBrandliveEventMedium = Omit<DBBrandliveEvent, 'homepage' | 'sessions'>;
export interface GetEventsResponse extends DBBrandliveEventMedium {
	homepage: DBEventHomepage | null;
	sessions: DBSession[] | [null];
	associated_event_groups?: EventGroup[];
	template_theme?: ITemplateEventTheme;
	custom_pages?: CustomPage[];
}

export interface IUpdateSessionsTracks {
	tracks: string[];
	sessionUUID: string;
}

// Product constantly changes these so when pulling data from the backend it should be a case insensitive & non-exact search
export enum EventType {
	Webinar = 'Webinar',
	Event = 'Event',
	Allhands = 'Allhands',
	Hub = 'Hub',
	Other = 'Other'
}

export const EventTypeLabels = {
	[EventType.Webinar]: 'Webinar',
	[EventType.Event]: 'Event',
	[EventType.Allhands]: 'Allhands',
	[EventType.Hub]: 'Hub',
	[EventType.Other]: 'Other'
};

export interface TranslationsUpdateRequest {
	isUpdating: boolean;
	admin: {
		profile_id: number;
		first_name?: string;
		last_name?: string;
	} | null;
	eventUuid: string;
}

export enum StripeStatus {
	none = "none",
	ready = "ready",
	incomplete = "incomplete"
}

export interface StripeResponse {
	stripe_status: StripeStatus,
	primary_contact: string | null
}

export enum TrackTypesEnum {
	onDemand = 'onDemand',
	live = 'live',
}

export interface FiresideSessionSettings {
	uuid: Session["uuid"];
	settings?: {
		at_capacity?: boolean;
		google_meet?: {
			name: string;
			meetingUri: string;
		}
		google_meet_code?: string;
		google_meet_refresh_token?: string;
		google_meet_site_url?: string;
		google_meet_use_service_account?: boolean;
		use_google_meet?: boolean;
		error?: string;
		loading?: boolean;
	};
}

export interface SessionChatContentSettings {
	disable_images?: boolean;
	disable_gifs?: boolean;
	disable_emojis?: boolean; // 😢
}

export interface SuperAdminNotification {
	uuid: string;
	created_by: number;
	modified_by: number;
	modified_at: string;
	created_at: string;
	config: SuperAdminNotificationConfig;
}

export interface SuperAdminNotificationConfig {
	name: string;
	content: string; // html
}
