import { useCallback, useEffect, useRef } from 'react';

import Icon, { ICONS, COLORS } from '../../../general-ui/icon';
import { useGetHoveredStateButtonName } from './hooks/engage.hooks';
import { OptionalComponent } from '../../../../utils/optional-component';

interface IEngageCardTitleProps {
	name: string;
	tagIcon: string;
	cardName: string;
	editableTitle?: JSX.Element;
	isQuestionType: boolean;
	isCommentBoxType: boolean;
	surveyCompleted: boolean;
	searchTrigger?: JSX.Element;
	filterComponent?: JSX.Element;
	onViewResults?: () => void;
	handleOpen: () => void;
	privateResponsesEnabled: boolean;
}

export default function EngageCardTitle({
	name,
	tagIcon,
	cardName,
	editableTitle,
	isQuestionType,
	isCommentBoxType,
	surveyCompleted,
	filterComponent,
	onViewResults,
	handleOpen,
	privateResponsesEnabled
}: IEngageCardTitleProps): JSX.Element {
	const hoveredStateButtonName = useGetHoveredStateButtonName(isQuestionType, surveyCompleted, isCommentBoxType);
	const handleOpenRef = useRef(handleOpen);
	const onViewResultsRef = useRef(onViewResults);

	useEffect(() => {
		handleOpenRef.current = handleOpen;
	}, [handleOpen]);

	useEffect(() => {
		onViewResultsRef.current = onViewResults;
	}, [onViewResults]);

	const cardNameElement = editableTitle
		? editableTitle
		: <h3 className="evt-typography-h6 text-ellipsis">{cardName}</h3>;

	const hoveredStateHandler = useCallback(() => {
		if (surveyCompleted) {
			onViewResultsRef.current?.();
			handleOpenRef.current();

			return;
		}

		handleOpenRef.current();
	}, [surveyCompleted]);

	return (
		<>
			<span className="engage-card-pill evt-typography-caption-l2">
				<Icon name={tagIcon} color={COLORS.WHITE} size={12} />
				{name}
			</span>
			<div className="engage-card-title-container">
				{cardNameElement}
				<button
					className="engage-card-hover-action evt-button btn-xs btn-font-tiny"
					onClick={hoveredStateHandler}
				>
					{hoveredStateButtonName}
				</button>
			</div>
			<OptionalComponent display={isQuestionType}>
				<OptionalComponent display={privateResponsesEnabled}>
					<Icon name={ICONS.VIEWERS_EYE_OFF} color={COLORS.GRAY} size={36} />
				</OptionalComponent>
				<>{filterComponent}</>
			</OptionalComponent>
		</>
	);
}
