import React, { useRef, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';

interface ILiveCounterProps {
	notLive: boolean;
	isHeader: boolean;
	isHost: boolean;
	startTime: any;
}

const LiveCounter: React.FC<ILiveCounterProps> = (props) => {
	const startTimer = useRef(Date.now());
	const [elapsed, setElapsed] = useState(0);
	const timeout = useRef<any>(null);
	const mounted = useRef(false);

	if (props.notLive) {
		startTimer.current = Date.now();
	}
	else if (props.startTime) {
		// If in the middle of a live broadcast or a scheduled rehearsal and the page is loaded/refreshed, we want the time
		// elapsed on the counter to start from the beginning of the broadcast/rehearsal. 
		startTimer.current = props.startTime;
	}

	useEffect(() => {
		timeout.current = setTimeout(() => {
			if (mounted.current)
				setElapsed(elapsed + 1000);
		}, 1000);
	});

	useEffect(() => {
		//we want to prevent the elapsed counter from updating if this component is not mounted
		mounted.current = true;
		return () => {
			mounted.current = false;
			if (timeout.current) clearTimeout(timeout.current);
		};
	}, []);

	const time = Math.abs(startTimer.current - Date.now());

	function minutes() {
		if (props.notLive) {
			if (props.isHost) {
				return "00";
			} else {
				if (props.isHeader) {
					return "00m";
				} else {
					return "00";
				}
			}
		}

		// Total mins elapsed and then % 60 because any mins > 60 = hours 
		const mins = Math.floor((time / 1000 / 60)) % 60;
		if (mins < 10)
			return "0" + mins;
		else
			return mins;
	}

	function seconds() {
		if (props.notLive) return "00";

		const seconds = Math.floor((time / 1000) % 60);
		if (seconds < 10) {
			return "0" + seconds;
		} else {
			return seconds;
		}
	}

	const hours = useCallback((headerSpacing = false): string => {
		if (props.notLive) {
			return '';
		}

		const hours = Math.floor(time / 1000 / 60 / 60);

		if (hours > 0) {
			return `${hours}${headerSpacing ? ' : ' : ':'}`;
		}

		return '';
	}, [props.notLive, time]);

	return (
		// This timer is used in the header (different for host v. presenter) and above main video for live presenter
		<div className={classNames("live-indicator", !props.isHeader && 'presenter-main-video', { 'not-live': props.notLive || props.isHeader })}>
			<span className={!props.isHeader && !props.isHost && !props.notLive ? "timer-text-presenter-live" : "timer-text"}>
				{props.isHeader ?
					<span>{hours()}{minutes()}:{seconds()}</span>
					:
					<span>{hours(true)}{minutes()} : {seconds()}</span>
				}
			</span>
		</div>
	);
};

export default LiveCounter;
