import { useCallback, useEffect, useRef, useState } from 'react';
import { Session, SessionTypesEnum, FeatureFlagMap, FeatureFlagsEnum } from '../types/working-model';
import Socket from 'connection/socket';
import SocketManager from 'connection/socket-main-thread/socket-manager';
import { useGetEvent } from 'hooks/session.hooks';
import { LiveStateChangePayload, StreamState } from 'types/live-state';
import { useDispatch } from 'react-redux';
import { liveStageChange, setLiveStates } from 'store/actions/event/event-actions';
import { useTypedSelector } from 'store/reducers/use-typed-selector';
import { showAlert } from '@general-ui/alert/alert-service';

const CHECKINTERVAL = 3000;

const parseStreamTimeFromManifest = (manifest: string): number | undefined => {
	const streamTimeMatch = manifest.match(/#EXT-X-SESSION-DATA:DATA-ID="STREAM-TIME",VALUE="(?<streamTime>\d+.\d+|\d+)"/m);
	if (streamTimeMatch) {
		const streamTime = streamTimeMatch.groups?.streamTime;
		if (streamTime) {
			const now = new Date().getTime();
			const streamTimeMs = parseFloat(streamTime) * 1000;
			const streamStartTime = now - streamTimeMs;
			return streamStartTime;
		}
	}
};

export default function useLive(playbackUrl: string | null | undefined, session?: Session, featureFlags?: FeatureFlagMap): [boolean, number | undefined] {
	const liveStates = useTypedSelector(state => state.LiveEventReducer.liveStates);
	const event = useGetEvent();
	const socket = useRef<Socket | null>(null);
	const eventUuid = event?.uuid;
	const sessionUuid = session?.uuid;
	const dispatch = useDispatch();

	useEffect(() => {
		if (sessionUuid) {
			dispatch(setLiveStates(sessionUuid));
		}
	}, [sessionUuid, dispatch]);

	useEffect(() => {
		socket.current = SocketManager.get(`event-${eventUuid}`);

		const handleLiveStateChange = ({ data }: { data: LiveStateChangePayload }) => {
			dispatch(liveStageChange(data));
		};

		socket.current?.addListener('live-state-change', handleLiveStateChange);

		const handleOnline = () => {
			console.log('User now online');
			if (sessionUuid) {
				dispatch(setLiveStates(sessionUuid));
			}
		};

		const handleOffline = () => {
			console.log('User now offline');
			// user connection dropped, show alert to user
			showAlert({
				message: 'Network Offline',
				description: 'It looks like you are offline. Please check your network connection.',
				type: 'warning'
			});
		};

		const handleNetworkChange = () => {
			console.warn(`Network change, network is ${navigator.onLine ? 'online' : 'offline'}`);

			// network changed from offline to online, re-check current live state
			if (navigator.onLine && sessionUuid) {
				dispatch(setLiveStates(sessionUuid));
			} else if (!navigator.onLine) {

				// user connection dropped, show alert to user
				showAlert({
					message: 'Network Offline',
					description: 'It looks like you are offline. Please check your network connection.',
					type: 'warning'
				});
			}
		};

		if ('connection' in navigator) {
			try {
				(navigator.connection as any).addEventListener('change', handleNetworkChange);
			} catch {
				window.addEventListener('online', handleOnline);
				window.addEventListener('offline', handleOffline);
				console.warn('navigator.connection not supported');
			}
		} else {
			window.addEventListener('online', handleOnline);
			window.addEventListener('offline', handleOffline);
		}

		return () => {
			socket.current?.removeListener('live-state-change', handleLiveStateChange);
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
			if ('connection' in navigator) {
				try {
					(navigator.connection as any).removeEventListener('change', handleNetworkChange);
				} catch {
					console.warn('navigator.connection not supported');
				}
			}
		};
	}, [eventUuid, dispatch, sessionUuid]);

	const [liveState, liveAt] = liveStates[playbackUrl ?? ''] ?? [StreamState.offline, undefined];

	return [liveState === StreamState.live, liveAt];
}