import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface IPortalProps {
	className?: string;
}

const Portal: React.FC<React.PropsWithChildren<IPortalProps>> = ({ children, className = 'custom-portal' }) => {
	const [container] = useState(document.createElement('div'));

	container.classList.add(className);

	useEffect(() => {
		document.body.appendChild(container);
		return () => {
			document.body.removeChild(container);
		};
	}, [container]);

	return ReactDOM.createPortal(children, container);
};

export default Portal;
