import { useEffect, useState } from "react";
import { sleep } from "../utils/utils";
import { GetHv, GetJson } from "./helpers";

interface IProps {
	path: string;
	timer?: number;
	cancel?: boolean;
	headers?: Record<string, string>;
	token?: string;
	repo?: 'api' | 'microservice';
	HVHost?: string;
}

const usePoll = ({
	path,
	timer = 15000,
	cancel = false,
	headers = {},
	token = '',
	repo = 'api',
	HVHost,
}: IProps) => {

	const [result, setResult] = useState<Record<string, unknown>>();
	const [running, setRunning] = useState(false);

	useEffect(() => {
		const run = async () => {
			try {
				if (cancel) return;

				if (repo === 'api') {
					const response = await GetJson({
						path,
						token,
						headers,
					});
					setResult(response);
				}

				if (repo === 'microservice' && HVHost) {
					const response = await GetHv(HVHost, path, token);
					setResult(response);
				}

				await sleep(timer);
				run();
			} catch (error) {
				console.error(error);
			}
		};

		if (!cancel && !running) {
			setRunning(true);
			run();
		}
	}, [cancel, timer, path, headers, token, running, repo, HVHost]);

	return result;
};

export default usePoll;
