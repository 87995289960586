import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import ReactSelect from 'react-select';

import { addTicketingSet, chooseRegistrationStep, getGlobalCurrencies, removeTicketingSet, setRegistrationFeature, updateRegistrationSteps, updateTicketingSet } from "../../../../../../../../store/actions/admin/create-event";
import { GetDefaultTicketingSet } from "../../../../../../../../store/utils/create-event";
import { ExchangeResponse, GlobalCurrency, GlobalPrice, RegistrationStepType, TicketingSet } from "../../../../../../../../types/working-model";
import Icon, { COLORS, ICONS } from "../../../../../../../general-ui/icon";
import ModalComponent from "../../../../../../../general-ui/modal/modal";
import { Popover } from "../../../../../../../general-ui/popover/popover";
import TextInput, { Validation } from "../../../../../../../general-ui/text-input/text";
import "react-datepicker/dist/react-datepicker.css";
import './paid-ticketing.scss';
import { getStripeStatus } from "../../../../../../../../store/actions/admin/integrations";
import SessionSelectInput from "../../../../../../../general-ui/select/session-select";
import useRequiredFields from "../../../../../../../../utils/use-required-fields";
import { useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { convertCurrency, getDisplayPrice, numberWithCommas, toDictTyped, updateTranslateKey } from "../../../../../../../../utils/utils";
import { showAlert } from "../../../../../../../general-ui/alert/alert-service";
import { Tooltip } from "../../../../../../../general-ui/tooltip/tooltip";
import { OptionalComponent } from "../../../../../../../../utils/optional-component";
import { GetExchangeRate } from "../../../../../../../../connection/integrations";
import { reactSelectStyles, TReactSelectOption } from "../../../../../../../general-ui/react-select/react-select-styles";
import { isUsingCustomSSO } from "../../../../../../../../utils/sso-utils";
import PanelBody from "../../panel-body";
import { getDefaultLanguage } from "../../../../../../../live-event/utils";
import Switch from "@general-ui/switch/switch";
import { UpdateRegistrationSteps } from "connection/create-event";

interface IEditableGlobalPrice extends Omit<GlobalPrice, 'price'> {
	price: string | number
}

interface IEditableTicketSet extends Omit<TicketingSet, 'prices'> {
	prices?: IEditableGlobalPrice[]
}

type PaidTicketSettingsProps = {
	inV2?: boolean;
}

const PaidTicketingSettings: React.FC<PaidTicketSettingsProps> = ({ inV2 }) => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const globalCurrencies = useTypedSelector(state => state.CreateEventReducer.globalCurrencies);

	const dispatch = useDispatch();

	const [workingTicketSet, setWorkingTicketSet] = useState<IEditableTicketSet | null>(null);
	const [workingTicketSetIndex, setWorkingTicketSetIndex] = useState(-1);
	const [ticketingModalOpen, setTicketingModalOpen] = useState(false);
	const [openPopoverIndex, setOpenPopoverIndex] = useState(-1);
	const [errors, setErrors] = useState<any | null>({});
	const [currentExchangeRates, setCurrentExchangeRates] = useState<ExchangeResponse | null>();

	//the tickets themselves appear 
	const paidTicketingStep = workingEvent?.registration_steps?.find(step => step.type === RegistrationStepType.ticketing);
	const registrationSteps = workingEvent?.registration_steps;

	const ticketSets = paidTicketingStep?.ticketing;

	const [globalCurrencyMap, globalCurrencyOptions] = useMemo(() => {
		if (!globalCurrencies) return [null, null];

		const currencyMap: { [key: string]: GlobalCurrency } = {};
		const options: TReactSelectOption[] = [];

		for (const c of globalCurrencies) {
			options.push({ label: `${c.code} - ${c.name}`, value: c.code });
			currencyMap[c.code] = c;
		}

		return [currencyMap, options];
	}, [globalCurrencies]);

	const {
		requiredFields,
		updateRequiredField,
		shouldDisplayFieldError,
		hasCompletedRequiredFields,
		resetRequiredFields,
	} = useRequiredFields(['sessions'], {
		'sessions': (sessionIds: number[]) => !!sessionIds.length
	});

	const getBaseLanguage = useCallback(() => workingEvent && getDefaultLanguage(workingEvent), [workingEvent]);

	const addTicketingType = useCallback(async () => {
		if (!workingEvent) return;
		if (isUsingCustomSSO(workingEvent)) {
			showAlert({
				message: "Unable to add paid ticketing",
				description: "Paid ticketing cannot be enabled for events with SSO registration",
				type: "error",
				duration: 5000
			});
		} else {
			const language = getBaseLanguage();
			if (!language) return;
			try {
				const ticketingSet = await GetDefaultTicketingSet(language, workingEvent.sessions);
				dispatch(addTicketingSet(ticketingSet));
			} catch (e) {
				console.error(e);
			}
		}
	}, [dispatch, workingEvent, getBaseLanguage]);

	const removeTicketSet = useCallback((index: number) => {
		return () => {
			// if deleting the last ticket, change reg step to general so an empty ticketing step isn't displayed
			const isLastTicket = paidTicketingStep?.ticketing?.length === 1;
			if (isLastTicket) dispatch(chooseRegistrationStep(RegistrationStepType.general));

			dispatch(removeTicketingSet(index));
			setOpenPopoverIndex(-1);
		};
	}, [dispatch, paidTicketingStep?.ticketing]);

	const editTicketSet = useCallback((index: number) => {
		return () => {
			if (!globalCurrencyMap) return;
			const ticketSet = ticketSets?.[index];
			if (ticketSet?.prices?.length) {
				setWorkingTicketSet({
					...ticketSet,
					prices: ticketSet.prices.map(globalPrice => {
						const stripe_multiplier = globalCurrencyMap[globalPrice.currency]?.stripe_multiplier;
						const canHaveCents = stripe_multiplier !== 1;
						return ({
							...globalPrice,
							price: formatPrice(globalPrice.price, canHaveCents)
						});
					})
				});
			} else if (ticketSet) {
				// make sure there is a currency and price for backwards compatibility to work
				if (!ticketSet.currency || !ticketSet.price) return;

				// this is how we're handling backwards compatibility for tickets created before global payments and are missing the prices field.
				const currencyCode = ticketSet.currency.toUpperCase();
				setWorkingTicketSet({
					...ticketSet,
					prices: [{ price: formatPrice(ticketSet.price), currency: currencyCode, multiplier: globalCurrencyMap[currencyCode]?.stripe_multiplier }]
				});
			}
			setWorkingTicketSetIndex(index);
			setTicketingModalOpen(true);
			setOpenPopoverIndex(-1);
		};
	}, [globalCurrencyMap, ticketSets]);

	const openPopover = useCallback((index: number) => {
		return () => setOpenPopoverIndex(index);
	}, []);

	const closeModal = useCallback(() => {
		resetRequiredFields();
		setTicketingModalOpen(false);
	}, [resetRequiredFields]);

	const saveWorkingTicketSet = useCallback(() => {
		if (!workingTicketSet) {
			closeModal();

			return;
		}

		if (!hasCompletedRequiredFields(workingTicketSet) || !workingTicketSet?.prices) {
			return;
		}

		if (Object.keys(errors).length > 0) {
			return false;
		}
		// save ticket price as a number 
		const pricesAsNumbers = workingTicketSet.prices.map(_price => ({ ..._price, price: Number(_price.price) }));

		// update name_translations and description_translations
		const language = getBaseLanguage();
		if (!language) return;

		dispatch(updateTicketingSet(
			{
				...workingTicketSet,
				prices: pricesAsNumbers,
				name_translations: updateTranslateKey({
					translateString: workingTicketSet.name_translations ?? { base: workingTicketSet.name, changed: '' },
					input: workingTicketSet.name,
					baseLanguage: language,
					language,
				}),
				description_translations: updateTranslateKey({
					translateString: workingTicketSet.description_translations ?? { base: workingTicketSet.description || '', changed: '' },
					input: workingTicketSet.description || '',
					baseLanguage: language,
					language,
				}),
			},
			workingTicketSetIndex
		));

		closeModal();
	}, [workingTicketSet, hasCompletedRequiredFields, errors, getBaseLanguage, dispatch, workingTicketSetIndex, closeModal]);

	const setTextValue = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>) => {
		setWorkingTicketSet(workingTicketSet => {
			if (!workingTicketSet) {
				return null;
			}

			setErrors((errors: any) => {
				if (name === 'name' && value.match(/[A-Za-z0-9]/g) === null) {
					return {
						...errors,
						[name]: true,
					};
				} else {
					const _errors = errors;
					delete _errors[name];

					return _errors;
				}
			});

			return {
				...workingTicketSet,
				[name]: value,
			};
		});
	};

	function maxDecimalPlaces(value: string) {
		return value.includes('.') && value.split('.')[1].length > 2;
	}

	function formatPrice(value: number, canHaveCents = true): string {
		if (!canHaveCents) return value.toFixed(0);
		const stringyValue = value.toFixed(2);
		if (!stringyValue.includes('.')) {
			return stringyValue + '.00';
		}

		// JS cuts trailing 0s off numbers, so we need to add that back here to display it like a currency.
		const [dollars, cents] = stringyValue.split('.');
		// using a string here for display. On save of the modal we will change the strings to be numbers.
		return `${dollars}.${cents.padEnd(2, '0')}`;
	}

	const setNumericValue = ({ target: { valueAsNumber, value, name } }: React.ChangeEvent<HTMLInputElement>, i?: number) => {
		setWorkingTicketSet(workingTicketSet => {
			if (!workingTicketSet || (maxDecimalPlaces(value) && value)) {
				return workingTicketSet;
			}

			setErrors((errors: any) => {
				if (
					(name.includes('price') && valueAsNumber < 0.5) ||
					(name.includes('price') && isNaN(valueAsNumber)) ||
					(name === 'quantity' && valueAsNumber <= 0) ||
					(name === 'quantity' && isNaN(valueAsNumber))) {
					return {
						...errors,
						[name]: true,
					};
				} else {
					const _errors = errors;
					delete _errors[name];

					return _errors;
				}
			});

			if (name === 'quantity') {
				return {
					...workingTicketSet,
					[name]: valueAsNumber,
				};
			} else {
				return {
					...workingTicketSet,
					prices: workingTicketSet.prices?.map((price, j) => {
						if (j === i && currentExchangeRates?.conversion_rates && globalCurrencyMap) {
							// if there is a multiplier of 1, dont let them add cents to this value.
							// There is a slightly weird experience here being that you can add a . but this function doesnt trigger until you add a number after it which removes the number and the dot.
							// alternatively this could be value.match(/(?!00).[0-9]$/) and we remove canHaveCents from formatPrice.
							if (globalCurrencyMap[price.currency].stripe_multiplier === 1 && value.includes('.')) return price;

							// make sure this value equates to at least 1 USD
							if (Number(convertCurrency(currentExchangeRates, valueAsNumber, price.currency, 'USD')) < 1) {
								setErrors((prev: any) => ({ ...prev, [name]: true }));
							}

							return {
								...price,
								// this is going to be a string, but on save were going to update it to be a number
								price: value
							};
						}
						return price;
					})
				};
			}
		});
	};

	const handlePriceBlur = ({ target: { valueAsNumber } }: React.ChangeEvent<HTMLInputElement>, i?: number) => {
		setWorkingTicketSet(prev => {
			if (!prev || !prev?.prices || !globalCurrencyMap) return prev;

			return {
				...prev,
				prices: prev.prices?.map((price, j) => {
					if (j === i) {
						const stripe_multiplier = globalCurrencyMap[price.currency]?.stripe_multiplier;
						const canHaveCents = stripe_multiplier !== 1;
						return {
							...price,
							// letting them save a string here, then on save were converting it to a number
							price: formatPrice(valueAsNumber, canHaveCents)
						};
					}
					return price;
				})
			};
		});
	};

	const usedCurrencyDict = useMemo(() => workingTicketSet?.prices ? toDictTyped('currency', workingTicketSet.prices) : {}, [workingTicketSet?.prices]);

	const fetchCurrencyConversion = useCallback(async (currencyCode: string) => {
		if (!token || !currencyCode) return;

		try {
			const res = await GetExchangeRate(token, currencyCode);
			setCurrentExchangeRates(res);
		} catch (e) {
			showAlert({
				type: 'error',
				message: 'Failed to fetch exchange rates',
				description: 'Please try again later',
				duration: 5000
			});
			console.error(e);
		}
	}, [token]);

	useEffect(() => {
		// update currentExchangeRates whenever base ticket currency doesn't match base currentExchangeRates
		const baseCurrency = workingTicketSet?.prices?.[0].currency;
		if (baseCurrency && (baseCurrency !== currentExchangeRates?.base)) {
			fetchCurrencyConversion(baseCurrency);
		}
	}, [currentExchangeRates, fetchCurrencyConversion, token, workingTicketSet?.prices]);

	const handleCurrencyChange = async (currency: string, i: number) => {
		if (usedCurrencyDict[currency]) {
			showAlert({
				message: 'Cannot add currency',
				description: `${currency} is already used for this ticket.`,
				type: 'error',
				duration: 5000
			});
			return;
		}

		setWorkingTicketSet(prev => {
			if (!prev || !prev?.prices || !currentExchangeRates || !globalCurrencyMap) return null;

			const basePrice = prev.prices[0].price;
			let priceAfterExchangeRate = Number(basePrice) * currentExchangeRates.conversion_rates[currency];

			const { stripe_multiplier } = globalCurrencyMap[currency];
			const canHaveCents = stripe_multiplier !== 1;
			if (!canHaveCents) {
				priceAfterExchangeRate = Math.ceil(priceAfterExchangeRate);
			}
			const newCurrency = { price: formatPrice(priceAfterExchangeRate, canHaveCents), currency, multiplier: globalCurrencyMap[currency].stripe_multiplier };

			return {
				...prev,
				prices: prev?.prices?.map((price, j) => {
					if (j === i) {
						return newCurrency;
					}
					return price;
				})
			};
		});
	};

	const handleAddCurrency = async () => {
		setWorkingTicketSet(prev => {
			if (!prev || !prev?.prices || !currentExchangeRates || !globalCurrencyMap) return prev;
			const basePrice = prev.prices[0].price;
			const newCurrencyOption = globalCurrencyOptions?.find(currency => !usedCurrencyDict[currency.value]);
			if (!newCurrencyOption) return prev;

			const newCurrencyCode = newCurrencyOption.value;
			const { stripe_multiplier } = globalCurrencyMap[newCurrencyCode];
			let priceAfterExchangeRate = (Number(basePrice) * currentExchangeRates.conversion_rates[newCurrencyCode]);
			const canHaveCents = stripe_multiplier !== 1;

			if (!canHaveCents) {
				priceAfterExchangeRate = Math.ceil(priceAfterExchangeRate);
			}
			const newCurrency = { price: formatPrice(priceAfterExchangeRate, canHaveCents), currency: newCurrencyCode, multiplier: globalCurrencyMap[newCurrencyCode].stripe_multiplier };

			return {
				...prev,
				prices: [...prev.prices, newCurrency]
			};
		});
	};

	const handleRecalculateExchangeRates = async () => {
		// Heads up these only update every 12 hours, this is more of a reset button
		// loop thru all prices (except first) and update each price to exchange rate for that currency code
		setWorkingTicketSet(prev => {
			if (!prev || !prev?.prices || !currentExchangeRates || !globalCurrencyMap) return prev;
			const basePrice = prev.prices[0].price;

			const pricesWithUpdatedExchangeRates = prev.prices.map((_price, i) => {
				// first price is the base price so we dont need to do that one
				if (i) {
					// get currency multiplier and update if we need to 
					let priceAfterExchangeRate = currentExchangeRates.conversion_rates[_price.currency] * Number(basePrice);
					const { stripe_multiplier } = globalCurrencyMap[_price.currency];
					const canHaveCents = stripe_multiplier !== 1;
					if (!canHaveCents) {
						priceAfterExchangeRate = Math.ceil(priceAfterExchangeRate);
					}
					return {
						..._price,
						price: formatPrice(priceAfterExchangeRate, canHaveCents)
					};
				}
				return _price;
			});
			return {
				...prev,
				prices: pricesWithUpdatedExchangeRates
			};
		});
	};

	const handleRemoveCurrency = (index: number) => {
		setWorkingTicketSet(prev => {
			if (!prev || !prev.prices) return prev;
			return {
				...prev,
				prices: prev.prices.filter((_, j) => index !== j)
			};
		});
	};

	const setSessionIds = (sessions: number[]) => {
		const selectedSessionUuids = workingEvent?.sessions.filter(session => sessions.indexOf(session.session) >= 0).map(session => session.uuid) || [];
		// setSessionUuids(selectedSessionUuids);

		// setSessionIds gets called on render, only update required
		// fields if sessions param is not initial sessions (by reference)
		if (workingTicketSet?.sessions !== sessions) {
			updateRequiredField('sessions', sessions);
		}
		setWorkingTicketSet(workingTicketSet => {
			if (!workingTicketSet) {
				return null;
			}

			return {
				...workingTicketSet,
				sessions: sessions,
				session_uuids: selectedSessionUuids,
			};
		});
	};

	const handleToggle = useCallback(async (_value: RegistrationStepType, isOn: boolean) => {
		try {
			if (!registrationSteps?.length || !workingEvent || !token) return;
			const updatedSteps = registrationSteps.map(_step => {
				if (_step.type !== _value) return _step;
				return {
					..._step,
					isOn
				};
			});

			// if ticketing is being turned on, also make sure send email is on (email is required for ticketing)
			if (_value === RegistrationStepType.ticketing && isOn) {
				const sendEmails = workingEvent.registration_settings?.sendEmailConfirmation;
				if (!sendEmails) {
					dispatch(setRegistrationFeature("sendEmailConfirmation", true));
				}
			}

			dispatch(updateRegistrationSteps(updatedSteps));
			await UpdateRegistrationSteps(token, workingEvent.uuid, updatedSteps);
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Error updating registration step",
				description: "We ran into an error updating that step. Please try again.",
				duration: 5000,
				type: "error"
			});
		}
	}, [dispatch, registrationSteps, token, workingEvent]);

	useEffect(() => {
		function handleFocus() {
			if (token) {
				dispatch(getStripeStatus(token));
			}
		}

		handleFocus();
		window.addEventListener('focus', handleFocus);

		return () => window.removeEventListener('focus', handleFocus);
	}, [dispatch, token]);

	useEffect(() => {
		if (token && !globalCurrencies) {
			dispatch(getGlobalCurrencies(token));
		}
	}, [dispatch, globalCurrencies, ticketingModalOpen, token]);

	if (!workingEvent?.sessions) return null;

	// TODO: Stripe conversion site goes here with proper messaging
	const ticketPricesToolTip = (
		<p>Earnings from payments will be based on exchange rates at time of ticket purchase. Please read the <a href="https://dashboard.stripe.com/currency_conversion" target="_blank" rel="noreferrer">Stripe currency conversion documents</a> for more information.</p>
	);

	const exchangeRateTooltip = (
		<p>Exchange rates are estimates, pulled from <a href="https://www.exchangerate-api.com/" target="_blank" rel="noreferrer">ExchangeRate-API</a> using the first currency as the base currency.</p>
	);

	return (
		<>
			<PanelBody>
				{inV2 && (
					<div className="registration-option-v2 avatar-registration-option-v2">
						<div className="toggle-card in-padded-container">
							<label>Ticketing</label>
							<div>
								<Switch
									on={paidTicketingStep?.isOn ?? false}
									onClick={handleToggle}
									value={RegistrationStepType.ticketing}
								/>
							</div>
						</div>
					</div>
				)}

				<div className="paid-ticketing-header">
					<label>Ticket types</label>
					<button className="no-style" onClick={addTicketingType}>
						+ Add ticket type
					</button>
				</div>
				{paidTicketingStep?.ticketing?.map((ticketSet, index) => {
					let currencyDisplay;
					if (ticketSet?.prices?.length) {
						currencyDisplay = getDisplayPrice(Number(ticketSet.prices[0].price), ticketSet.prices[0].currency, ticketSet.prices[0].multiplier);
					} else {
						currencyDisplay = getDisplayPrice(ticketSet.price, ticketSet.currency, 100);
					}

					return (
						<div key={`${ticketSet.name}.${index}`} className="paid-ticketing-card">
							<div className="ticket-data">

								<label>{ticketSet.name}</label>
								<span>{numberWithCommas(ticketSet.quantity)} tickets • {currencyDisplay}</span>
								<div className="ticket-currency-list">
									{ticketSet.prices ?
										ticketSet.prices?.map((globalPrice, i, arr) => {
											// stop adding bubbles after the 3rd currency
											if (i > 3) return null;
											// display how many are remanding after first 3 currency's are displayed
											if (i === 3) return (
												<div className="ticket-currency-bubble" key={`${globalPrice.currency}-${i}`}>+{arr.length - 3}</div>
											);
											return (
												<div className="ticket-currency-bubble" key={`${globalPrice.currency}-${i}`}>{globalPrice.currency}</div>
											);
										}) : (
											<div className="ticket-currency-bubble">{ticketSet.currency}</div>
										)}
								</div>
							</div>
							<div className="ticket-popover">
								<button onClick={openPopover(index)} className="no-style open-popover">
									<Icon name={ICONS.THREE_DOTS_HORIZONTAL} size={18} color={COLORS.WHITE} />
								</button>
								<Popover
									open={openPopoverIndex === index}
								>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<button onClick={editTicketSet(index)}>Edit</button>
										<button onClick={removeTicketSet(index)}>Delete</button>
									</div>

								</Popover>
							</div>
						</div>
					);
				})}
				{/* FUTURE DEV - Integrations are not included in MVP */}
				{/* <div className="paid-ticketing-header" style={{ marginTop: 28 }}>
					<label>Payment method</label>
				</div>

				<div className="stripe-status">
					{stripe.status === StripeStatus.ready ? (
						<>
							<p>Stripe is connected</p>
							<a href={"/admin/settings/integrations"} className="button primary" target="_blank" rel="noreferrer">Go to Integrations</a>
						</>
					) : (
						<>
							<p>Connect your Stripe account to collect payments from your events</p>
							<a href={"/admin/settings/integrations"} className="button primary" target="_blank" rel="noreferrer">Connect with Stripe</a>
						</>
					)}
				</div> */}
			</PanelBody>

			<ModalComponent
				closeable={false}
				cancellable={false}
				open={ticketingModalOpen}
				onRequestClose={closeModal}
				title={"Add tickets"}
				subtitle={"You can create multiple ticket types to sell"}
				footer={(
					<>
						<button onClick={closeModal}>Cancel</button>
						<button onClick={saveWorkingTicketSet} className="lemonade" disabled={Object.keys(errors).length > 0}>
							Save
						</button>
					</>
				)}
			>
				<div className="paid-ticketing-modal">
					<div className="paid-ticketing-settings">
						<TextInput
							defaultValue={workingTicketSet?.name ?? ''}
							label="Name"
							onChange={setTextValue}
							style={{ gridArea: "name" }}
							id="ticket_name"
							name="name"
							required
							valid={errors.name ? Validation.error : Validation.normal}
						/>
						<TextInput
							defaultValue={workingTicketSet?.quantity ?? ''}
							label="Quantity"
							numeric={true}
							onChange={setNumericValue}
							min={0}
							style={{ gridArea: "quantity" }}
							id="ticket_quantity"
							name="quantity"
							required
							valid={errors.quantity ? Validation.error : Validation.normal}
						/>
						<TextInput
							defaultValue={workingTicketSet?.description ?? ''}
							label="Description"
							onChange={setTextValue}
							style={{ gridArea: "description" }}
							id="ticket_description"
							name="description"
						/>
						<div className="currency-container">
							<div className="currency-title-container">
								<label>
									Available Currencies
								</label>
								<OptionalComponent display={(workingTicketSet?.prices?.length ?? 0) > 1}>
									<Tooltip tooltip={exchangeRateTooltip}>
										<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
									</Tooltip>
								</OptionalComponent>
							</div>
							<ul>
								{workingTicketSet?.prices && workingTicketSet.prices.map((ticketPrice, i, arr) => {
									if (!globalCurrencyMap || !globalCurrencyOptions) return null;
									const selectedCurrency = globalCurrencyMap[ticketPrice.currency];

									return (
										<li key={`${ticketPrice.currency}-${i}`} className="currency-row">
											<TextInput
												label="Price"
												numeric
												value={ticketPrice.price.toString()}
												onChange={e => setNumericValue(e, i)}
												onBlur={e => handlePriceBlur(e, i)}
												min={0.5}
												max={99999.99}
												step={0.01}
												id="ticket_price"
												name={`price-${i}`}
												required
												valid={errors?.[`price-${i}`] ? Validation.error : Validation.normal}
											/>
											<div className="currency-dropdown">
												<label htmlFor="currency">
													Currency
												</label>
												<OptionalComponent display={(i === 0 && arr.length > 1)}>
													<Tooltip tooltip="This base currency is used to calculate new currencies exchange rate.">
														<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
													</Tooltip>
												</OptionalComponent>

												<ReactSelect
													styles={reactSelectStyles({
														menu: { right: 0, top: 0 },
														menuList: { fontSize: "14px" },
														control: { borderRadius: 100, border: '1px solid #625E70', borderColor: '#625E70', fontSize: "14px", padding: '0', height: '46px', maxWidth: '90%', marginLeft: '18px', marginBottom: '1px' },
														focusedBorderColor: '#908CA0'
													})}
													options={globalCurrencyOptions}
													value={{ label: `${selectedCurrency.code} - ${selectedCurrency.name}`, value: selectedCurrency.code }}
													onChange={value => value && handleCurrencyChange(value.value, i)}
													isClearable={false}
													isSearchable
													menuPortalTarget={document.body}
													name="currency"
												/>
											</div>
											{!i ? (
												// when we have more than 1 concurrency, display this tooltip with link to stripe estimated conversion site
												<OptionalComponent display={(workingTicketSet.prices?.length ?? 0) > 1}>
													<Tooltip position="left" tooltip={ticketPricesToolTip}><Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} /></Tooltip>
												</OptionalComponent>
											) : (
												<button className="no-style no-padding remove-currency" onClick={() => handleRemoveCurrency(i)}>
													<Icon name={ICONS.TRASH_OUTLINE} size={16} color={COLORS.WHITE} />
												</button>
											)}
										</li>
									);
								})}
							</ul>
							<div className="currency-list-footer">
								<OptionalComponent display={(workingTicketSet?.prices?.length ?? 0) > 1}>
									<button className="recalculate no-style no-padding" onClick={handleRecalculateExchangeRates} disabled={!!Object.keys(errors).find(key => key.match(/^price-[0-9]$/))}>
										Recalculate all
									</button>
								</OptionalComponent>
								<button className="add-currency no-style no-padding" onClick={handleAddCurrency}>
									+ Add Currency
								</button>
							</div>
						</div>
						<div style={{ gridArea: "session" }} className="field-group">
							<SessionSelectInput
								placeholder="Select Session"
								sessions={workingEvent.sessions}
								onChange={setSessionIds}
								preSelectedSessions={workingTicketSet?.sessions ?? []}
								valid={requiredFields['sessions']}
								errorMessage={shouldDisplayFieldError('sessions') ? 'Select at least one session.' : undefined}
							/>
						</div>
					</div>
				</div>
			</ModalComponent>
		</>
	);
};

export default PaidTicketingSettings;