import classNames from 'classnames';
import React, { ReactNode, useEffect, useRef, useState, useMemo } from 'react';

import usePrevious from '../../../utils/use-previous';

import './popover.scss';

interface Props {
	open: boolean;
	onClose?: () => void;
	children: ReactNode;
	style?: React.CSSProperties;
	bottom?: boolean;
	className?: string;
	ignoreRef?: React.RefObject<HTMLElement | null> | React.MutableRefObject<HTMLElement | null>;
}

export function Popover({
	open,
	onClose,
	children,
	style = {},
	className = '',
	bottom = false,
	ignoreRef
}: Props): JSX.Element {
	const selector = useRef<HTMLDivElement | null>(null);
	const [displayNone, setDisplayNone] = useState(true);
	const [visible, setVisible] = useState(false);
	const prevOpen = usePrevious(open);

	const timeout = useRef<NodeJS.Timeout | null>(null);
	useEffect(() => {
		// We want to set the content to display none if it's closed
		// otherwise everything inside can be tabbed to and clicked when it's collapsed.
		// But we also want to maintain the css transitions, so we need to delay setting it to display none
		// until it's collapsed
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		if (open) {
			setVisible(true);
			setDisplayNone(false);
		} else {
			timeout.current = setTimeout(() => {
				setDisplayNone(true);
			}, 200);
		}
	}, [open]);

	// if the popover is in a modal we can't use the provided onClose function because it blocks the popover from opening
	const popover = selector.current;
	const inModal = useMemo(() => popover?.closest('.modal') !== null, [popover]);

	// on escape, close dropdown
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				onClose && onClose();
			}
		};
		if (!inModal) {
			document.addEventListener('keydown', handleKeyDown);
		}
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [inModal, onClose]);

	useEffect(() => {
		const checkClick = (e: any) => {
			if (!open) return;

			// looks this css key not need more if (e.target?.className?.includes?.('popover-opener')) return;
			const path = e.path || e.composedPath();

			for (const item of path) {
				if (selector.current === item || ignoreRef?.current === item) return;
			}

			onClose && onClose();
			window.removeEventListener('click', checkClick);
		};

		// we need to check if it's already open, otherwise it immediately closes
		if (selector.current?.classList.contains('open') && !inModal) {
			setTimeout(() => {
				window.addEventListener('click', checkClick);
			}, 100);
		}

		return () => {
			window.removeEventListener('click', checkClick);
		};
	}, [open, onClose, prevOpen, inModal, ignoreRef]);

	return (
		<div
			className={classNames(`popover-wrapper${className ? ` ${className}` : ''}`, { open, bottom })}
			onTransitionEnd={() => {
				if (!open) {
					setVisible(false);
				}
			}}
			style={{ ...style, display: visible ? 'block' : 'none' }}
			ref={selector}
		>
			<div style={{ display: displayNone ? 'none' : 'block' }}>
				{children}
			</div>
		</div>
	);
}
