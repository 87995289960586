import { extractFileType } from '../../utils/utils';
import {
	Document,
	EventListSortType,
	fileType,
	IQuestionSocket,
	ISocketCommentProps,
	Product,
	StaticVideo,
	UstreamVideo,
	WistiaVideo,
} from '../../types/working-model';
import { PATHNAMES } from '../../utils/admin-routing-utils';
import { FontPack, ThemePack } from 'types/theme-packs';

export const THUMBNAIL_TYPES = new Set(['pptx', 'docx', 'xlsx', 'key', 'pdf']);
export const FILE_TYPES = {
	ALL_DOCS: 0,
	PDF: 1,
	WORD: 2,
	EXCEL: 3,
	POWERPOINT: 4,
	MISC: 5, //All file types in "Documents" cotegory must be lower numbers than MISC
	IMAGES: 6,
	RECORDED: 7,
	UPLOADED: 8,
	FONTS: 9,
	THEME_PACKS: 10,
	_DEPRECATED_THEMES: 11,
	TEMPLATES: 12,
	VIDEO_ARCHIVE: 13
};

export const FILE_TYPE_ROUTE_MAP: { [key: number]: string } = {
	0: PATHNAMES.ContentSubLevels.Documents.All,
	1: PATHNAMES.ContentSubLevels.Documents.Pdf,
	2: PATHNAMES.ContentSubLevels.Documents.Word,
	3: PATHNAMES.ContentSubLevels.Documents.Excel,
	4: PATHNAMES.ContentSubLevels.Documents.Powerpoint,
	5: PATHNAMES.ContentSubLevels.Documents.Misc,
	6: PATHNAMES.ContentSubLevels.Images,
	7: PATHNAMES.ContentSubLevels.Videos.Recorded,
	8: PATHNAMES.ContentSubLevels.Videos.Uploaded,
	9: PATHNAMES.ContentSubLevels.Fonts,
	10: PATHNAMES.ContentSubLevels.Palettes,
	11: PATHNAMES.ContentSubLevels.Themes,
	12: PATHNAMES.ContentSubLevels.Templates,
	13: PATHNAMES.ContentSubLevels.Videos.Archive
};

export const ROUTE_FILE_TYPE_MAP: { [key: string]: number } = {
	[PATHNAMES.ContentSubLevels.Documents.All]: 0,
	[PATHNAMES.ContentSubLevels.Documents.Pdf]: 1,
	[PATHNAMES.ContentSubLevels.Documents.Word]: 2,
	[PATHNAMES.ContentSubLevels.Documents.Excel]: 3,
	[PATHNAMES.ContentSubLevels.Documents.Powerpoint]: 4,
	[PATHNAMES.ContentSubLevels.Documents.Misc]: 5,
	[PATHNAMES.ContentSubLevels.Images]: 6,
	[PATHNAMES.ContentSubLevels.Videos.Recorded]: 7,
	[PATHNAMES.ContentSubLevels.Videos.Uploaded]: 8,
	[PATHNAMES.ContentSubLevels.Fonts]: 9,
	[PATHNAMES.ContentSubLevels.Palettes]: 10,
	[PATHNAMES.ContentSubLevels.Themes]: 11,
	[PATHNAMES.ContentSubLevels.Templates]: 12,
	[PATHNAMES.ContentSubLevels.Videos.Archive]: 13
};

export const FILE_TYPE_KEYS = Object.entries(FILE_TYPES).reduce(
	(acc: { [key: number]: string }, curr) => {
		acc[curr[1]] = curr[0];
		return acc;
	},
	{}
);

export const FILE_NUMBER_MAP: { [key: string]: number } = {
	pdf: 1,
	doc: 2,
	docx: 2,
	docm: 2,
	csv: 3,
	xls: 3,
	xla: 3,
	xlam: 3,
	xlsm: 3,
	xlsx: 3,
	ppt: 4,
	pptx: 4,
	pptm: 4,
	jpg: 6,
	png: 6,
	jpeg: 6,
	gif: 6,
	webp: 6,
	svg: 6,
	tiff: 6,
	tif: 6,
};

export const isDocumentCategory = (fileType: number): boolean =>
	fileType <= FILE_TYPES.MISC;

export function filterDocsByFileType(documents: Document[], fileType: number): Document[] {
	if (!documents.length) { return []; }

	return documents.filter((doc) => {
		const extractedFileType = extractFileType(doc.original_name);
		const fileNumber =
			FILE_NUMBER_MAP[extractedFileType] || FILE_TYPES.MISC; //If not mapped, assign MISC

		if (fileType === FILE_TYPES.ALL_DOCS && isDocumentCategory(fileNumber)) { return true; }
		else if (fileType === fileNumber) { return true; }
		else { return false; }
	});
}

export const SORT_METHODS = {
	NEWEST: 'newest',
	OLDEST: 'oldest',
	AZ: 'a-z',
	ZA: 'z-a',
	LARGEST: 'largest',
	SMALLEST: 'smallest',
	LONGEST: 'longest',
	SHORTEST: 'shortest',
};

export const CONTENT_SORT_TO_EVENT_SORT = {
	[SORT_METHODS.NEWEST]: EventListSortType.dateAsc,
	[SORT_METHODS.OLDEST]: EventListSortType.dateDesc,
	[SORT_METHODS.AZ]: EventListSortType.alphaAsc,
	[SORT_METHODS.ZA]: EventListSortType.alphaDesc,
};

export function sortDocs(docs: Document[], sortBy: string): Document[] {
	return [...docs].sort((a, b) => {
		const nameA = a.display_name.base.toLowerCase();
		const nameB = b.display_name.base.toLowerCase();
		const dateA = new Date(a.created_at);
		const dateB = new Date(b.created_at);
		const sizeA = a.filesize;
		const sizeB = b.filesize;

		switch (sortBy) {
			case SORT_METHODS.AZ:
				return nameA < nameB ? -1 : 1;
			case SORT_METHODS.ZA:
				return nameA > nameB ? -1 : 1;
			case SORT_METHODS.NEWEST:
				return dateA > dateB ? -1 : 1;
			case SORT_METHODS.OLDEST:
				return dateA < dateB ? -1 : 1;
			case SORT_METHODS.SMALLEST:
				return sizeA - sizeB;
			case SORT_METHODS.LARGEST:
				return sizeB - sizeA;
			default:
				return 0;
		}
	});
}

export function sortUstreamVideos(vids: UstreamVideo[], sortBy: string): UstreamVideo[] {
	return [...vids].sort((a, b) => {
		const nameA = a.title.toLowerCase();
		const nameB = b.title.toLowerCase();
		const dateA = new Date(a.created_at);
		const dateB = new Date(b.created_at);
		const lengthA = parseInt(a.length, 10);
		const lengthB = parseInt(b.length, 10);

		switch (sortBy) {
			case SORT_METHODS.AZ:
				return nameA < nameB ? -1 : 1;
			case SORT_METHODS.ZA:
				return nameA > nameB ? -1 : 1;
			case SORT_METHODS.NEWEST:
				return dateA > dateB ? -1 : 1;
			case SORT_METHODS.OLDEST:
				return dateA < dateB ? -1 : 1;
			case SORT_METHODS.SHORTEST:
				return lengthA - lengthB;
			case SORT_METHODS.LONGEST:
				return lengthB - lengthA;
			default:
				return 0;
		}
	});
}

export function sortWistiaVideos(vids: WistiaVideo[], sortBy: string): WistiaVideo[] {
	return [...vids].sort((a, b) => {
		const nameA = a.name.toLowerCase();
		const nameB = b.name.toLowerCase();
		const dateA = new Date(a.created);
		const dateB = new Date(b.created);
		const lengthA = a.duration;
		const lengthB = b.duration;

		switch (sortBy) {
			case SORT_METHODS.AZ:
				return nameA < nameB ? -1 : 1;
			case SORT_METHODS.ZA:
				return nameA > nameB ? -1 : 1;
			case SORT_METHODS.NEWEST:
				return dateA > dateB ? -1 : 1;
			case SORT_METHODS.OLDEST:
				return dateA < dateB ? -1 : 1;
			case SORT_METHODS.SHORTEST:
				return lengthA - lengthB;
			case SORT_METHODS.LONGEST:
				return lengthB - lengthA;
			default:
				return 0;
		}
	});
}

export function sortStaticVideos(vids: StaticVideo[], sortBy: string): StaticVideo[] {
	return [...vids].sort((a, b) => {

		const nameA = a.name.toLowerCase();
		const nameB = b.name.toLowerCase();
		const dateA = a.created_date;
		const dateB = b.created_date;
		// "recorded" type videos duration are in Milliseconds, the rest are in seconds
		const lengthA = a.duration
			? (a.type === "recorded" ? a.duration / 1000 : a.duration)
			: 0;
		const lengthB = b.duration
			? (b.type === "recorded" ? b.duration / 1000 : b.duration)
			: 0;

		switch (sortBy) {
			case SORT_METHODS.AZ:
				return nameA < nameB ? -1 : 1;
			case SORT_METHODS.ZA:
				return nameA > nameB ? -1 : 1;
			case SORT_METHODS.NEWEST:
				return dateA > dateB ? -1 : 1;
			case SORT_METHODS.OLDEST:
				return dateA < dateB ? -1 : 1;
			case SORT_METHODS.SHORTEST:
				return lengthA - lengthB;
			case SORT_METHODS.LONGEST:
				return lengthB - lengthA;
			default:
				return 0;
		}
	});
}

export function sortMiscModule(list: FontPack[] | ThemePack[], sortBy: string): (FontPack | ThemePack)[] {
	return [...list].sort((a, b) => {
		const nameA = a.title.toLowerCase();
		const nameB = b.title.toLowerCase();
		const dateA = new Date(a.created_at);
		const dateB = new Date(b.created_at);

		switch (sortBy) {
			case SORT_METHODS.AZ:
				return nameA < nameB ? -1 : 1;
			case SORT_METHODS.ZA:
				return nameA > nameB ? -1 : 1;
			case SORT_METHODS.NEWEST:
				return dateA > dateB ? -1 : 1;
			case SORT_METHODS.OLDEST:
				return dateA < dateB ? -1 : 1;
			default:
				return 0;
		}
	});
}

export function sortProducts(list: Product[], sortBy: string): Product[] {
	return [...list].sort((a, b) => {
		const nameA = a.product_title.base.toLowerCase();
		const nameB = b.product_title.base.toLowerCase();
		const dateA = new Date(a.created_at);
		const dateB = new Date(b.created_at);

		switch (sortBy) {
			case SORT_METHODS.AZ:
				return nameA < nameB ? -1 : 1;
			case SORT_METHODS.ZA:
				return nameA > nameB ? -1 : 1;
			case SORT_METHODS.NEWEST:
				return dateA > dateB ? -1 : 1;
			case SORT_METHODS.OLDEST:
				return dateA < dateB ? -1 : 1;
			default:
				return 0;
		}
	});
}

export function sortPresenterMessages(
	presenterMap: Map<string, IQuestionSocket | ISocketCommentProps>
): Map<string, IQuestionSocket | ISocketCommentProps> {
	return new Map(
		Array.from(presenterMap.entries()).sort((a, b) => {
			if (a[1].sent_to_presenter_at && b[1].sent_to_presenter_at) {
				return (
					new Date(a[1].sent_to_presenter_at).getTime() -
					new Date(b[1].sent_to_presenter_at).getTime()
				);
			}

			return 0;
		})
	);
}

export enum UPLOAD_TYPES {
	DOCUMENT = 'Documents',
	IMAGE = "Images",
	VIDEO = 'Videos',
	FONT = "Fonts",
	THEMES = "Themes",
}

export const allowedFileTypes: { [key: string]: fileType[] } = {
	[UPLOAD_TYPES.DOCUMENT]: [
		'application/pdf',
		'application/msword',
		'video/mp4',
		'video/mov',
		'video/quicktime',
		'application/postscript',
		'application/font-sfnt',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/vnd.ms-powerpoint',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'font/ttf',
		'font/otf',
		'font/woff',
		'application/font-woff',
		'application/xml',
		'text/xml'
	],
	[UPLOAD_TYPES.IMAGE]: [
		'image/jpg',
		'image/jpeg',
		'image/png',
		'image/gif',
		'image/svg+xml'
	],
	[UPLOAD_TYPES.VIDEO]: [
		'video/mp4',
	],
};
