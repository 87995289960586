import React, { useEffect, useMemo, useReducer } from 'react';
import { useDispatch } from 'react-redux';

import { setLiveEventSessionPlayerType } from '../../../../store/actions/event/event-actions';
import { BrandliveEvent, CreateVideo, FiresideSessionSettings, LanguagesAbbr, ReactionConfig, Session } from '../../../../types/working-model';
import { DefaultQuality } from './video/constants';
import { QualityLevel, VideoPlayerType } from './video/types';
import { getEnabledReactions, getReactionsEnabled, getSecondaryVideo, getVideoPlayerType, getShouldShowPlaceholder, getLastVolume } from './video/utils';
import { UseFiresideMeetData } from '../hooks/fireside-meet';
import { useIsAdmin } from 'hooks/context.hooks';

export enum Actions {
	Play,
	Pause,
	SeekTo,
	SeekComplete,
	SetVolume,
	Ended,
	SetMuted,
	LoadCaptions,
	LoadPlaybackRates,
	SetPlaybackRate,
	SetSelectedCaptions,
	EnableCaptions,
	DisableCaptions,
	LoadTranscript,
	EnableTranscript,
	DisableTranscript,
	LoadQualities,
	SetQuality,
	SetActiveQuality,
	SetActiveQualityByIndex,
	SetPlayerType,
	SetPlaybackUrl,
	SetSecondaryUrl,
	SetIsLive,
	SetBuffering,
	SetPlaying,
	SetDurationInSeconds,
	SetPip,
	SetUseNativePip,
	SetSession,
	SetIsMobile,
	SetChatIsOverlaid,
	SetChatClosed,
	SetReactionsEnabled,
	SetReactions,
	SetVideo,
	CancelPip,
	SetHasRecordedCues,
	SetHasLiveCues,
	SetHasIosCaptions,
	ResetIntialPlay,
	SetShowPlaceholder,
	SetPlayerTypeAndStateUpdate,
	SetIdle,
	SetAutoplayMuted,
	UnmuteFromAutoplay,
	SetStreamStartTime,
	SetManualPip,
	SetOverlayOpen,
	SetModeratorView,
	SetFiresideSettings,
	SetIsTestBroadcast,
	ForceRefresh
}

type DispatchedAction<T, P> = { type: T, payload: P };
type PlayAction = DispatchedAction<Actions.Play, void>;
type PauseAction = DispatchedAction<Actions.Pause, void>;
type SeekToAction = DispatchedAction<Actions.SeekTo, number>;
type SeekCompleteAction = DispatchedAction<Actions.SeekComplete, boolean>;
type SetVolumeAction = DispatchedAction<Actions.SetVolume, number>;
type EndedAction = DispatchedAction<Actions.Ended, void>;
type SetMutedAction = DispatchedAction<Actions.SetMuted, boolean>;
type LoadCaptionsAction = DispatchedAction<Actions.LoadCaptions, TextTrack[]>;
type LoadPlaybackRates = DispatchedAction<Actions.LoadPlaybackRates, number[]>;
type SetPlaybackRate = DispatchedAction<Actions.SetPlaybackRate, number>;
type EnableCaptionsAction = DispatchedAction<Actions.EnableCaptions, void>;
type DisableCaptionsAction = DispatchedAction<Actions.DisableCaptions, void>;
type LoadTranscriptAction = DispatchedAction<Actions.LoadTranscript, void>;
type EnableTranscriptAction = DispatchedAction<Actions.EnableTranscript, void>;
type DisableTranscriptAction = DispatchedAction<Actions.DisableTranscript, void>;
type LoadQualitiesAction = DispatchedAction<Actions.LoadQualities, QualityLevel[]>;
type SetQualityAction = DispatchedAction<Actions.SetQuality, QualityLevel>;
type SetActiveQualityAction = DispatchedAction<Actions.SetActiveQuality, QualityLevel>;
type SetActiveQualityByIndexAction = DispatchedAction<Actions.SetActiveQualityByIndex, number>;
type SetPlayerTypeAction = DispatchedAction<Actions.SetPlayerType, VideoPlayerType>;
type SetPlaybackUrlAction = DispatchedAction<Actions.SetPlaybackUrl, string | undefined>;
type SetSecondaryUrlAction = DispatchedAction<Actions.SetSecondaryUrl, string | undefined>;
type SetIsLiveAction = DispatchedAction<Actions.SetIsLive, boolean>;
type SetBufferingAction = DispatchedAction<Actions.SetBuffering, boolean>;
type SetPlayingAction = DispatchedAction<Actions.SetPlaying, boolean>;
type SetDurationInSecondsAction = DispatchedAction<Actions.SetDurationInSeconds, number>;
// type SetProgressActions = DispatchedAction<Actions.SetProgress, TimeUpdate>;
type SetPipAction = DispatchedAction<Actions.SetPip, boolean>;
type SetUseNativePipAction = DispatchedAction<Actions.SetUseNativePip, boolean>;
type SetSessionAction = DispatchedAction<Actions.SetSession, Session>;
type SetIsMobileAction = DispatchedAction<Actions.SetIsMobile, boolean>;
type SetChatIsOverlaidAction = DispatchedAction<Actions.SetChatIsOverlaid, boolean>;
type SetChatClosedAction = DispatchedAction<Actions.SetChatClosed, boolean>;
type SetReactionsEnabledAction = DispatchedAction<Actions.SetReactionsEnabled, boolean>;
type SetReactionsAction = DispatchedAction<Actions.SetReactions, ReactionConfig[]>;
type SetVideoAction = DispatchedAction<Actions.SetVideo, CreateVideo | undefined>;
type SetSelectedCaptionsAction = DispatchedAction<Actions.SetSelectedCaptions, TextTrack | undefined>;
// type UpdateCuesAction = DispatchedAction<Actions.UpdateCues, { addCues: string[], removeCues: string[] }>;
// type ReplaceCuesAction = DispatchedAction<Actions.ReplaceCues, string[]>;
// type AddCueAction = DispatchedAction<Actions.AddCue, string>;
// type RemoveCueAction = DispatchedAction<Actions.RemoveCue, string>;
type CancelPipAction = DispatchedAction<Actions.CancelPip, boolean>;
type SetHasRecordedCuesAction = DispatchedAction<Actions.SetHasRecordedCues, boolean>;
type SetHasLiveCuesAction = DispatchedAction<Actions.SetHasLiveCues, boolean>;
type SetHasIosCaptions = DispatchedAction<Actions.SetHasIosCaptions, boolean>;
type ResetIntialPlayAction = DispatchedAction<Actions.ResetIntialPlay, boolean>;
type SetShowPlaceholderAction = DispatchedAction<Actions.SetShowPlaceholder, boolean>;
type SetPlayerTypeAndStateUpdateAction = DispatchedAction<Actions.SetPlayerTypeAndStateUpdate, { playerType: VideoPlayerType, showPlaceholder: boolean, isLive: boolean, secondaryUrl?: string, isTestBroadcast: boolean }>;
type SetIdleAction = DispatchedAction<Actions.SetIdle, boolean>;
type SetAutoplayMutedAction = DispatchedAction<Actions.SetAutoplayMuted, boolean>;
type UnmuteFromAutoplayAction = DispatchedAction<Actions.UnmuteFromAutoplay, undefined>;
type SetStreamStartTimeAction = DispatchedAction<Actions.SetStreamStartTime, number | undefined>;
type SetManualPipAction = DispatchedAction<Actions.SetManualPip, boolean>;
type SetOverlayOpenAction = DispatchedAction<Actions.SetOverlayOpen, boolean>;
type SetModeratorViewAction = DispatchedAction<Actions.SetModeratorView, boolean>;
type SetFiresideSettings = DispatchedAction<Actions.SetFiresideSettings, FiresideSessionSettings | undefined>;
type ForceRefresh = DispatchedAction<Actions.ForceRefresh, void>;

export type VideoPlayerAction =
	PlayAction |
	PauseAction |
	SeekToAction |
	SeekCompleteAction |
	SetVolumeAction |
	EndedAction |
	SetMutedAction |
	LoadCaptionsAction |
	EnableCaptionsAction |
	DisableCaptionsAction |
	LoadTranscriptAction |
	EnableTranscriptAction |
	DisableTranscriptAction |
	LoadQualitiesAction |
	SetQualityAction |
	SetActiveQualityAction |
	SetActiveQualityByIndexAction |
	SetPlayerTypeAction |
	SetPlaybackUrlAction |
	SetSecondaryUrlAction |
	SetIsLiveAction |
	SetBufferingAction |
	SetPlayingAction |
	SetDurationInSecondsAction |
	// SetProgressActions |
	SetPipAction |
	SetUseNativePipAction |
	SetSessionAction |
	SetIsMobileAction |
	SetChatIsOverlaidAction |
	SetChatClosedAction |
	SetReactionsEnabledAction |
	SetReactionsAction |
	SetVideoAction |
	SetSelectedCaptionsAction |
	// UpdateCuesAction |
	// ReplaceCuesAction |
	// AddCueAction |
	// RemoveCueAction |
	LoadPlaybackRates |
	SetPlaybackRate |
	CancelPipAction |
	SetHasRecordedCuesAction |
	SetHasLiveCuesAction |
	ResetIntialPlayAction |
	SetShowPlaceholderAction |
	SetPlayerTypeAndStateUpdateAction |
	SetIdleAction |
	SetAutoplayMutedAction |
	UnmuteFromAutoplayAction |
	SetStreamStartTimeAction |
	SetManualPipAction |
	SetHasIosCaptions |
	SetOverlayOpenAction |
	SetModeratorViewAction |
	SetFiresideSettings |
	ForceRefresh;

export type VideoPlayerReducerType = {
	activeQuality: QualityLevel;
	autoplayMuted: boolean;
	buffering: boolean;
	cancelPip: boolean;
	canDisplayCaptionsOption: boolean;
	canRaiseHand: boolean;
	captions: TextTrack[];
	captionsPreference: string;
	chatClosed: boolean;
	chatIsOverlaid: boolean;
	cues: string[];
	currentPlayedPercentage: number;
	currentSecond: number;
	durationInSeconds: number;
	ended: boolean;
	event?: BrandliveEvent;
	handRaised: boolean;
	hasCues: boolean;
	hasIosCaptions: boolean;
	hasLiveCues: boolean;
	hasRecordedCues: boolean;
	idle: boolean;
	initialPlay: boolean;
	isEditor: boolean;
	isIphone: boolean;
	isLive: boolean;
	isMobile: boolean;
	loadedPercentage: number;
	loadedSeconds: number;
	manualPip: boolean;
	moderatorView: boolean;
	muted: boolean;
	overlayOpen: boolean;
	paused: boolean;
	pip: boolean;
	playbackRate: number;
	playbackRates: number[];
	playbackUrl: string | undefined;
	playerType: VideoPlayerType;
	playing: boolean;
	qualities: QualityLevel[];
	quality: QualityLevel;
	reactions: ReactionConfig[];
	reactionsEnabled: boolean;
	secondaryUrl: string | undefined;
	seekComplete: boolean;
	seekTo: number;
	selectedCaptions: TextTrack | undefined;
	session?: Session;
	showCaptions: boolean;
	showPlaceholder: boolean;
	showTranscript: boolean;
	streamStartTime: number | undefined;
	transcript: unknown;
	transcripts: unknown[];
	useNativePip: boolean;
	video?: CreateVideo;
	videoMetadataExpanded: boolean;
	volume: number;
	firesideSettings?: FiresideSessionSettings;
	isTestBroadcast: boolean;
	refreshCount: number;
}

export const videoPlayerInitialState: VideoPlayerReducerType = {
	activeQuality: DefaultQuality,
	autoplayMuted: false,
	buffering: false,
	cancelPip: false,
	canDisplayCaptionsOption: false,
	canRaiseHand: false,
	captions: [],
	captionsPreference: '',
	chatClosed: false,
	chatIsOverlaid: false,
	cues: [],
	currentPlayedPercentage: 0,
	currentSecond: 0,
	durationInSeconds: 1,
	ended: false,
	handRaised: false,
	hasCues: false,
	hasIosCaptions: false,
	hasLiveCues: false,
	hasRecordedCues: false,
	idle: false,
	initialPlay: false,
	isEditor: false,
	isIphone: false,
	isLive: false,
	isMobile: false,
	loadedPercentage: 0,
	loadedSeconds: 0,
	manualPip: false,
	moderatorView: false,
	muted: false,
	overlayOpen: false,
	paused: false,
	pip: false,
	playbackRate: 1,
	playbackRates: [],
	playbackUrl: undefined,
	playerType: VideoPlayerType.none,
	playing: false,
	qualities: [DefaultQuality],
	quality: DefaultQuality,
	reactions: [],
	reactionsEnabled: false,
	secondaryUrl: undefined,
	seekComplete: false,
	seekTo: 0,
	selectedCaptions: undefined,
	showCaptions: false,
	showPlaceholder: true,
	showTranscript: false,
	streamStartTime: undefined,
	transcript: undefined,
	transcripts: [],
	useNativePip: false,
	video: undefined,
	videoMetadataExpanded: false,
	volume: 1,
	firesideSettings: undefined,
	isTestBroadcast: false,
	refreshCount: 0
};

export const videoPlayerReducer = (state: VideoPlayerReducerType, action: VideoPlayerAction): VideoPlayerReducerType => {
	switch (action.type) {
		case Actions.SetStreamStartTime: {
			return { ...state, streamStartTime: action.payload };
		}
		case Actions.Play: {
			return { ...state, paused: false, ended: false, idle: false };
		}
		case Actions.Pause: {
			return { ...state, paused: true };
		}
		case Actions.SeekTo: {
			return { ...state, seekTo: action.payload, seekComplete: false };
		}
		case Actions.SeekComplete: {
			return { ...state, seekComplete: action.payload };
		}
		case Actions.SetVolume: {
			return {
				...state,
				volume: action.payload,
				muted: action.payload <= 0.1
			};
		}
		case Actions.Ended: {
			return { ...state, playing: false, paused: true, ended: true };
		}
		case Actions.SetMuted: {
			let volume = state.volume;

			// user has muted by setting volume level to 0
			if (!action.payload && volume < 0.1) {
				volume = 1;
			}

			return { ...state, muted: action.payload, volume };
		}
		case Actions.LoadCaptions: {
			const captions = action.payload;

			if (state.captionsPreference) {
				const track = action.payload.find(track => track.language === state.captionsPreference);

				if (track) {
					return { ...state, captions: action.payload, selectedCaptions: track, hasRecordedCues: true };
				}
			}

			return { ...state, captions: captions, hasRecordedCues: captions.length > 0 };
		}
		case Actions.SetSelectedCaptions: {
			localStorage.setItem('cc', action.payload ? 'true' : 'false');

			if (!state.hasIosCaptions) {
				localStorage.setItem('ccp', action.payload?.language ?? '');
			} else {
				localStorage.setItem('ccp', 'ios');
			}

			return {
				...state,
				selectedCaptions: action.payload,
				showCaptions: !!action.payload,
				captionsPreference: action.payload?.language ?? state.hasIosCaptions ? 'ios' : '',
				cues: []
			};
		}
		case Actions.EnableCaptions: {
			localStorage.setItem('cc', 'true');
			let selectedCaptions = state.selectedCaptions;
			if (state.hasIosCaptions) {
				selectedCaptions = state.captions[0];
			}
			return { ...state, showCaptions: true, cues: [], selectedCaptions };
		}
		case Actions.DisableCaptions: {
			localStorage.setItem('cc', 'false');
			return { ...state, showCaptions: false, cues: [] };
		}
		case Actions.SetHasIosCaptions: {
			return { ...state, hasIosCaptions: action.payload };
		}
		case Actions.LoadTranscript: {
			return { ...state, transcript: action.payload };
		}
		case Actions.EnableTranscript: {
			return { ...state, showTranscript: true };
		}
		case Actions.DisableTranscript: {
			return { ...state, showTranscript: false };
		}
		case Actions.LoadQualities: {
			return { ...state, qualities: [DefaultQuality, ...action.payload] };
		}
		case Actions.SetQuality: {
			// this sets the user's requested quality
			return { ...state, quality: action.payload };
		}
		case Actions.SetActiveQuality: {
			// this sets the active quality, which is the quality that the player is currently playing in response to the user's setting
			return { ...state, activeQuality: action.payload };
		}
		case Actions.SetActiveQualityByIndex: {
			// HLS.js emits an event when the quality is updated only by passing the index of the quality
			const newQuality = state.qualities.find(quality => quality.index === action.payload) ?? DefaultQuality;
			return { ...state, activeQuality: newQuality };
		}
		case Actions.SetPlayerType: {
			return { ...state, playerType: action.payload };
		}
		case Actions.SetPlaybackUrl: {
			return { ...state, playbackUrl: action.payload };
		}
		case Actions.SetSecondaryUrl: {
			return { ...state, secondaryUrl: action.payload };
		}
		case Actions.SetIsLive: {
			return { ...state, isLive: action.payload };
		}
		case Actions.SetBuffering: {
			return { ...state, buffering: action.payload };
		}
		case Actions.SetPlaying: {
			return { ...state, playing: action.payload, initialPlay: true };
		}
		case Actions.SetDurationInSeconds: {
			return { ...state, durationInSeconds: action.payload };
		}
		// case Actions.SetProgress: {
		// 	const {
		// 		loadedSeconds,
		// 		loaded,
		// 		playedSeconds,
		// 		played
		// 	} = action.payload;
		// 	return {
		// 		...state,
		// 		currentSecond: playedSeconds,
		// 		currentPlayedPercentage: played,
		// 		loadedPercentage: loaded,
		// 		loadedSeconds
		// 	};
		// }
		case Actions.SetPip: {
			return { ...state, pip: action.payload };
		}
		case Actions.SetSession: {
			return {
				...state,
				session: action.payload
			};
		}
		case Actions.SetIsMobile: {
			// has transitioned from mobile while in native pip
			// disable native pip
			if (state.pip && !action.payload) {
				return {
					...state,
					isMobile: action.payload,
					pip: false
				};
			} else {
				return { ...state, isMobile: action.payload };
			}
		}
		case Actions.SetChatIsOverlaid: {
			return { ...state, chatIsOverlaid: action.payload };
		}
		case Actions.SetChatClosed: {
			return { ...state, chatClosed: action.payload };
		}
		case Actions.SetReactionsEnabled: {
			return { ...state, reactionsEnabled: action.payload };
		}
		case Actions.SetReactions: {
			return { ...state, reactions: action.payload };
		}
		case Actions.SetVideo: {
			return { ...state, video: action.payload };
		}
		// case Actions.AddCue: {
		// 	return { ...state, cues: [...state.cues, action.payload] };
		// }
		// case Actions.UpdateCues: {
		// 	const cues = state.cues.filter(cue => !action.payload.removeCues.includes(cue));
		// 	return { ...state, cues: [...cues, ...action.payload.addCues] };
		// }
		// case Actions.ReplaceCues: {
		// 	return { ...state, cues: action.payload }
		// }
		// case Actions.RemoveCue: {
		// 	return { ...state, cues: state.cues.filter(cue => cue !== action.payload) };
		// }
		case Actions.LoadPlaybackRates: {
			return { ...state, playbackRates: action.payload };
		}
		case Actions.SetPlaybackRate: {
			return { ...state, playbackRate: action.payload };
		}
		case Actions.CancelPip: {
			return { ...state, cancelPip: action.payload };
		}
		case Actions.SetHasRecordedCues: {
			return { ...state, hasRecordedCues: action.payload };
		}
		case Actions.SetHasLiveCues: {

			return { ...state, hasLiveCues: action.payload };
		}
		case Actions.ResetIntialPlay: {
			return { ...state, initialPlay: action.payload };
		}
		case Actions.SetShowPlaceholder: {
			return { ...state, showPlaceholder: action.payload };
		}
		case Actions.SetPlayerTypeAndStateUpdate: {
			// this has a tendency to over-fire because it's based on prop changes
			// we're going to do a by-value check to see if we want to push an update
			const playerTypeChange = action.payload.playerType !== state.playerType;

			const dispatchUpdate =
				playerTypeChange ||
				action.payload.isLive !== state.isLive ||
				action.payload.showPlaceholder !== state.showPlaceholder ||
				action.payload.secondaryUrl !== state.secondaryUrl ||
				action.payload.isTestBroadcast !== state.isTestBroadcast;

			// this can get called a lot - we don't want to constantly return new state objects if no real change has happened
			if (dispatchUpdate) {
				const newState = { ...state };

				// reset the captions and options state if the player state has changed
				if (playerTypeChange) {
					newState.captions = [];
					newState.hasIosCaptions = false;
					newState.hasLiveCues = false;
					newState.hasRecordedCues = false;
					newState.cues = [];
					newState.hasCues = false;
					newState.playbackRates = [];
					newState.qualities = [];
					newState.ended = false;
					newState.initialPlay = false;
					newState.loadedSeconds = 0;
					newState.loadedPercentage = 0;
					newState.currentPlayedPercentage = 0;
					newState.currentSecond = 0;
				}

				return {
					...newState,
					playerType: action.payload.playerType,
					showPlaceholder: action.payload.showPlaceholder,
					isLive: action.payload.isLive,
					secondaryUrl: action.payload.secondaryUrl,
					isTestBroadcast: action.payload.isTestBroadcast
				};
			} else {
				// return same state object to prevent pointless re-renders down line
				return state;
			}
		}
		case Actions.SetIdle: {
			// cannot be idle and playing at the same time, so skip idle if 
			// the video is actually playing
			if (!state.paused && action.payload) {
				return state;
			}

			return { ...state, idle: action.payload };
		}
		case Actions.SetUseNativePip: {
			return { ...state, useNativePip: action.payload };
		}
		case Actions.SetAutoplayMuted: {
			return { ...state, autoplayMuted: action.payload };
		}
		case Actions.UnmuteFromAutoplay: {
			return { ...state, muted: false, autoplayMuted: false, volume: 1 };
		}
		case Actions.SetManualPip: {
			return { ...state, manualPip: action.payload };
		}
		case Actions.SetOverlayOpen: {
			// this is dispatched way too much and causing huge numbers of re-renders
			// so we are going to first check whether the state has actually changed, 
			// otherwise return the same exact state object (no destructuring)
			if (state.overlayOpen !== action.payload) {
				return { ...state, overlayOpen: action.payload };
			}

			return state;
		}

		case Actions.SetModeratorView: {
			return {
				...state,
				moderatorView: action.payload
			};
		}

		case Actions.SetFiresideSettings: {
			return {
				...state,
				firesideSettings: action.payload
			};
		}

		case Actions.ForceRefresh: {
			return { ...state, refreshCount: state.refreshCount + 1 };
		}

		default: {
			return state;
		}
	}
};

// used to emit events to components outside this context - ie: going full screen, raising hand, toggling chat, etc
export const VideoControlsEvents = new EventTarget();

type VideoStateContextType = {
	state: VideoPlayerReducerType,
	dispatch: React.Dispatch<VideoPlayerAction>,
	eventTarget: EventTarget
};

export const VideoStateContext = React.createContext({
	state: videoPlayerInitialState,
	dispatch: () => null,
	eventTarget: VideoControlsEvents
} as VideoStateContextType);

export const getVideoPlayerInitialState = (props: SessionStreamStateProps, isAdmin: boolean): VideoPlayerReducerType => {
	const secondaryUrl = getSecondaryVideo(props);

	return {
		...videoPlayerInitialState,
		captionsPreference: localStorage.getItem('ccp') ?? '',
		chatClosed: props.chatClosed,
		chatIsOverlaid: props.chatIsOverlaid,
		event: props.event,
		isEditor: !!props.isEditor,
		isIphone: props.isIphone,
		isLive: props.isLive,
		isMobile: props.isMobile,
		muted: isAdmin ? true : localStorage.getItem('muted') === 'true',
		playbackUrl: props.playbackUrl,
		playerType: getVideoPlayerType(props),
		reactions: getEnabledReactions(props.session.reaction_settings),
		reactionsEnabled: getReactionsEnabled(props.session.reaction_settings),
		secondaryUrl,
		session: props.session,
		showCaptions: localStorage.getItem('cc') === 'true',
		showPlaceholder: getShouldShowPlaceholder(props),
		streamStartTime: props.streamStartTime,
		video: props.video,
		volume: getLastVolume(),
		moderatorView: !!props.moderatorView,
		firesideSettings: props.session.fireside_session_settings,
		isTestBroadcast: (!props.isEditor && props.session.streaming_options?.test_stream) ?? false
	};
};

type SessionStreamStateProps = {
	chatClosed: boolean;
	chatIsOverlaid: boolean;
	event: BrandliveEvent;
	hasScrolledPast: boolean;
	isEditor?: boolean;
	isIphone: boolean;
	isLive: boolean;
	isMobile: boolean;
	language: LanguagesAbbr;
	manualPip: boolean;
	overlayOpen?: boolean;
	playbackUrl?: string;
	secondaryUrl: string | undefined;
	secondaryVideos?: Record<string, string>;
	session: Session;
	streamStartTime: number | undefined;
	video?: CreateVideo;
	moderatorView?: boolean;
	fsMeetData?: UseFiresideMeetData;
}

export type SessionStreamProviderProps = {
	children: JSX.Element | JSX.Element[];
} & SessionStreamStateProps;

export const SessionStreamProvider: React.FC<SessionStreamProviderProps> = ({
	children,
	...props
}) => {
	const isAdmin = useIsAdmin();
	const [state, dispatch] = useReducer(videoPlayerReducer, getVideoPlayerInitialState(props, isAdmin));
	const reduxDispatch = useDispatch();
	const {
		isLive,
		event,
		session,
		language,
		isEditor,
		secondaryVideos,
		playbackUrl,
		video,
		streamStartTime,
		manualPip,
		secondaryUrl,
		overlayOpen,
		moderatorView,
		fsMeetData
	} = props;

	useEffect(() => {
		dispatch({ type: Actions.SetStreamStartTime, payload: streamStartTime });
	}, [streamStartTime]);

	useEffect(() => {
		if (!isLive) {
			dispatch({ type: Actions.SetStreamStartTime, payload: undefined });
		}
	}, [isLive]);

	useEffect(() => {
		dispatch({ type: Actions.SetModeratorView, payload: !!moderatorView });
	}, [moderatorView]);

	useEffect(() => {
		dispatch({
			type: Actions.SetPlayerTypeAndStateUpdate,
			payload: {
				playerType: getVideoPlayerType({
					isLive,
					event,
					session,
					language,
					isEditor,
					secondaryVideos,
					playbackUrl,
					secondaryUrl,
					fsMeetData,
				}),
				showPlaceholder: getShouldShowPlaceholder({
					isLive,
					event,
					session,
					language,
					isEditor,
					secondaryVideos,
					secondaryUrl,
					fsMeetData
				}),
				isLive,
				secondaryUrl: getSecondaryVideo({
					secondaryVideos,
					session,
					language,
					isEditor,
					secondaryUrl
				}),
				isTestBroadcast: (!isEditor && session.streaming_options?.test_stream) ?? false
			}
		});
	}, [
		isLive,
		event,
		session,
		language,
		isEditor,
		secondaryVideos,
		playbackUrl,
		video,
		secondaryUrl,
		fsMeetData
	]);

	useEffect(() => {
		reduxDispatch(setLiveEventSessionPlayerType(state.playerType));
	}, [reduxDispatch, state.playerType]);

	useEffect(() => {
		dispatch({ type: Actions.SetPlaybackUrl, payload: playbackUrl });
	}, [playbackUrl]);

	useEffect(() => {
		dispatch({ type: Actions.SetSession, payload: session });
	}, [session]);

	// useEffect(() => {
	// 	dispatch({ type: Actions.SetEvent, payload: event });
	// }, [event]);

	useEffect(() => {
		dispatch({ type: Actions.SetVideo, payload: props.video });
	}, [props.video]);

	// player type has changed, likely vid has gone live or replay began
	useEffect(() => {
		dispatch({ type: Actions.ResetIntialPlay, payload: false });
	}, [state.playerType]);

	useEffect(() => {
		dispatch({ type: Actions.SetIsMobile, payload: props.isMobile });
	}, [props.isMobile]);

	useEffect(() => {
		dispatch({ type: Actions.SetChatIsOverlaid, payload: props.chatIsOverlaid });
	}, [props.chatIsOverlaid]);

	useEffect(() => {
		dispatch({ type: Actions.SetChatClosed, payload: props.chatClosed });
	}, [props.chatClosed]);

	useEffect(() => {
		dispatch({ type: Actions.SetReactionsEnabled, payload: getReactionsEnabled(props.session.reaction_settings) });
		dispatch({ type: Actions.SetReactions, payload: getEnabledReactions(props.session.reaction_settings) });
	}, [props.session.reaction_settings]);

	useEffect(() => {
		dispatch({ type: Actions.SetVideo, payload: props.video });
	}, [props.video]);

	useEffect(() => {
		if (props.hasScrolledPast) {
			dispatch({ type: Actions.CancelPip, payload: false });
		}
	}, [props.hasScrolledPast]);

	useEffect(() => {
		dispatch({ type: Actions.SetManualPip, payload: manualPip });
	}, [manualPip]);

	useEffect(() => {
		if (overlayOpen) {
			dispatch({ type: Actions.SetOverlayOpen, payload: overlayOpen });
		}
	}, [overlayOpen]);

	useEffect(() => {
		localStorage.setItem('volume', state.volume.toString());
	}, [state.volume]);

	useEffect(() => {
		localStorage.setItem('muted', state.muted.toString());
	}, [state.muted]);

	const store = useMemo(() => {
		return { state, dispatch, eventTarget: VideoControlsEvents };
	}, [state]);
	// debugger;
	return (
		<VideoStateContext.Provider value={store}>
			{children}
		</ VideoStateContext.Provider>
	);
};

// SessionStreamProvider.whyDidYouRender = true;