import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import type { Quality } from 'amazon-ivs-player';
import { isObject } from "underscore";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { Level } from 'hls.js';

import { BlProfile, BlProfileValues, BrandliveEvent, ChannelFeaturesEnum, CreateVideo, DeprecatedOnDemandSession, EBroadcastTypes, LanguagesAbbr, MiscellaneousActionData, PageModuleType, ReactionConfig, Session, SessionTypesEnum, VideoPlayerControlsSettings } from "../../../../../types/working-model";
import { QualityLevel, VideoPlayerType } from "./types";
import store from '../../../../../store/main';
import { ColorThemeProperties } from "../../../modules/video/video";
import { COLORS } from "../../../../general-ui/icon";
import { broadcastSignal } from "../../../../../utils/event-emitter";
import { TranslateFunction } from "../../../../../i18n/useTranslationModules";
import { useTypedSelector } from "../../../../../store/reducers/use-typed-selector";
import { updateMyListSessions } from "../../../../../store/actions/event/event-actions";
import * as Signals from '../../../../../utils/event-emitter';
import { Actions, VideoPlayerReducerType, VideoStateContext } from "../session-stream-provider";
import { CuesActions } from "./components/captions";
import { UseFiresideMeetData } from "../../hooks/fireside-meet";
import { EPaletteModes } from "types/theme-packs";

declare global {
	interface Document {
		mozCancelFullScreen?: () => Promise<void>;
		msExitFullscreen?: () => Promise<void>;
		webkitExitFullscreen?: () => Promise<void>;
		mozFullScreenElement?: Element;
		msFullscreenElement?: Element;
		webkitFullscreenElement?: Element;
	}

	interface HTMLElement {
		msRequestFullscreen?: () => Promise<void>;
		mozRequestFullscreen?: () => Promise<void>;
		webkitRequestFullscreen?: () => Promise<void>;
	}
}

export const requestFullscreen = (element: HTMLElement): Promise<void> => {
	if (element.requestFullscreen) {
		return element.requestFullscreen();
	} else if (element.webkitRequestFullscreen) {
		return new Promise((resolve, reject) => {
			try {
				(element.webkitRequestFullscreen as () => Promise<void>)();
				resolve();
			} catch (e) {
				reject(e);
			}
		});
	} else if (element.mozRequestFullscreen) {
		return element.mozRequestFullscreen();
	} else if (element.msRequestFullscreen) {
		return element.msRequestFullscreen();
	}

	return Promise.reject('Fullscreen API is not supported');
};

/*
Utilize the document picture-in-picture API to retain our own controls. This API doesn't seem finished and has no cross-browser support.
Leaving in until some time in the future when this matures. 

export const canSupportPictureInPicture = () => ('documentPictureInPicture' in window);
export const engagePictureInPicture = (session?: Session) => async (): Promise<boolean> => {
	if (session && window.documentPictureInPicture) {
		try {
			const player = document.getElementById(`video-player-v2-${session.uuid}`)?.getElementsByTagName('video')?.[0];
			const container = document.getElementById('player-container');

			if (player && container) {
				const pipWindow = await window.documentPictureInPicture.requestWindow();
				pipWindow.document.body.append(player);

				player.controls = true;

				player.addEventListener('playing', () => {
					console.log('video is playing');
				});

				player.addEventListener('progress', (e) => {
					console.log('video is progressing', e);
				});

				pipWindow.addEventListener("pagehide", (e: any) => {
					const pipPlayer = e.target.getElementById(`video-player-v2-${session.uuid}`);
					container.append(pipPlayer);
				});

				return true;
			}
		} catch (e) {
			console.error(e);
			return false;
		}
	}

	return false;
};
*/

export const exitFullscreen = (): Promise<void> => {
	if (document.exitFullscreen) {
		return document.exitFullscreen();
	} else if (document.webkitExitFullscreen) {
		return new Promise((resolve, reject) => {
			try {
				(document.webkitExitFullscreen as () => Promise<void>)();
				resolve();
			} catch (e) {
				reject(e);
			}
		});
	} else if (document.mozCancelFullScreen) {
		return document.mozCancelFullScreen();
	} else if (document.msExitFullscreen) {
		return document.msExitFullscreen();
	}

	return Promise.reject('Fullscreen API is not supported');
};

export const getOnDemandVideo = (session: Session, language: LanguagesAbbr): [string | null | undefined, CreateVideo | undefined] => {
	let source;
	let homepageVideo;

	if (session.streaming_options?.single_stream) {
		if (Array.isArray(session.on_demand_video)) {
			source = session.on_demand_video[0]?.source;
			homepageVideo = session.on_demand_video[0];
		} else {
			const backwardsCompatibileSession = session as unknown as DeprecatedOnDemandSession;
			source = backwardsCompatibileSession.on_demand_video?.source;
			homepageVideo = backwardsCompatibileSession.on_demand_video;
		}
	} else {
		if (session.on_demand_video !== null && typeof session.on_demand_video === 'object' && !Array.isArray(session.on_demand_video)) {
			const backwardsCompatibileSession = session as unknown as DeprecatedOnDemandSession;
			source = backwardsCompatibileSession.on_demand_video?.source;
			homepageVideo = backwardsCompatibileSession.on_demand_video;
		} else {
			source = session.on_demand_video?.find(video => video?.session_language === language)?.source ?? session.on_demand_video?.[0]?.source;
			homepageVideo = session.on_demand_video?.find(video => video?.session_language === language) as CreateVideo ??
				session.on_demand_video?.[0] as CreateVideo;
		}
	}

	return [source, homepageVideo];
};

type GetSecondaryVideoProps = {
	session: Session;
	language: LanguagesAbbr;
	isEditor?: boolean;
	secondaryVideos?: Record<string, string>;
	secondaryUrl: string | undefined;
}
export const getSecondaryVideo = ({
	// these were used previously to select something different in the editor
	// but we should have the editor match the live event unless test broadcast is on
	// session,
	// language,
	// isEditor,
	// secondaryVideos,
	secondaryUrl
}: GetSecondaryVideoProps): string | undefined => {

	// if not editor, use the secondary video from the session
	return secondaryUrl;
};

const isInPersonMode = (event: BrandliveEvent): boolean => {
	return !!event.settings.attendeeInPersonEnabled;
};

const isOnDemandAndHasVideo = (session: Session, language: LanguagesAbbr): boolean => {
	const [source] = getOnDemandVideo(session, language);
	return session.session_type === SessionTypesEnum.onDemand && !!source;
};

export const isTestBroadcast = (session: Session, isEditor: boolean): boolean => {
	return !isEditor && !!session.streaming_options?.test_stream && session.session_type === SessionTypesEnum.broadcast;
};

const isFiresideBroadcast = (session: Session) => {
	return session.session_type === SessionTypesEnum.fireside && !session.fireside_session_settings?.settings?.use_google_meet;
};

const isFiresideMeet = (eventBundle: BrandliveEvent, session: Session) => {
	const canUseMeetFiresides = eventBundle?.channel_features?.includes(ChannelFeaturesEnum.google_meet_firesides);
	return canUseMeetFiresides && session.session_type === SessionTypesEnum.fireside && session.fireside_session_settings?.settings?.use_google_meet;
};

const isBroadcast = (session: Session) => {
	return session.session_type === SessionTypesEnum.broadcast;
};

const isBreakout = (session: Session) => {
	return session.session_type === SessionTypesEnum.breakoutRooms;
};

const isEmbedAndHasVideo = (session: Session, language: LanguagesAbbr): boolean => {
	const [source] = getOnDemandVideo(session, language);
	if (session.session_type === SessionTypesEnum.broadcast) {
		return session.broadcast_type === EBroadcastTypes.embed && !!source?.includes('<iframe');
	} else if (session.session_type === SessionTypesEnum.onDemand) {
		return !!source?.includes('<iframe');
	} else {
		return false;
	}
};

const isECDNStream = (event: BrandliveEvent, session: Session): boolean => {
	if (Array.isArray(event.ecdn_data)) {
		return !!event.ecdn_data.find(data => data.session_uuid === session.uuid);
	}

	return false;
};

const canShowMeetPlayer = (fsMeetData?: UseFiresideMeetData): boolean => {
	if (fsMeetData?.firesideMeetJoinable) {
		if (fsMeetData?.userLeft) {
			return false;
		}

		if (fsMeetData?.hostToken) {
			return fsMeetData?.fsJoined;
		} else {
			return fsMeetData?.isFiresideLive;
		}
	}

	return false;
};

type GetVideoPlayerTypeProps = {
	session: Session;
	event: BrandliveEvent;
	language: LanguagesAbbr;
	isLive: boolean;
	secondaryVideos?: Record<string, string>;
	playbackUrl?: string;
	isEditor?: boolean;
	secondaryUrl: string | undefined;
	fsMeetData?: UseFiresideMeetData;
}

export const getVideoPlayerType = ({
	event,
	session,
	isLive,
	language,
	playbackUrl,
	isEditor,
	secondaryVideos,
	secondaryUrl,
	fsMeetData
}: GetVideoPlayerTypeProps): VideoPlayerType => {

	// breakout sessions never show video player
	if (isBreakout(session)) {
		return VideoPlayerType.none;
	}

	if (isInPersonMode(event)) {
		return VideoPlayerType.placeholder;
	}

	// embeded iframes should display if the session is of any type
	if (isEmbedAndHasVideo(session, language)) {
		return VideoPlayerType.embed;
	}

	// if the session is on-demand AND has a video, display the video player
	if (isOnDemandAndHasVideo(session, language)) {
		const [source, video] = getOnDemandVideo(session, language);

		if (source && video) {
			// video is a link - ie a YT or Vimeo link
			if (video.type === 'url') {
				return VideoPlayerType.link;
			}

			if (video.hls_link?.endsWith('.m3u8')) {
				// otherwise, it should use the hls player
				return VideoPlayerType.hls;
			} else {
				return VideoPlayerType.mp4;
			}
		}
	}

	// broadcast and firesides sessions both use IVS
	if (isBroadcast(session) || isFiresideBroadcast(session)) {

		// only display the player if the video is actually live
		// AND if this is not a test broadcast 
		if (!isTestBroadcast(session, !!isEditor) && playbackUrl && isLive) {

			// if is live but is using ECDN, use hls player
			if (isECDNStream(event, session)) {
				return VideoPlayerType.hls;
			}

			return VideoPlayerType.ivs;
		}

		// secondary videos always show via the HLS player
		if (getSecondaryVideo({ session, language, isEditor, secondaryVideos, secondaryUrl })) {
			return VideoPlayerType.hls;
		}
	}

	if (isFiresideMeet(event, session)) {
		if (canShowMeetPlayer(fsMeetData)) {
			return VideoPlayerType.meet;
		} else if (secondaryUrl) {
			if (secondaryUrl.endsWith('.m3u8')) {
				return VideoPlayerType.hls;
			} else {
				return VideoPlayerType.mp4;
			}
		} else {
			return VideoPlayerType.placeholder;
		}
	}

	// no conditions exist to display a video player
	return VideoPlayerType.none;
};

export const usePlayerType = (props: GetVideoPlayerTypeProps): VideoPlayerType => {
	const {
		session,
		event,
		language,
		isLive,
		secondaryVideos,
		playbackUrl,
		isEditor,
		secondaryUrl
	} = props;

	return useMemo(() => {
		return getVideoPlayerType({
			session,
			event,
			language,
			isLive,
			secondaryVideos,
			playbackUrl,
			isEditor,
			secondaryUrl
		});
	}, [session, event, language, isLive, secondaryVideos, playbackUrl, isEditor, secondaryUrl]);
};

export const hlsLevelToQualityLevel = (level: Level, index: number): QualityLevel => {
	return {
		index,
		label: `${level.height}p`,
		internal: level
	};
};

export const ivsQualityToQualityLevel = (level: Quality, index: number): QualityLevel => {
	return {
		index,
		label: `${level.height}p`,
		internal: level
	};
};

export const isQuality = (quality: QualityLevel['internal']): quality is Quality => {
	if (isObject(quality) && 'codecs' in quality) {
		return true;
	} else {
		return false;
	}
};

export const getColorThemeProperties = (theme: VideoPlayerControlsSettings['color_theme'] | undefined): ColorThemeProperties => {
	if (theme === EPaletteModes.Light) {
		return {
			className: `control-theme-${theme}`,
			primaryColor: COLORS.BLACK
		};
	}

	// Default theme
	return {
		className: '',
		primaryColor: COLORS.WHITE
	};
};

export const getReactionsEnabled = (settings: Session['reaction_settings']) =>
	settings?.enabled ?? false;

export const getEnabledReactions = (settings: Session['reaction_settings']) =>
	settings?.reactions?.filter(reaction => reaction.enabled) ?? [];

export const getVideoTimestamp = (playedSeconds: number, session: Session) => {
	// if broadcast session, pull relative timestamp from the session start time
	// else pull from played seconds on video player
	if (session.session_type === SessionTypesEnum.broadcast) {
		if (session?.timestamp && session?.timestamp < Date.now()) {
			return (Date.now() - session?.timestamp) / 1000;
		}
		return null;
	}
	return playedSeconds;
};

export const getUserData = () => {
	const {
		blProfileUser,
		registrationId,
		validPasscodeLists
	} = store.getState().LiveEventReducer;
	return { blProfileUser, registrationId, validPasscodeLists };
};

type GetReactionPayloadProps = {
	reaction: ReactionConfig;
	blProfileUser: BlProfile;
	validPasscodeLists: number[];
	session: Session;
	video?: CreateVideo;
	currentSecond?: number;
}
export const getReactionPayload = ({
	reaction,
	blProfileUser,
	validPasscodeLists,
	session,
	video,
	currentSecond
}: GetReactionPayloadProps) => {
	const miscPayload: Pick<MiscellaneousActionData, 'reaction' | 'valid_passcode_lists'> = {
		reaction: {
			emoji_url: reaction.icon ?? reaction.url ?? '',
			name: reaction.name,
			user_first_name: blProfileUser?.profile?.[session.channel ?? 0]?.[BlProfileValues.firstName] ?? null,
			user_last_name: blProfileUser?.profile?.[session.channel ?? 0]?.[BlProfileValues.lastName] ?? null,
			email: blProfileUser?.email ?? null,
			relative_timestamp: currentSecond ? getVideoTimestamp(currentSecond, session) : 0,
			video_url: video?.hls_link ?? video?.source ?? null,
			session_title: session?.title?.base ?? null
		},
		valid_passcode_lists: validPasscodeLists,
	};
	return miscPayload;
};


export const getSubtitleTracks = (track: TextTrack) => track.kind === 'captions';
export const getAnyTrackWithCues = (track: TextTrack) => !!track.cues;

export const disableAllTracks = (track: TextTrack) => {
	track.mode = 'hidden';
	track.mode = 'disabled';
};

// iOS does not follow the rules - has activeCues property to push updates containing an array of cue values
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleCueChange = (e: any) => {
	if (e.target && e.target !== null && e.target.activeCues) {
		const texts: string[] = [];
		for (const cue of e.target.activeCues) {
			texts.push(cue);
		}
		broadcastSignal(CuesActions.replaceCues, texts);
		broadcastSignal('captions-track-found', true);
	}
};

export const enableTrack = (language: string) => (track: TextTrack) => {
	if (track.language === language) {
		track.mode = 'showing';
		// Commenting out since it causes an issue with switchin from on demand to live
		// The captions stick around and don't update
		// Since we're using native captions for HLS, hopefully this wills still work
		// setTimeout(() => {
		// 	track.oncuechange = handleCueChange;
		// });
	} else {
		track.mode = 'hidden';
		track.mode = 'disabled';
		track.oncuechange = null;
		if (track.cues) {
			for (const cue of track.cues) {
				cue.onenter = null;
				cue.onexit = null;
			}
		}
	}
};

export const removeAllTrackListeners = (track: TextTrack) => {
	track.oncuechange = null;
	if (track.cues) {
		for (const cue of track.cues) {
			cue.onenter = null;
			cue.onexit = null;
		}
	}
};

export type GetShouldShowReplayProps = {
	isEditor?: boolean | undefined;
	session: Session;
	secondaryVideos?: Record<string, string>;
	language: LanguagesAbbr;
	secondaryUrl: string | undefined;
};
export const shouldShowReplay = ({ session, language, isEditor, secondaryVideos, secondaryUrl }: GetShouldShowReplayProps): boolean => {
	return !!getSecondaryVideo({ session, language, isEditor, secondaryVideos, secondaryUrl });
};

export type AnyVideoExistsProps = {
	isEditor?: boolean | undefined;
	event: BrandliveEvent;
	session: Session;
	secondaryVideos?: Record<string, string>;
	language: LanguagesAbbr;
	isLive: boolean;
	secondaryUrl: string | undefined;
	fsMeetData?: UseFiresideMeetData;
};
export const anyVideoExists = (props: AnyVideoExistsProps): boolean => {
	const {
		event,
		session,
		language,
		isLive,
		isEditor,
		fsMeetData
	} = props;

	const replayOnAndExists = shouldShowReplay(props);
	const isOnDemandAndVideoExists = isOnDemandAndHasVideo(session, language);
	const isEmbedTypeAndEmbedExists = isEmbedAndHasVideo(session, language);
	const isLiveAndNotTesting = isLiveAndNotTestStream(session, isLive, isEditor);
	const isFiresidesMeet = isFiresideMeet(event, session);
	return replayOnAndExists || isOnDemandAndVideoExists || isEmbedTypeAndEmbedExists || isLiveAndNotTesting || (!!isFiresidesMeet && canShowMeetPlayer(fsMeetData));
};

export const isLiveAndNotTestStream = (session: Session, isLive: boolean, isEditor = false): boolean => {
	const isTestStream = !isEditor && session.streaming_options.test_stream && session.session_type === SessionTypesEnum.broadcast;
	return isLive && !isTestStream;
};

export type GetShouldShowPlaceholderProps = {
	isEditor?: boolean | undefined;
	event: BrandliveEvent;
	session: Session;
	isLive: boolean;
	secondaryVideos?: Record<string, string>;
	language: LanguagesAbbr;
	secondaryUrl: string | undefined;
	fsMeetData?: UseFiresideMeetData;
};
export const getShouldShowPlaceholder = (props: GetShouldShowPlaceholderProps) => {
	const { event } = props;
	const shouldShowVideo = anyVideoExists(props);
	const attendeeInPersonModeEnabled = event.settings.attendeeInPersonEnabled;
	return !shouldShowVideo && !attendeeInPersonModeEnabled;
};

export const getSessionDescription = (session: Session, language: LanguagesAbbr, t: TranslateFunction, ns: string): string | undefined => {
	const descriptionModule = session.modules.find(m => m.type === PageModuleType.description);
	if (descriptionModule?.is_on) {
		return t(`${ns}:session.description`, descriptionModule.content.description?.[language] ?? descriptionModule.content.description?.base ?? '');
	}
};

export const useMyListActive = (session?: Session): [boolean, boolean, () => void] => {
	const dispatch = useDispatch();
	const liveEvent = useTypedSelector((state) => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector((state) => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector((state) => state.LiveEventReducer.blProfileUserToken);
	const userSavedSessions = useTypedSelector((state) => state.LiveEventReducer.userSavedSessions);
	const userSavedSessionsTemp = useTypedSelector((state) => state.LiveEventReducer.userSavedSessionsTemp);
	const event = (liveEvent ?? workingEvent) as BrandliveEvent;
	const active = event.settings.personalizedAttendeeList;
	const sessionUuid = session?.uuid;
	const eventUuid = event.uuid;

	return useMemo(() => {
		// handling a temp update to keep the UI snappy
		let inMyList = false;
		if (sessionUuid) {
			inMyList = userSavedSessionsTemp ?
				userSavedSessionsTemp.includes(sessionUuid) :
				userSavedSessions?.includes(sessionUuid) ?? false;
		}

		const addToMyList = () => {
			if (!token || !sessionUuid) return;
			dispatch(updateMyListSessions(token, eventUuid, [...(userSavedSessions ?? []), sessionUuid]));
		};

		const removeFromMyList = () => {
			if (!token || !sessionUuid) return;
			dispatch(updateMyListSessions(token, eventUuid, userSavedSessions?.filter(session => session !== sessionUuid) ?? []));
		};

		const toggleInMyList = () => {
			if (inMyList) removeFromMyList();
			else addToMyList();
		};

		return [
			active,
			!!inMyList,
			toggleInMyList
		];
	}, [active, token, userSavedSessions, userSavedSessionsTemp, sessionUuid, eventUuid, dispatch]);
};

export const getThumbnailUrlFromIvs = (url: string, index = 0): string => {
	return url.replace(/hls\/master.m3u8$/, `thumbnails/thumb${index}.jpg`);
};

export const getThumbnailUrlFromUpload = (url: string, index = 0): string => {
	return url.replace(/converted.m3u8$/, `thumbnails/thumb.${index.toString().padStart(7, '0')}.jpg`);
};

const disableHideControls = localStorage.getItem('DISABLE_HIDE_CONTROLS');

export const useControlsHoverEffect = (
	wrapperRef: React.RefObject<HTMLDivElement>,
	playing: boolean
) => {
	const {
		dispatch
	} = useContext(VideoStateContext);
	const hoverTimeout = useRef<NodeJS.Timeout | null>(null);
	const idleTimeout = useRef<NodeJS.Timeout | null>(null);
	const hoverDebounce = useRef<NodeJS.Timeout | null>(null);

	// called whenever the user interacts with the controls and sets a delay
	// to hide the controls then mark the player as idle
	const hoverOut = useCallback(() => {
		const wrapper = wrapperRef.current;
		if (hoverTimeout.current) {
			clearTimeout(hoverTimeout.current);
		}

		if (idleTimeout.current) {
			clearTimeout(idleTimeout.current);
		}

		const waitForIdle = () => {
			// displays the paused cover view if the player is not playing a video
			dispatch({ type: Actions.SetIdle, payload: true });
		};

		const waitForHoverPause = () => {
			if (disableHideControls) {
				return;
			}

			// displays the controls
			// not using react to prevent pointless re-render of the entire controls component
			// just to add/remove a class name. Testing shows this allows the visual change to 
			// occur so fast that there is no performance impact
			wrapper?.classList.remove('hover');
			Signals.broadcastEvent('controls-hidden', true);
			idleTimeout.current = setTimeout(waitForIdle, 400);
		};

		hoverTimeout.current = setTimeout(waitForHoverPause, 3000);
	}, [wrapperRef, dispatch]);

	const hoverIn = useCallback(() => {
		if (hoverDebounce.current) {
			clearTimeout(hoverDebounce.current);
		}

		hoverDebounce.current = setTimeout(() => {
			const wrapper = wrapperRef.current;
			Signals.broadcastEvent('controls-hidden', false);
			dispatch({ type: Actions.SetOverlayOpen, payload: false });
			wrapper?.classList.add('hover');
		}, 16 * 5); // only every 5th frame

	}, [dispatch, wrapperRef]);

	useEffect(() => {
		if (playing) {
			hoverOut();
		}
	}, [hoverOut, playing]);

	// intentionally not using stateful class name change on the wrapper
	// to prevent re-rendering of the entire component tree just to handle a hover effect
	// nothing reads this class name statefully
	useEffect(() => {
		const wrapper = wrapperRef.current;

		const handleHover = () => {
			hoverIn();
			hoverOut();
		};

		if (wrapper) {
			// user is interacting with the video due to any pointer action (mouse or touch)
			// with the video element at all. Using as little react state as possible because
			// these interactions can easily over-fire.
			wrapper.addEventListener('pointermove', handleHover);
			wrapper.addEventListener('pointerdown', hoverIn);
			wrapper.addEventListener('pointerup', hoverOut);
		}

		return () => {
			if (wrapper) {
				wrapper.removeEventListener('pointerdown', handleHover);
				wrapper.removeEventListener('pointermove', hoverIn);
				wrapper.removeEventListener('pointerup', hoverOut);
			}
		};
	}, [hoverTimeout, wrapperRef, hoverIn, hoverOut]);

	useEffect(() => {
		// cleanup all timers when component unmounts
		return () => {
			if (hoverTimeout.current) {
				clearTimeout(hoverTimeout.current);
			}

			if (idleTimeout.current) {
				clearTimeout(idleTimeout.current);
			}
		};
	}, []);
};

export const getLastVolume = () => {
	const last = localStorage.getItem('volume');
	if (last) {
		// check first to see if the volume exists, because Number(null) is 0
		return Number(last);
	} else {
		return 1;
	}
};


export const useIosCaptions = (videoEl: React.MutableRefObject<HTMLVideoElement | null> | React.RefObject<HTMLVideoElement | null>, logger?: (...args: unknown[]) => void) => {
	const { state: { playerType, video, secondaryUrl, showCaptions, isIphone, playing, hasIosCaptions }, dispatch } = useContext(VideoStateContext);
	const { language } = useParams<{ language: string }>();
	const [player, setPlayer] = useState(videoEl.current);
	const hasIosCaptionsRef = useRef(hasIosCaptions);

	useEffect(() => {
		hasIosCaptionsRef.current = hasIosCaptions;
	}, [hasIosCaptions]);

	// one of these must exist otherwise we would not be here
	const url = (video?.hls_link ?? secondaryUrl) as string;

	useEffect(() => {
		if (playing && !player) {
			setPlayer(videoEl.current);
		}
		// the hook actually does fire when the video element changes, eslint is wrong
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [playing, player, videoEl.current]);

	useEffect(() => {
		if (!isIphone || !player) return;

		const hasCues = () => {
			if (!hasIosCaptionsRef.current) {
				dispatch({ type: Actions.SetHasIosCaptions, payload: true });
			}
		};

		const getTrackList = () => {

			//tracks arrive as an iterable TextTrackList, convert to plain array ignoring all metadata tracks, only subtitles
			const titleTracks: TextTrack[] = player.textTracks ? Array.from(player.textTracks as TextTrackList).filter(getSubtitleTracks) : [];

			if (!titleTracks.length) return;

			logger?.('ios subtitle tracks:', titleTracks);

			// uploaded subtitles exist in user's language
			const anyTrackHasLanguage = titleTracks.some(track => track.language === language);

			// anonymous language tracks exist - treat as closed captions
			const anonymousTrackExists = titleTracks.some(track => track.language === '');

			// if subtitles exist in user's language use that, if only anonymous closed captions exist use that, otherwise use nothing.
			const targetLanguage = anyTrackHasLanguage ? language : anonymousTrackExists ? '' : undefined;

			titleTracks.forEach(enableTrack(targetLanguage !== undefined ? targetLanguage : ''));

			// these caption tracks originate from uploaded webvtt track files, not embedded in recordings
			dispatch({ type: Actions.LoadCaptions, payload: titleTracks });
		};

		Signals.on('captions-track-found', hasCues);
		getTrackList();
		player.textTracks?.addEventListener('addtrack', getTrackList);

		Signals.broadcastEvent('fullscreen-available', true);

		if (ReactPlayer?.canEnablePIP(url) || player.webkitSupportsPresentationMode?.("picture-in-picture")) {
			dispatch({ type: Actions.SetUseNativePip, payload: true });
		} else {
			dispatch({ type: Actions.SetUseNativePip, payload: false });
		}

		if (playerType === VideoPlayerType.hls) {
			dispatch({
				type: Actions.LoadPlaybackRates,
				payload: [0.5, 0.75, 1, 1.25, 1.5]
			});
		}

		return () => {
			player.textTracks?.removeEventListener('addtrack', getTrackList);
			Signals.off('captions-track-found');
		};
	}, [player, isIphone, playerType, logger, language, url, dispatch, showCaptions]);
};

export const useSubtitleUpdate = (logger?: (...args: unknown[]) => void) => {
	const { dispatch } = useContext(VideoStateContext);

	useEffect(() => {
		return () => {
			dispatch({ type: Actions.SetHasRecordedCues, payload: false });
		};
	}, [dispatch, logger]);
};

type DisplayCaptionsDependencies = Pick<
	VideoPlayerReducerType,
	'playerType' |
	'hasLiveCues' |
	'hasIosCaptions' |
	'hasRecordedCues' |
	'selectedCaptions' |
	'manualPip'
>;

export const getCanDisplayCaptionsButton = (
	state: DisplayCaptionsDependencies
): boolean => {
	const isEmbed = state.playerType === VideoPlayerType.embed;
	const isIvs = state.playerType === VideoPlayerType.ivs;
	const isHls = state.playerType === VideoPlayerType.hls;
	const isPlaceholder = state.playerType === VideoPlayerType.placeholder;
	const hasLiveCues = state.hasLiveCues;
	const hasIosCaptions = state.hasIosCaptions;
	const hasRecordedCues = state.hasRecordedCues;
	const selectedCaptions = state.selectedCaptions;
	const isPip = state.manualPip;

	if (isEmbed || isPlaceholder) return false;

	if (isIvs && (hasLiveCues || hasIosCaptions)) {
		return true;
	}

	if (isHls && (hasRecordedCues || hasIosCaptions)) {
		return true;
	}

	if (isPip && selectedCaptions) {
		return true;
	}

	return false;
};