import classNames from 'classnames';
import { useRef } from 'react';

import './video-feed.scss';

interface VideoFeedProps {
	mediaStream: MediaStream;
	muted?: boolean;
	className?: string;
	mirror?: boolean;
	useReference?: boolean;
	onVideoPlay?: (...args: any[]) => any;
}

export default function VideoFeed({ mediaStream, muted, className, mirror = false, useReference = true, onVideoPlay }: VideoFeedProps): JSX.Element {
	const getVideo = (): JSX.Element => {
		return (
			<video
				className={classNames(className, { 'invert-camera-feed': mirror })}
				muted={muted}
				playsInline={true}
				ref={video => {
					if (video) {
						video.srcObject = mediaStream;
						video.volume = 0;

						video.oncanplay = () => {
							video.play();
							onVideoPlay && onVideoPlay();
						};
					}
				}} />
		);
	};
	if (useReference) {
		const videoRef = useRef<JSX.Element>(getVideo());

		return videoRef.current;
	}
	else {
		return getVideo();
	}
}