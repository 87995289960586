import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router";
import classNames from 'classnames';
import { LazyMotion, domAnimation, m } from "framer-motion";

import { useTranslate } from "../../../../i18n/useTranslationModules";
import BroadcastingControls from '../../../broadcasting/broadcasting-controls';
import VideoFeed from "../../../general-ui/video-feed/video-feed";
import { Tag } from "../../../general-ui/tag/tag";
import { ICONS } from "../../../general-ui/icon";
import { getNameFromBlProfile, getCompanyAndJob } from "../../../../utils/utils";
import { BlProfile, FeatureFlagsEnum, RegFieldsEnum, Session } from "../../../../types/working-model";
import { useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import { getDefaultAgendaImage } from "../../../../store/utils/create-event";
import { ParamsProps } from "../../live-event";
import { getBroadcastServer } from '../../../../store/actions/admin/broadcasting';
import Socket from '../../../../connection/socket';
import ErrorBoundary from '../../../../utils/error-boundary';
import '../../../../scss/live-event/base/session/firesides/fireside-header.scss';
import { useAvatarIsEnabled } from '../../../../utils/avatar-is-enabled';

export const getFiresideImage = (session: Session, template: string): string => {
	return session.image || session.pre_broadcast_thumbnail || getDefaultAgendaImage(template, 0);
};

const TimeLive: React.FC<{ session: Session }> = ({ session }) => {
	const [timeLive, setTimeLive] = useState<string>("");

	const { t } = useTranslate(['session', 'homepage']);

	const startTime: Date | null = session.timestamp ? new Date(session.timestamp) : null;
	useEffect(() => {
		if (!startTime) return;
		let secondsElapsed = Math.floor((new Date().valueOf() - startTime.valueOf()) / 1000);

		const timer = setInterval(() => {
			secondsElapsed++;

			const hours = Math.floor(secondsElapsed / 3600);
			const minutes = Math.floor((secondsElapsed - (hours * 3600)) / 60);
			const seconds = secondsElapsed - (hours * 3600) - (minutes * 60);

			const hoursString = hours > 0 ? hours.toString() + ":" : "";
			const minutesString = minutes.toString().padStart(2, "0") + ":";
			const secondsString = seconds.toString().padStart(2, "0");

			setTimeLive(hoursString + minutesString + secondsString);
		}, 1000);

		return () => {
			clearInterval(timer);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Tag classNameTag={"fireside-header-info-button tag-container-live"} text={`${t("homepage:live")} ${timeLive}`} />
	);
};

interface IFiresideHeaderProps {
	videoCallConnected: boolean;
	templateClassName: string;
	blProfile: BlProfile | null;
	session: Session;
	cameraActive: boolean;
	isOnStage?: boolean;
	socket?: Socket;
}

const FiresideHeader: React.FC<IFiresideHeaderProps> = ({
	videoCallConnected,
	templateClassName,
	blProfile,
	cameraActive,
	session,
	isOnStage = true,
	socket,
}) => {
	const { language } = useParams() as ParamsProps;
	const dispatch = useDispatch();
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const selfStream = useTypedSelector(state => state.FiresidesReducer.selfStream);
	const token = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);

	const channel = eventBundle?.channel ?? 0;
	const displayAvatar = useAvatarIsEnabled(eventBundle);
	const profile = blProfile?.profile?.[channel];

	const { t } = useTranslate(['session', 'homepage']);
	const { t: sessionT } = useTranslate(`session.${session.session}`);

	useEffect(() => {
		if (token && featureFlags[FeatureFlagsEnum.firesides_broadcasting]) {
			dispatch(getBroadcastServer(token, session.uuid));
		}
	}, [token, session.uuid, dispatch, featureFlags]);

	return (
		<div className={classNames('fireside-header', templateClassName, { "on-stage": isOnStage })}>
			<div className='fireside-header-left center'>
				<div className='fireside-header-icon center'>
					<img src={getFiresideImage(session, templateClassName)} />
				</div>
				<div className={'fireside-header-info'}>
					<div className={'fireside-header-info-title-container'}>
						<div className={'fireside-header-info-title'}><span>{sessionT('title') || session.title[language] || session.title.base}</span></div>
					</div>
					<div className='fireside-header-info-buttons'>
						{featureFlags[FeatureFlagsEnum.firesides_broadcasting] ? (
							<ErrorBoundary uniqueLabel="Fireside stage broadcasting controls">
								<BroadcastingControls session={session} socket={socket}></BroadcastingControls>
							</ErrorBoundary>
						) : (
							<TimeLive session={session} />
						)}

						<Tag classNameTag={"fireside-header-info-button"} icon={ICONS.FIRESIDE} text={t("homepage:Fireside")} />
					</div>
				</div>
			</div>
			{isOnStage && (
				<LazyMotion features={domAnimation}>
					<m.div
						initial={{ translateX: '100%' }}
						animate={{ translateX: '0%' }}
						transition={{
							type: "spring",
							stiffness: 350,
							damping: 20
						}}
						className='fireside-header-right'>
						<div className={`fireside-header-user-feed margin-right ${(!cameraActive || !videoCallConnected) && "camera-off"}`}>
							{/* self feed */}
							{(videoCallConnected && selfStream && cameraActive)
								? <ErrorBoundary uniqueLabel="Fireside stage self feed"><VideoFeed mirror className='fireside-video-feed' muted={true} mediaStream={selfStream} /></ErrorBoundary>
								: <div className="attendee-card-avatar" style={{ backgroundImage: `url("${displayAvatar && profile?.avatar}")` }}>
									{displayAvatar && profile?.avatar ? null : (
										<>
											{profile?.[RegFieldsEnum.first_name]?.charAt(0)?.toUpperCase() || ''}{profile?.[RegFieldsEnum.last_name]?.charAt(0)?.toUpperCase() || ''}
										</>
									)}
								</div>
							}
						</div>
						<div className='fireside-header-user-info'>
							<div className='fireside-header-user-name'>
								<div className='name'>{blProfile && getNameFromBlProfile(blProfile, channel)}</div>
								<div className='subtext'>({t("session:You")})</div>
							</div>
							<div className='fireside-header-user-company subtext'>{blProfile && getCompanyAndJob(blProfile, channel)}</div>
						</div>
					</m.div>
				</LazyMotion>
			)}
		</div>
	);
};

export default FiresideHeader;
