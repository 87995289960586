import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../i18n/useTranslationModules";

import { GetBroadcastingErrorLogs } from "../../connection/broadcasting";
import * as BroadcastActions from "../../store/actions/admin/broadcasting";
import { Session } from '../../types/working-model';
import {
	BROADCAST_STATES,
	STREAM_STATES,
} from "../../store/reducers/admin/broadcasting";
import { useTypedSelector } from "../../store/reducers/use-typed-selector";
import { GreenroomState } from "../../store/broadcast-types";
import { getStorageItem, setStorageItem } from "../../utils/local-storage";
import { showAlert } from "../general-ui/alert/alert-service";
import Icon, { COLORS, ICONS } from "../general-ui/icon";
import LiveCounter from "../general-ui/countdown/live-counter";
import Socket from "../../connection/socket";
import './_broadcasting.scss';
import usePoll from "../../connection/use-poll";
import { sleep } from "../../utils/utils";
import { HvHostMap } from "../../connection/helpers";


const RESOLUTIONS: Record<string, string> = {
	'480p': '768x480',
	'720p': '1280x720',
	'1080p': '1920x1080',
};

const CLASS = {
	READY: "ready",
	REHEARSAL: "showSession",
	LOADING: "loading",
	LIVE: "live",
	STOPPED: "stopped",
	ERROR: "error",
	CANCEL: "cancel",
};

let cooldownTimer: NodeJS.Timeout;
let startupTimer: NodeJS.Timeout;
let secondsRemaining = 0;

interface IBroadcastingControls {
	session: Session;
	socket?: Socket;
}

export default function BroadcastingControls(props: IBroadcastingControls): JSX.Element {
	const session: Session = props.session;
	const firesidesHostSessions: number[] = useTypedSelector(state => state.LiveEventReducer.firesidesHostSessions);
	const translationUpdates = useTypedSelector(event => event.LiveEventReducer.translationUpdates);
	const host = firesidesHostSessions.includes(session.session);
	const token = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);

	const streamState = useSelector(
		(state: GreenroomState) => state.BroadcastReducer.streamState
	);
	const serverState = useSelector(
		(state: GreenroomState) => state.BroadcastReducer.serverState
	);
	const startingBroadcast = useSelector(
		(state: GreenroomState) => state.BroadcastReducer.startingBroadcast
	);
	const stoppingBroadcast = useSelector(
		(state: GreenroomState) => state.BroadcastReducer.stoppingBroadcast
	);
	// I don't know if this is supposed to stay for later use, but it's unused so I'm just commenting it out
	// const useCustomEndpoint = useSelector(
	// 	(state: GreenroomState) => state.BroadcastReducer.useCustomEndpoint
	// );
	const goLiveButtonClicked = useSelector(
		(state: GreenroomState) => state.BroadcastReducer.goLiveButtonClicked
	);
	const broadcastEndpoint = useSelector(
		(state: GreenroomState) => state.BroadcastReducer.broadcastEndpoint
	);
	const customEndpoint = useSelector(
		(state: GreenroomState) => state.BroadcastReducer.customEndpoint
	);
	const previousServerState = useSelector(
		(state: GreenroomState) => state.BroadcastReducer.previousServerState
	);
	const [hovering, setHovering] = useState(false);
	const countdownToEventInterval = useRef<NodeJS.Timeout | null>(null);
	const [showModal, setShowModal] = useState(false);
	const [cancelPoll, setCancelPoll] = useState(false);

	const dispatch = useDispatch();
	const { socket } = props;
	const streamStateRef = useRef<number>(streamState);
	const [mockStartingBroadcast, setMockStartingBroadcast] = useState(false);
	const [, updateState] = React.useState();
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-expect-error
	const forceUpdate = React.useCallback(() => updateState({}), []);

	const { t } = useTranslate(["homepage", "session"]);

	// poll broadcast server state
	const pollResult = usePoll({
		// path: `/v3/admin/broadcast/check-broadcast-status/${session.uuid}`,
		path: `/e3-check-broadcast-status/${session.uuid}`,
		token,
		cancel: cancelPoll,
		HVHost: HvHostMap.eventData,
		repo: 'microservice',
	});

	// if poll results do not match server state in redux, then update server state in redux
	useEffect(() => {
		if (!cancelPoll && pollResult?.currentState && pollResult.currentState !== serverState) {
			dispatch(BroadcastActions.setBroadcastState(pollResult.currentState as BROADCAST_STATES));
		}
	}, [cancelPoll, dispatch, pollResult, serverState]);

	// when we click the go live button, we want to cancel polling because polling state could be out of sync and cause the button to be wrong,
	// however, we don't want to permanently cancel it, so we will just wait 15 seconds and restart it
	useEffect(() => {
		if (cancelPoll) {
			const tempPausePoll = async () => {
				await sleep(15000);
				setCancelPoll(false);
			};
			tempPausePoll();
		}
	}, [cancelPoll]);

	const TEXT = useMemo(() => {
		return {
			SHOW: t("homepage:Show", ""),
			GETTING_READY: t("session:fireside.Getting Ready", ""),
			REHEARSAL: t("session:fireside.Show Session", ""),
			GO_LIVE: t("session:fireside.Go Live", ""),
			LIVE: t("session:Live", ""),
			STARTING_BROADCAST: t("session:fireside.Starting Broadcast", ""),
			END_SHOW: t("session:fireside.End Show", ""),
			SHOW_ENDED: t("session:fireside.Show Ended", ""),
			ERROR: t("session:fireside.Error", ""),
			RETRY: t("session:fireside.Retry", ""),
			CANCEL: t("session:fireside.Cancel Broadcast", ""),
		};
		// disabling so translationUpdates forces t function to re-render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t, translationUpdates]);

	// THIS IS USED FOR MANUALLY CHANGING SERVER STATES IN THE BROWSER CONSOLE FOR TESTING UI/STYLES etc.
	// @tas-ignore
	//useEffect(() => {window.setBroadcastState = (state: number) => dispatch(BroadcastActions.setBroadcastState(state));}, []);

	useEffect(() => {
		// Set an interval if the event start time is in the future. When it is reached, remove the interval which updates
		// useMemo below (ex: button automatically changing from "show starts in" to "GO LIVE" for the producer)
		if (session?.timestamp && new Date(session.timestamp) > new Date()) {
			countdownToEventInterval.current = setInterval(() => {
				if (session.timestamp && new Date(session.timestamp) <= new Date()) {
					if (countdownToEventInterval.current) {
						clearInterval(countdownToEventInterval.current);
					}
					countdownToEventInterval.current = null;
					forceUpdate(); // Necessary to update useMemo because rehearsalInterval.current doesn't trigger render update by itself
				}
			}, 1000);
		}
	}, [forceUpdate, session]);

	useEffect(() => {
		streamStateRef.current = streamState;
	}, [streamState]);

	const checkEventAndShowSessionTimesForStatus = useCallback(() => {
		if (!session) return [TEXT.SHOW, CLASS.READY, false, false];

		if (
			!host &&
			session.timestamp &&
			session.end_timestamp &&
			new Date(session.timestamp) < new Date() &&
			new Date(session.end_timestamp) > new Date()
		) {
			// (presenter only) When event start time is reached and the broadcast hasn't started yet the presenter sees "Ready to Start"
			return [TEXT.GO_LIVE, CLASS.READY, false, false];
		} else if (session.end_timestamp && new Date(session.end_timestamp) < new Date()) {
			// If we are past the event END time and not live, then the broadcast is considered done
			return [TEXT.SHOW_ENDED, CLASS.STOPPED, false, false];
		} else {
			// Countdown to show
			return [TEXT.SHOW, CLASS.READY, false, false];
		}
	}, [TEXT.GO_LIVE, TEXT.SHOW, TEXT.SHOW_ENDED, host, session]);

	// Everything that is displayed in the status button comes from here. This fires any time the broadcastState.serverState updates,
	// the producer hovers over the button, and when the event time is reached
	// stateMessage - the text displayed on the button
	// className - the subclass of .broadcast-indicator
	// loading - makes the icon spin, wheee
	// error - sub-class of .broadcast-status, but I don't see any css using .error under .broadcast-status - could be deprecated?
	const [stateMessage, className, loading, error] = useMemo(() => {
		const prevFailedStates = getStorageItem("broadcast-attempt-failed") ?? [];
		let serverStateHolder = serverState;
		if (
			prevFailedStates.includes(session?.session) &&
			previousServerState !== BROADCAST_STATES.BROADCAST_STARTED
		) {
			serverStateHolder = BROADCAST_STATES.BROADCAST_FAILED;
		} else if (
			prevFailedStates.includes(session?.session) &&
			previousServerState === BROADCAST_STATES.BROADCAST_STARTED
		) {
			serverStateHolder = BROADCAST_STATES.BROADCAST_STARTED;
			setStorageItem(
				"broadcast-attempt-failed",
				prevFailedStates.filter((id: number) => id !== session?.session)
			);
		}
		switch (serverStateHolder) {
			case BROADCAST_STATES.OFF:
			case BROADCAST_STATES.STARTING_UP:
			case BROADCAST_STATES.BROADCAST_PREWARMING:
				if (host) {
					return [TEXT.GETTING_READY, CLASS.LOADING, true, false];
				}
				return checkEventAndShowSessionTimesForStatus();
			case BROADCAST_STATES.BROADCAST_STARTING: {
				if (host) {
					if (hovering) return [TEXT.CANCEL, CLASS.CANCEL, false, false];

					return [TEXT.STARTING_BROADCAST, CLASS.LOADING, true, false];
				}
				return checkEventAndShowSessionTimesForStatus();
			}

			case BROADCAST_STATES.READY: {
				if (startingBroadcast) {
					if (hovering) return [TEXT.CANCEL, CLASS.CANCEL, false, false];

					return [TEXT.STARTING_BROADCAST, CLASS.LOADING, true, false];
				}
				if (host) {
					if (hovering) {
						return [TEXT.GO_LIVE, CLASS.READY, false, false];
					}
					return [TEXT.GO_LIVE, CLASS.READY, false, false];
				}
				return checkEventAndShowSessionTimesForStatus();
			}

			case BROADCAST_STATES.BROADCAST_STARTED: {
				if (stoppingBroadcast) {
					return [TEXT.GO_LIVE, CLASS.READY, false, false];
				}
				if (host && hovering) {
					if (streamStateRef.current === STREAM_STATES.PRE)
						return [TEXT.GO_LIVE, CLASS.READY, false, false];

					if (streamStateRef.current === STREAM_STATES.POST)
						return [TEXT.GO_LIVE, CLASS.READY, false, false];

					return [TEXT.END_SHOW, CLASS.LIVE, false, false];
				}

				if (host) {
					if (streamStateRef.current === STREAM_STATES.PRE)
						return [TEXT.GO_LIVE, CLASS.READY, false, false];

					if (streamStateRef.current === STREAM_STATES.POST)
						return [TEXT.SHOW_ENDED, CLASS.STOPPED, false, false];
				}

				return [TEXT.LIVE, CLASS.LIVE, false, false];
			}

			case BROADCAST_STATES.BROADCAST_STOPPED: {
				if (host && mockStartingBroadcast) {
					if (hovering) return [TEXT.CANCEL, CLASS.CANCEL, false, false];

					return [TEXT.STARTING_BROADCAST, CLASS.LOADING, true, false];
				} else if (host && hovering) {
					return [TEXT.GO_LIVE, CLASS.READY, false, false];
				} else if (host) {
					return [TEXT.GO_LIVE, CLASS.READY, false, false];
				}
				return checkEventAndShowSessionTimesForStatus();
			}

			case BROADCAST_STATES.BROADCAST_PREWARM_FAILED:
			case BROADCAST_STATES.BROADCAST_FAILED: {
				if (session && !prevFailedStates.includes(session.uuid)) {
					setStorageItem("broadcast-attempt-failed", JSON.stringify([
						...prevFailedStates,
						session.uuid,
					]));
				}
				if (host && hovering) {
					return [TEXT.RETRY, CLASS.READY, false, false];
				}

				if (session && token) GetBroadcastingErrorLogs(session.uuid, token);
				return [TEXT.ERROR, CLASS.ERROR, false, true];
			}

			case BROADCAST_STATES.DRAINING:
			case BROADCAST_STATES.KILLED: {
				return [TEXT.SHOW_ENDED, CLASS.STOPPED, false, true];
			}
			default:
				return [TEXT.SHOW, CLASS.READY, true, false];
		}

		// disabling so goLiveButtonClicked doesn't warn us
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		serverState,
		session,
		previousServerState,
		goLiveButtonClicked,
		host,
		checkEventAndShowSessionTimesForStatus,
		TEXT,
		hovering,
		startingBroadcast,
		stoppingBroadcast,
		mockStartingBroadcast,
		token,
	]);

	const icon = useMemo(() => {
		switch (stateMessage) {
			case TEXT.SHOW:
				return (
					<Icon
						name={ICONS.CHECK_CIRCLE}
						color={COLORS.DEFAULT_GRAY}
						size={14}
					/>
				);
			case TEXT.RETRY:
			case TEXT.GO_LIVE:
				return <Icon name={ICONS.LIVE} color={COLORS.WHITE} size={14} />;
			case TEXT.END_SHOW:
				return <Icon name={ICONS.STOP_LIVE} color={COLORS.WHITE} size={16} />;
			case TEXT.REHEARSAL:
			case TEXT.GETTING_READY:
			case TEXT.STARTING_BROADCAST:
				return (
					<Icon
						name={ICONS.REFRESH_TRANSPARENT}
						color={COLORS.DEFAULT_GRAY}
						size={14}
					/>
				);
			case TEXT.SHOW_ENDED:
			case TEXT.LIVE:
				return <div className="live-circle" />;
			case TEXT.CANCEL:
			default:
				return <Icon name={ICONS.CLOSE} color={COLORS.WHITE} size={14} />;
		}
	}, [stateMessage, TEXT]);

	const handleStoppingBroadcast = () => {
		if (!token || !session) return;
		if (startupTimer) clearInterval(startupTimer);

		setMockStartingBroadcast(false);

		dispatch(BroadcastActions.setGoLiveButtonClicked(false));
		dispatch(BroadcastActions.stopBroadcast(token, session.uuid));

		socket?.sendMessage({ type: "broadcast-off-show", data: true });
		socket?.sendMessage({ type: "mock-broadcast-starting-show", data: false });
	};

	const handleSendMockStartingMessage = () => {
		socket?.sendMessage({ type: "mock-broadcast-starting-show", data: true });
	};

	useEffect(() => {
		const handleReceiveMockStartingMessage = (data: boolean) => {
			setMockStartingBroadcast(data);
			// if we have a startupTimer going then we need to clear it so that we dont accidentally start the broadcast
			if (!data && startupTimer) {
				clearInterval(startupTimer);
			}
		};

		socket?.addListener('mock-broadcast-starting-show', handleReceiveMockStartingMessage);

		return () => {
			socket?.removeListener('mock-broadcast-starting-show', handleReceiveMockStartingMessage);
		};
	});

	function handleClick(clickedFromModal: boolean) {
		setHovering(false);
		//only hosts can edit broadcast state, session and token should be true if the button is clickable
		if (!host || !session || !token) return;
		setCancelPoll(true);

		setShowModal(false);
		//do nothing if we're waiting on a state transition
		if (startingBroadcast || stoppingBroadcast) {
			if (stateMessage === TEXT.GO_LIVE || clickedFromModal) {
				dispatch(BroadcastActions.setGoLiveButtonClicked(true));
			}
			return;
		}

		switch (serverState) {
			//start (or restart) broadcast
			case BROADCAST_STATES.BROADCAST_STOPPED:
			case BROADCAST_STATES.READY: {
				if (stateMessage === TEXT.GO_LIVE || clickedFromModal) {
					dispatch(BroadcastActions.setGoLiveButtonClicked(true));
					handleSendMockStartingMessage();
					return sendStartBroadcastMessage();
				}

				if (stateMessage === TEXT.CANCEL) {
					return handleStoppingBroadcast();
				}

				return;
			}

			//user clicked cancel when stream was starting up, cancel the broadcast
			case BROADCAST_STATES.BROADCAST_STARTING: {
				return handleStoppingBroadcast();
			}

			//queue up a start command for after server has started up
			case BROADCAST_STATES.STARTING_UP: {
				if (stateMessage === TEXT.GO_LIVE || clickedFromModal) {
					dispatch(BroadcastActions.setGoLiveButtonClicked(true));
					// Include the "Please Wait" alert here because if server just began starting up when GO LIVE is clicked
					// there could be a longer wait. Prewarming occurs after starting up, and is usually quicker, so we don't
					// include the alert message there because you will probably be live momentarily.
					showAlert({
						message: "Please Wait",
						description:
							"Broadcasts outside of the event time window may take up to 10 minutes to begin",
						duration: 7000,
					});
					return dispatch(
						BroadcastActions.getBroadcastServer(token, session.uuid)
					);
				}
				return;
			}

			//queue up a start command for after server has started up
			case BROADCAST_STATES.BROADCAST_PREWARMING: {
				if (stateMessage === TEXT.GO_LIVE || clickedFromModal) {
					dispatch(BroadcastActions.setGoLiveButtonClicked(true));
					return dispatch(
						BroadcastActions.getBroadcastServer(token, session.uuid)
					);
				}
				return;
			}

			//restart broadcast that already was live
			case BROADCAST_STATES.OFF: {
				if (stateMessage === TEXT.GO_LIVE || clickedFromModal) {
					// Server hasn't started warming up yet. Will probably take a couple of minutes before broadcast is live.
					showAlert({
						message: "Please Wait",
						description:
							"Broadcasts outside of the event time window may take up to 10 minutes to begin",
						duration: 7000,
					});
					dispatch(BroadcastActions.setGoLiveButtonClicked(true));
					return dispatch(
						BroadcastActions.getBroadcastServer(
							token,
							session.uuid
						)
					);
				}
				return;
			}

			//broadcast is in progress and user clicked button. This means they want to either
			//go from pre - media -> main stream
			//go from main stream -> post - media
			//go from post - media -> main stream
			//go from main stream -> broadcast off
			case BROADCAST_STATES.BROADCAST_STARTED: {
				//they were in the post stream, go back to canvas view OR we were showing pre-media, start main show
				if (
					streamStateRef.current === STREAM_STATES.POST ||
					streamStateRef.current === STREAM_STATES.PRE
				) {
					return socket?.sendMessage({ type: "broadcast-main-show", data: true });
				}

				return handleStoppingBroadcast();
			}

			//attempt to restart broadcast that previously errored out
			case BROADCAST_STATES.BROADCAST_FAILED: {
				if (!broadcastEndpoint?.url && !customEndpoint.url) {
					showAlert({
						message: "Cannot start broadcast",
						description: "No destination is selected",
						duration: 5000,
					});
					return;
				}

				const failedBroadcasts = getStorageItem("broadcast-attempt-failed") ?? [];
				setStorageItem(
					"broadcast-attempt-failed",
					failedBroadcasts.filter((id: string) => id !== session.uuid)
				);
				dispatch(
					BroadcastActions.setBroadcastState(BROADCAST_STATES.BROADCAST_STOPPED)
				);
				handleStoppingBroadcast();
				dispatch(BroadcastActions.setGoLiveButtonClicked(true));
				setTimeout(sendStartBroadcastMessage, 75);
				return;
			}

			default:
				return;
		}
	}

	function sendStartBroadcastMessage() {
		//both should be true, we can't have an input to start without logging in (token) and we can't start without a session
		if (!token || !session) return;

		//show starting broadcast in the UI
		setMockStartingBroadcast(true);

		//we are still cooling down since the last broadcast stop
		if (secondsRemaining > 0) {
			//set a timer to automatically send startBroadcast message after the cooldown time
			startupTimer = setInterval(() => {
				//cooldown timer has run out, actually send the start broadcast message
				if (secondsRemaining < 1) {
					//dispatch send broadcast message
					dispatch(
						BroadcastActions.startBroadcast(
							token,
							session.uuid,
							(RESOLUTIONS)['1080p']
						)
					);
					//done with this interval
					clearInterval(startupTimer);
				}
			}, 1000);
		} else {
			//we are not cooling down, just startup now
			dispatch(
				BroadcastActions.startBroadcast(
					token,
					session.uuid,
					(RESOLUTIONS)['1080p']
				)
			);
		}
	}

	useEffect(() => {
		//server has moved into the stopped phase. Need to cooldown before allowing restarting the broadcast
		//note: if the user has just landed on the page or refreshed and the broadcast is in the stopped
		//phase, this will still delay the actual broadcast start. This is OK, because people will try to
		//refresh to get around the cooldown timer.
		if (serverState === BROADCAST_STATES.BROADCAST_STOPPED) {
			//need to cool down for 30 seconds before allowing next broadcast
			secondsRemaining = 31;
			//set interval so this ticks down each second
			cooldownTimer = setInterval(() => {
				//decrement seconds remaining
				secondsRemaining -= 1;

				//done cooling down, stop this timer
				if (secondsRemaining < 1) {
					clearInterval(cooldownTimer);
				}
			}, 1000);
		}

		//don't need to mock the starting state anymore - it actually is starting
		if (serverState === BROADCAST_STATES.BROADCAST_STARTING) {
			setMockStartingBroadcast(false);
		}
	}, [serverState]);

	useEffect(() => {
		//if the cooldown phase is still going on and this component has de-rendered, stop the timers
		return () => {
			clearInterval(startupTimer);
			clearInterval(cooldownTimer);
		};
	}, []);

	const startTime = useMemo(() => {
		if (!session?.timestamp) return null;

		switch (stateMessage) {
			case TEXT.LIVE: {
				// This should be the actual start of the broadcast.  broadcast_live_start_time
				return new Date(session.timestamp);
			}
			default:
				return null;
		}
	}, [stateMessage, session, TEXT]);

	const displayHoveringClass = useMemo(() => {
		return (
			stateMessage === TEXT.GO_LIVE ||
			stateMessage === TEXT.RETRY ||
			stateMessage === TEXT.END_SHOW ||
			stateMessage === TEXT.CANCEL
		);
	}, [stateMessage, TEXT]);

	const displayCountdownOrCounter = useMemo(() => {
		return (
			stateMessage === TEXT.SHOW ||
			stateMessage === TEXT.REHEARSAL ||
			stateMessage === TEXT.LIVE ||
			stateMessage === TEXT.ERROR
		);
	}, [stateMessage, TEXT]);

	function mouseIn() {
		if (host) setHovering(true);
	}

	function mouseOut() {
		if (host) setHovering(false);
	}
	const isFireside = window.location.href.substring(window.location.href.lastIndexOf('/') + 1) === 'fireside';
	return (host || serverState === BROADCAST_STATES.BROADCAST_STARTED) &&
		session && isFireside ? (
		<>
			<div className="fireside-header-info-button broadcast-container">
				<div
					onMouseEnter={mouseIn}
					onMouseLeave={mouseOut}
					onMouseUp={() => handleClick(false)}
					className={classNames(
						"broadcast-indicator",
						className,
						displayHoveringClass && "hovering"
					)}
				>
					<div
						className={classNames(
							"broadcast-status",
							{ loading, error },
							displayHoveringClass && "hovering"
						)}
						style={{ paddingRight: displayCountdownOrCounter ? 8 : 11 }}
					>
						{icon} {stateMessage}
					</div>
					{/* display:none so that when producer hovers the timers don't reset */}
					<div
						className="clock"
						style={{ display: displayCountdownOrCounter ? "flex" : "none" }}
					>
						{serverState === BROADCAST_STATES.BROADCAST_STARTED &&
							streamStateRef.current === STREAM_STATES.MAIN ? (
							<LiveCounter
								notLive={false}
								isHeader={true}
								isHost={host}
								startTime={startTime}
							/>
						) : (null
							//   The following will be implemented when we add an actual start time to fireside session model	
							//   <Countdown
							//     start_timestamp={
							//       stateMessage === TEXT.REHEARSAL && nextShowSession.current
							//         ? nextShowSession.current.start_time
							//         : session.start_time
							//     }
							//     end_timestamp={
							//       stateMessage === TEXT.REHEARSAL && nextShowSession.current
							//         ? nextShowSession.current.end_time
							//         : session.end_time
							//     }
							//   />
						)}
					</div>
				</div>

				{(streamStateRef.current === STREAM_STATES.PRE ||
					streamStateRef.current === STREAM_STATES.POST) &&
					serverState === BROADCAST_STATES.BROADCAST_STARTED && (
						<div className="stream-state-badge">
							<div className="live-dot" />
							<span>
								{streamStateRef.current === STREAM_STATES.PRE ? "Pre" : "Post"}
								-Show
							</span>
						</div>
					)}
			</div>
			{showModal && (
				<div className="broadcasting-start-overlay">
					<div className="broadcasting-start-modal">
						<h4>Before you go Live…</h4>
						<p>
							Your greenroom is configured in a way that will allow off-screen
							presenters’ audio to leak into your live show. Turning on
							“Backstage & Producer Comms” will isolate audio output to your
							in-canvas presenters.
							<a
								href="https://help.brand.live/hc/en-us/articles/360062129473-Backstage-and-Producer-Comms"
								target="_blank"
								rel="noopener noreferrer"
							>
								{" "}
								Learn more in the Help Center
							</a>
						</p>

						<div className="button-container">
							<button
								className="go-live padding-button-medium button-background-shading"
								onClick={() => handleClick(true)}
							>
								<Icon name={ICONS.LIVE} color={COLORS.WHITE} size={14} />
								&nbsp;&nbsp;GO LIVE
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	) : <></>;
}
