import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { setLeaderboardIsOn, setLiveSessionsSync, updateEventBundle, updateTestSessionStream } from "../../store/actions/event/event-actions";
import Socket, { SocketMessage } from "../../connection/socket";
import { useTypedSelector } from "../../store/reducers/use-typed-selector";
import { hasEventAccess } from "../../utils/registration-utils";
import SocketManager from "../../connection/socket-main-thread/socket-manager";

const useBundleUpdateSockets = () => {
	const socket = useRef<Socket | null>(null);
	const liveSessionsTimeout = useRef<NodeJS.Timeout | null>(null);
	const [hasSocket, setHasSocket] = useState(false);

	const eventBundle = useTypedSelector(event => event.LiveEventReducer.eventBundle);
	const validSessions = useTypedSelector(event => event.LiveEventReducer.validSessions);
	const finishedRegistering = useTypedSelector(event => event.LiveEventReducer.finishedRegistering);
	const userVerified = useTypedSelector(event => event.LiveEventReducer.userVerified);
	const firesidesHostSessions = useTypedSelector(event => event.LiveEventReducer.firesidesHostSessions);
	const registrationBypass = useTypedSelector(state => !!state.LiveEventReducer.registration_bypass);
	const loadingValidSessions = useTypedSelector(state => state.LiveEventReducer.loadingValidSessions);

	const dispatch = useDispatch();

	// set as a const so that the useEffect can efficiently handle changes to the eventBundle without over firing the useEffect
	const socketKey = `event-${eventBundle?.uuid}`;

	// separate out whether the user has access out of the original useEffect
	// we do not want to reconnect to the websocket whenever this changes as it can change a lot
	const hasAccess = useMemo(() => {
		return hasEventAccess(eventBundle, validSessions, finishedRegistering, userVerified, firesidesHostSessions, registrationBypass, loadingValidSessions);
	}, [eventBundle, finishedRegistering, firesidesHostSessions, loadingValidSessions, registrationBypass, userVerified, validSessions]);

	const timeoutLiveSessions = useCallback(() => {
		if (liveSessionsTimeout.current) {
			clearTimeout(liveSessionsTimeout.current);
		}
		liveSessionsTimeout.current = setTimeout(() => {
			dispatch(setLiveSessionsSync([]));
		}, 1000 * 70);
	}, [dispatch]);

	// only acquire a new connection to this socket when the uuid actually changes and does not contain undefined (which is only while the page is loading)
	useEffect(() => {
		if (!socketKey.includes('undefined')) {
			socket.current = SocketManager.get(socketKey);
			setHasSocket(true);
		}

		return () => {
			SocketManager.leave(socketKey);
			setHasSocket(false);
		};
	}, [socketKey]);

	useEffect(() => {
		const handleBundleUpdate = async (message: SocketMessage) => {
			try {
				const res = await fetch(message.data);
				if (res.ok) {
					const eventBundle = await res.json();
					dispatch(updateEventBundle(eventBundle));
				}
			} catch (e) {
				console.error(e);
			}
		};

		const handleSecBundleUpdate = async (message: SocketMessage) => {
			try {
				const res = await fetch(message.data);
				if (res.ok) {
					const eventBundle = await res.json();
					dispatch(updateEventBundle(eventBundle));
				}
			} catch (e) {
				console.error(e);
			}
		};

		const handleLiveSessionsUpdate = (message: SocketMessage) => {

			dispatch(setLiveSessionsSync(message.data.sessions));

			//if no sessions are live at all, no websocket message will arrive
			//cron job fires every 60 seconds. Expire the live sessions list in 70 seconds
			//as we can assume no sessions are live since we haven't been updated in the last 60 seconds
			timeoutLiveSessions();
		};

		const handleSessionStreamUpdate = (message: SocketMessage) => {
			dispatch(updateTestSessionStream(message.data.testStream, message.data.session_uuid));
		};

		const handleLeaderboardToggle = (message: SocketMessage) => {
			dispatch(setLeaderboardIsOn(message.data.isOn));
		};

		// only deal with this if there is currently a socket connection
		if (hasSocket) {
			if (hasAccess) {
				socket.current?.addListener('bundle_update_sec_url', handleSecBundleUpdate);
			} else {
				socket.current?.addListener('bundle_update_url', handleBundleUpdate);
			}

			socket.current?.addListener('live-sessions', handleLiveSessionsUpdate);
			socket.current?.addListener('session-stream-update', handleSessionStreamUpdate);
			socket.current?.addListener('leaderboard-isOn', handleLeaderboardToggle);
		}

		//if we have gone more than 60 seconds waiting on a websocket message to arrive to tell us if the event is live,
		//nothing is live. Use case is if user hits page, detects that something is live, in the next minute the channel stops
		//but the user never received a websocket message to confirm that the channel was live.
		timeoutLiveSessions();

		return () => {
			socket.current?.removeListener('bundle_update_url', handleBundleUpdate);
			socket.current?.removeListener('bundle_update_sec_url', handleSecBundleUpdate);
			socket.current?.removeListener('live-sessions', handleLiveSessionsUpdate);
			socket.current?.removeListener('session-stream-update', handleSessionStreamUpdate);
			socket.current?.removeListener('leaderboard-isOn', handleLeaderboardToggle);

			if (liveSessionsTimeout.current) {
				clearTimeout(liveSessionsTimeout.current);
			}
		};
		// make certain that the effect only fires when the relevant data changes (event uuid, whether the user has access) or we'll end up 
		// with huge connection spikes whenever users navigate around OR whenever the bundle is updated
	}, [dispatch, hasAccess, timeoutLiveSessions, hasSocket]);
};

export default useBundleUpdateSockets;
