import Icon, { COLORS, ICONS } from "@general-ui/icon";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { uniqueId } from "underscore";

import { useAppDispatch, useTypedSelector } from "store/reducers/use-typed-selector";
import { CustomPage, FeatureFlagsEnum, GateTypes, PageGating, Requirement, RequirementMap } from "types/working-model";
import { OptionalComponent } from "utils/optional-component";
import { Tooltip } from "@general-ui/tooltip/tooltip";
import CardAddRequirement from "./card-add-requirement";
import CardAudienceListRequirement from "./card-audience-list";
import CardCustomPageGatingRequirement from "./card-custom-page-gating-requirement";
import CardDomainsRequirement from "./card-domains-requirement";
import CardNewAudienceList from "./card-new-audience-list";
import CardSharedPasscodeRequirement from "./card-shared-passcode-requirement";
import PaidTicketingSettings from "../settings/registration-settings/paid-ticketing";
import PanelAdvancedSettings from "./panel-advanced-settings";
import LargeButton from "@general-ui/button/large-button";
import Switch from "@general-ui/switch/switch";
import ModalComponent from "@general-ui/modal/modal";
import { togglePasswordGating } from "store/actions/admin/create-event";

import "../settings/settings.scss";
import "./card-gating-option.scss";

export const defaultPageGating: PageGating = {
	isOn: false,
	pageGates: []
};

interface ListType {
	type: GateTypes | 'password';
	name: string;
	subTitle: string;
	icon: string;
	disabled?: boolean;
	tooltip?: string;
	toggleButton?: boolean;
}
export interface GatingListType {
	type: GateTypes;
	name: string;
	subTitle: string;
	icon: string;
	component: JSX.Element
}
const RegistrationSettings = () => {
	const dispatch = useAppDispatch();
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const passwordGatingFlagEnabled = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags[FeatureFlagsEnum.password_gating]);

	// To be broken out and added to when adding a new gating option, or when editing an existing one
	const [allRequirements, setAllRequirements] = useState<RequirementMap | undefined>(workingEvent?.registration_settings?.requirements);

	const [addingNewToOption, setAddingNewToOption] = useState<boolean>(false);
	const [isAddNewRequirement, setIsAddNewRequirement] = useState<boolean>(false);
	const [isAddNewPageGating, setIsAddNewPageGating] = useState<boolean>(false);
	const [openPageGating, setOpenPageGating] = useState<boolean>(false);
	const [creatingNewList, setCreatingNewList] = useState<boolean>(false);
	const [showPasswordGatingCofirmationModal, setShowPasswordGatingConfirmationModal] = useState<boolean>(false);

	const customPages: CustomPage[] = useMemo(() => workingEvent?.custom_pages ?? [], [workingEvent?.custom_pages]);
	// if page gating has not yet been created for an event, insert default settings

	const pageGating: PageGating = useMemo(() => workingEvent?.settings.pageGating || defaultPageGating, [workingEvent?.settings.pageGating]);

	const passwordGatingEnabled = useMemo(() => !!workingEvent?.registration_settings?.password_gating_enabled, [workingEvent?.registration_settings?.password_gating_enabled]);

	useEffect(() => {
		setAllRequirements(workingEvent?.registration_settings?.requirements);
	}, [workingEvent?.registration_settings?.requirements]);

	const listTypes: ListType[] = useMemo(() => [
		{
			type: GateTypes.passcode_list,
			name: "Audience list",
			subTitle: "Allow list members only",
			icon: ICONS.LAYOUT_LIST,
		},
		{
			type: GateTypes.domain_email,
			name: "Domains",
			subTitle: "Allow or block email domains",
			icon: ICONS.EMAIL,
		},
		{
			type: GateTypes.shared_passcode,
			name: "Passcode",
			subTitle: "Add shared access code",
			icon: ICONS.MAGIC_LINK,
		},
		{
			type: GateTypes.custom_page_gating,
			name: "Custom Page Gating",
			subTitle: "Allow list members only",
			icon: ICONS.LAYOUT_LIST,
			disabled: !customPages?.length
		},
		{
			type: 'password',
			name: "Password login",
			subTitle: "Add password gating",
			icon: ICONS.PADLOCK,
			// If we turn the feature flag off after a prod event has it enabled,
			// still show the toggle so it can still be toggled off (the settings block will then disappear).
			disabled: !passwordGatingFlagEnabled && !passwordGatingEnabled,
			tooltip: "Enabling password login will disable any other gating methods added.",
			toggleButton: true
		},
	], [customPages?.length, passwordGatingFlagEnabled, workingEvent?.registration_settings?.requirements]);

	function getEmptyGateRequirement(type = GateTypes.domain_email): Requirement | Requirement[] {
		return { type, id: uniqueId() };
	}

	const addNewGatingOption = (type: GateTypes | 'password') => {
		if (type === 'password') {
			return;
		}

		setAddingNewToOption(true);
		if (type === GateTypes.passcode_list) {

			const passwordReq = [...(allRequirements?.[GateTypes.passcode_list] || []) as Requirement[]];

			passwordReq.push(getEmptyGateRequirement(type) as Requirement);
			setAllRequirements(prev => {
				return ({
					...prev,
					[type]: passwordReq,
				});
			});
		}

		else if (type === GateTypes.custom_page_gating) {
			setOpenPageGating(true);
		}

		else {
			setAllRequirements(prev => {
				return ({
					...prev,
					[type]: getEmptyGateRequirement(type),
				});
			});
		}
	};

	function listTypeComponent(listType: ListType, req: Requirement | Requirement[] | undefined, showAddRequirementCard: boolean) {
		switch (listType.type) {
			// survey components below are existing and very outdated
			// I commented out most of what is there so they would be basic functioning components that I could use to verify the routing works as expected
			// new page (tab) routing can be added here following the pattern below
			case GateTypes.domain_email:
				return (<CardDomainsRequirement
					requirement={req as Requirement}
					setAllRequirements={setAllRequirements}
				/>);
			case GateTypes.shared_passcode:
				return (<CardSharedPasscodeRequirement
					requirement={req as Requirement}
					setAllRequirements={setAllRequirements}
				/>);
			case GateTypes.passcode_list:
				return (
					<>
						{/* // Passcode lists are an array while all the other types are a single object */}
						{!isAddNewRequirement && (req as Requirement[])?.map((r, i) => (
							<CardAudienceListRequirement
								key={`${r.passcodeList?.join('')}-${r.gatedSessions?.join('')}`}
								requirement={r as Requirement}
								setAllRequirements={setAllRequirements}
								allRequirements={allRequirements}
								reqIndex={i}
								addingNewToOption={addingNewToOption}
								setAddingNewToOption={setAddingNewToOption}
								setIsAddNewRequirement={setIsAddNewRequirement}
							/>

						))}

						{
							isAddNewRequirement && (
								<CardNewAudienceList
									setIsAddNewRequirement={setIsAddNewRequirement}
								/>
							)
						}

						{
							!isAddNewRequirement && showAddRequirementCard && (
								<CardAddRequirement
									addNewGatingOption={addNewGatingOption}
								/>
							)
						}
					</>
				);

			case GateTypes.ticketing:
				return (
					<PaidTicketingSettings />
				);
			case GateTypes.custom_page_gating: {
				const showAddPageGatingCard = (pageGating?.pageGates?.length > 0);
				return (
					<>

						{/* The display list of added gates and the edit gate componenet */}
						{pageGating && (
							<CardCustomPageGatingRequirement
								setOpenPageGating={setOpenPageGating}
								openPageGating={openPageGating}
								setIsAddNewPageGating={setIsAddNewPageGating}
								creatingNewList={creatingNewList}
								setCreatingNewList={setCreatingNewList}
							/>
						)
						}

						{/* Creating a brand new passcode list */}
						{
							isAddNewPageGating && (
								<CardNewAudienceList
									setIsAddNewRequirement={setIsAddNewPageGating}
									setOpenPageGating={setOpenPageGating}
									setCreatingNewList={setCreatingNewList}
								/>
							)
						}

						{/* The "Add Requirement" Button to create a new gate */}
						{
							!openPageGating && !isAddNewPageGating && showAddPageGatingCard && (
								<div className="add-requirement">
									<LargeButton
										title={"Add Requirements"}
										style={{ width: "100%" }}
										onClick={() => setOpenPageGating(true)}
										nonUpload
									/>
								</div>
							)
						}
					</>
				);
			}

			default:
				return <></>;
		}
	}

	return (
		<div className="registration-options-container-v2">
			<div className="registration-panel-container">
				{
					listTypes.map((listType, index) => {
						// Gets the existing requirement for the list type
						// req is unused for password gating
						const req = (listType.type as unknown as string) === 'password' ? undefined : allRequirements?.[listType.type as GateTypes];
						const showAddCustomPageGating = listType.type === GateTypes.custom_page_gating && !(pageGating?.pageGates.length > 0 || openPageGating);
						const showAddRequirement = listType.type !== GateTypes.custom_page_gating && !req;

						const showAddRequirementCard = (
							listType.type === GateTypes.passcode_list &&
							(req as Requirement[])?.length > 0 &&
							!!(req as Requirement[])?.[0]?.passcodeList);

						if (listType.disabled) return <React.Fragment key={index}></React.Fragment>;
						return (
							// Displays the top of the card that is always showing
							<div
								key={index}
								className="gating-option-card">
								<div className="card-header">
									<div className="left">
										<div className="gating-icon">
											<Icon name={listType.icon} size={18} color="white" />
										</div>
										<div className="gating-description">
											<div className="name">{listType.name}
												<OptionalComponent display={!!listType.tooltip}>
													<Tooltip
														maxWidth={200}
														tooltip={listType?.tooltip || ""}>
														<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
													</Tooltip>
												</OptionalComponent>
											</div>

											<div className="subtitle">{listType.subTitle}</div>
										</div>
									</div>
									<OptionalComponent display={(showAddRequirement || showAddCustomPageGating) && !listType.toggleButton}>
										<div className="right">
											<button
												disabled={listType.disabled || passwordGatingEnabled}
												onClick={() => addNewGatingOption(listType.type)} className="no-style bl-rainbow">Add</button>
										</div>
									</OptionalComponent>
									<OptionalComponent display={!!listType.toggleButton}>
										<div className="right">
											<Switch
												on={passwordGatingEnabled}
												onClick={() => passwordGatingEnabled ? dispatch(togglePasswordGating(false)) : setShowPasswordGatingConfirmationModal(true)}
												value="password-gating-toggle-button"
											/>
										</div>
									</OptionalComponent>
								</div>

								{/* Hidden until there are gating options or they click Add */}
								<div className={classNames("card-body", { "expanded": (!!req && (listType.type as unknown as string) !== 'password') || ((openPageGating || pageGating?.pageGates.length) && listType.type === GateTypes.custom_page_gating) })}>
									<div className="gating-option-form">
										{listTypeComponent(listType, req, showAddRequirementCard)}
									</div>
								</div>
							</div>
						);
					})
				}

				<PanelAdvancedSettings />

				<ModalComponent
					className="date-filter-modal"
					title="Remove Gating to Enable Password Login"
					open={showPasswordGatingCofirmationModal}
					padTitle={false}
					onRequestClose={() => setShowPasswordGatingConfirmationModal(false)}
					closeable
					cancellable
					footer={<>
						<button onClick={() => setShowPasswordGatingConfirmationModal(false)}>Cancel</button>
						<button className="lemonade" onClick={() => {
							dispatch(togglePasswordGating(true));
							setShowPasswordGatingConfirmationModal(false);
						}}>
							Continue
						</button>
					</>}
				>
					Enabling password login will undo any gating you have added to your project. Are you sure you want to proceed?
				</ModalComponent>
			</div>
		</div>
	);
};

export default RegistrationSettings;