import { PGSortOrder } from "../../../components/live-event/marketing-page/marketing-registered-directory/marketing-registered-directory";
import { calculateFinishedSurvey, CompletedQuizQuestion, getPollAnswerLocalStorageKey, getPollAnswerLocalStorageKeyBackwardsCompatible } from "../../../components/live-event/session/session-modules/survey/survey";
import { UpdateUserSavedSessions } from "../../../connection/events";
import { SearchLeaderboard, GetLeaderboardDataForProfile, GetLeaderboardForUser } from '../../../connection/leaderboard';
import { GetTicketQuantities } from "../../../connection/live-events/event";
import {
	CompleteProfile,
	IProfileComplete,
	IRegistrationRequest,
	Register,
	CreateStripeIntent,
	IStripeOptions,
	IStripeData,
	ReRegister,
	ReRegisterCheck,
	IReRegistrationRequest,
	EditProfile,
	IProfileEditRequest,
	SSORegister,
	GoogleSSORequest,
	GetMarketingEmailSelection,
	IRegistrationGDPR,
	EventbriteTicketPurchase,
	IEventbriteTicketPurchaseData,
	RequestRegistrationPasswordReset,
	UpdateRegistrationPassword,
	RegistrationSignIn
} from "../../../connection/registration";
import { BookmarkProfile, SearchProfiles } from "../../../connection/social";
import { GetUserSession, UpdateUserSessionEndTime, GetPageSession, UpdatePageSessionEndTime } from "../../../connection/user-sessions";
import { Action, AsyncAction } from "../../../types/actions";
import {
	BLChannelProfile,
	BlProfile,
	BrandliveEvent,
	Dictionary,
	IMultiSelectOption,
	LanguagesAbbr,
	PageModule,
	QuizSurvey,
	RegistrationQuestion,
	Session,
	Survey,
	SurveyAnswerBody,
	SurveyType,
} from "../../../types/working-model";
import { CheckPlaybackUrls } from "../../../utils/live-checker";
import { checkExpiration, getStorageItem } from "../../../utils/local-storage";
import { LoadEventBundle, LoadPage } from "../../utils/page-loader";
import { GetUserSurveySessionRating, ConfirmGDPRCookies } from '../../../connection/notifications';
import db from '../../utils/indexed-db';
import { GetSessionLiveState } from "connection/live-states";
import { LiveStateChangePayload } from "types/live-state";
import { RegFailureReasons } from 'utils/use-get-registration-error-message';


export const SET_BL_USER = "SET_BL_USER";
export function setBlUser(user: BlProfile | null): Action {
	return {
		type: SET_BL_USER,
		payload: user,
	};
}

export const REGISTER = "REGISTER";
export function register(
	request: IRegistrationRequest,
	eventUuid: string,
	bl_token?: string,
	headers?: Record<string, string>
): AsyncAction {
	return {
		type: REGISTER,
		promise: Register(request, eventUuid, bl_token, headers, undefined),
		meta: { submittedPassword: !!request.password }
	};
}

export const SSO_REGISTER = "SSO_REGISTER";
export function ssoRegister(request: GoogleSSORequest, eventUuid: string): AsyncAction {
	const bl_token = getStorageItem('Bl-Profile-Token');
	return {
		type: REGISTER,
		promise: SSORegister(request, eventUuid, bl_token)
	};
}

export const GET_USER_SESSION = "GET_USER_SESSION";
export function getUserSession(
	sessionId: number,
	event: number,
	channel: number,
	token?: string,
	registration_id?: string,
	registeredLanguage?: string,
	referrer?: string,
	validPasscodeLists?: number[],
): AsyncAction {
	return {
		type: GET_USER_SESSION,
		promise: GetUserSession(sessionId, event, channel, token, registration_id, registeredLanguage, referrer, validPasscodeLists)
	};
}

export const UPDATE_USER_SESSION_END_TIME = "UPDATE_USER_SESSION_END_TIME";
export function updateUserSessionEndTime(userSessionUuid: string): AsyncAction {
	return {
		type: UPDATE_USER_SESSION_END_TIME,
		promise: UpdateUserSessionEndTime(userSessionUuid)
	};
}

export const SET_USER_SESSION = "SET_USER_SESSION";
export function setUserSession(userSession: string): Action {
	return {
		type: SET_USER_SESSION,
		payload: userSession
	};
}

export const RESET_USER_SESSION = "RESET_USER_SESSION";
export function resetUserSession(): Action {
	return {
		type: RESET_USER_SESSION,
		payload: null
	};
}

export const GET_PAGE_SESSION = "GET_PAGE_SESSION";
export function getPageSession(eventId: number, token?: string, registration_id?: string, referrer?: string): AsyncAction {
	return {
		type: GET_PAGE_SESSION,
		promise: GetPageSession(eventId, token, registration_id, referrer)
	};
}

export const UPDATE_PAGE_SESSION_END_TIME = "UPDATE_PAGE_SESSION_END_TIME";
export function updatePageSessionEndTime(pageSession: string): AsyncAction {
	return {
		type: UPDATE_PAGE_SESSION_END_TIME,
		promise: UpdatePageSessionEndTime(pageSession)
	};
}

export const SET_PAGE_SESSION = "SET_PAGE_SESSION";
export function setPageSession(pageSession: string): Action {
	return {
		type: SET_PAGE_SESSION,
		payload: pageSession
	};
}

export const SET_REGISTRATION_STEP = "SET_REGISTRATION_STEP";
export function setRegistrationStep(step: number): Action {
	return {
		type: SET_REGISTRATION_STEP,
		payload: step
	};
}

export const COMPLETE_PROFILE = "COMPLETE_PROFILE";
export function completeRegistrationProfile(request: IProfileComplete, bl_token?: string): AsyncAction {
	return {
		type: COMPLETE_PROFILE,
		promise: CompleteProfile(request, bl_token)
	};
}

export const EDIT_PROFILE = "EDIT_PROFILE";
export function editProfile(updatedProfile: IProfileEditRequest, eventUuid: string, bl_token?: string): AsyncAction {
	return {
		type: EDIT_PROFILE,
		promise: EditProfile(updatedProfile, eventUuid, bl_token)
	};
}


export const PURCHASE_EVENTBRITE_TICKET = "PURCHASE_EVENTBRITE_TICKET";
export function eventbriteTicketPurchase(data: IEventbriteTicketPurchaseData): AsyncAction {
	return {
		type: PURCHASE_EVENTBRITE_TICKET,
		promise: EventbriteTicketPurchase(data),
	};
}

export const CREATE_STRIPE_INTENT = "CREATE_STRIPE_INTENT";
export function createStripeIntent(options: IStripeOptions, data: IStripeData): AsyncAction {
	return {
		type: CREATE_STRIPE_INTENT,
		promise: CreateStripeIntent(options, data),
	};
}

export const FINISHED_STRIPE_PAYMENT = "FINISHED_STRIPE_PAYMENT";
export function finishedStripePayment(): Action {
	return {
		type: FINISHED_STRIPE_PAYMENT,
		payload: true,
	};
}

export const SET_PAID_SESSIONS = "SET_PAID_SESSIONS";
export function setPaidSessions(paidSessions: Array<string>): Action {
	return {
		type: SET_PAID_SESSIONS,
		payload: paidSessions,
	};
}

export const TOGGLE_REGISTRATION_MODAL = "TOGGLE_REGISTRATION_MODAL";
export function toggleRegistrationModal(sessionUuid: string | null): Action {
	return {
		type: TOGGLE_REGISTRATION_MODAL,
		payload: sessionUuid,
	};
}

export const SET_BL_PROFILE_TOKEN = "SET_BL_PROFILE_TOKEN";
export function setBlProfileToken(token: string): Action {
	return {
		type: SET_BL_PROFILE_TOKEN,
		payload: token,
	};
}

export const CLEAR_ERROR_UPDATING_PROFILE = "CLEAR_ERROR_UPDATING_PROFILE";
export function clearErrorUpdatingProfile(): Action {
	return {
		type: CLEAR_ERROR_UPDATING_PROFILE,
		payload: null,
	};
}

export const CHECK_QUIZZES_FOR_COMPLETION = "CHECK_QUIZZES_FOR_COMPLETION";
export function checkQuizzesForCompletion(
	module: PageModule,
	eventId: number,
	sessions?: Session[]
): Action {
	const modules: Survey[] | undefined = module.modules;
	if (!modules) {
		// resets to initial
		return {
			type: CHECK_QUIZZES_FOR_COMPLETION,
			payload: {},
		};
	}

	// Filter for quizzes, produce array of objects expected by `calculateFinishedSurvey`
	const sessionIds = sessions?.map(sess => sess.session);
	const quizzes = modules.filter((m) => m.survey_type === SurveyType.quiz) as QuizSurvey[];

	// Pulls all the localstorage items here, checks the expiration. Doing this here
	// to prevent us from calling local storage potentially thousands of times.
	const localStorageItems = { ...localStorage };
	for (const key of Object.keys(localStorageItems)) {
		checkExpiration(key, localStorageItems[key]);
	}

	const allCompletedQuizQuestions = quizzes
		.map((m) => {
			return m.questions
				.map((q) => {
					// We're just looking for completion here, not to set answered values
					const lsKey = getPollAnswerLocalStorageKey(q, m.survey, eventId);
					const lsKeyBackwardsCompatible = getPollAnswerLocalStorageKeyBackwardsCompatible(q, m.survey);
					const answer = localStorageItems[lsKey] ?? localStorageItems[lsKeyBackwardsCompatible] ?? null;
					if (!answer) return null;

					const JSONAnswer: SurveyAnswerBody = JSON.parse(answer);
					if (!JSONAnswer?.radio_answer) {
						// Will filter out nulls
						return null;
					}
					if (JSONAnswer.session && !sessionIds?.includes(JSONAnswer.session)) {
						// This was existing logic, makes sense but not checked where values are loaded (see question type components)
						return null;
					}

					return {
						question: q.survey_question,
						option: JSONAnswer.radio_answer,
					};
				});
		})
		.flat()
		.filter((a) => !!a) as CompletedQuizQuestion[];

	// This runs through the questions and totals up correct answers
	// Note that this could be simplified in part by adding isCorrect to CompletedQuizQuestion type
	const quizzesStatus = calculateFinishedSurvey(module, allCompletedQuizQuestions);
	return {
		type: CHECK_QUIZZES_FOR_COMPLETION,
		payload: quizzesStatus,
	};
}

export const UPDATE_EVENT_BUNDLE = "UPDATE_EVENT_BUNDLE";
export function updateEventBundle(eventBundle: BrandliveEvent): Action {
	return {
		type: UPDATE_EVENT_BUNDLE,
		payload: eventBundle
	};
}

export const LOAD_EVENT_BUNDLE = "LOAD_EVENT_BUNDLE";
export function loadEventBundle(channelHost: string, eventUrl: string): AsyncAction {
	return {
		type: LOAD_EVENT_BUNDLE,
		promise: LoadEventBundle(channelHost, eventUrl)
	};
}

export const LOAD_PAGE = "LOAD_PAGE";
export function loadPage(eventUuid: BrandliveEvent, t: any): AsyncAction {
	return {
		type: LOAD_PAGE,
		promise: LoadPage(eventUuid, t)
	};
}

export const GET_TICKET_INFO = "GET_TICKET_INFO";
export function getTicketQuantities(channelHost: string, eventUrl: string): AsyncAction {
	return {
		type: GET_TICKET_INFO,
		promise: GetTicketQuantities(channelHost, eventUrl),
	};
}

export const RE_REGISTER = "RE_REGISTER";
export function reRegister(params: IReRegistrationRequest, headers?: Record<string, string>): AsyncAction {
	return {
		type: RE_REGISTER,
		promise: ReRegister(params, headers)
	};
}

export const RE_REGISTER_CHECK = "RE_REGISTER_CHECK";
export function reRegisterCheck(eventUuid: string, code: string): AsyncAction {
	return {
		type: RE_REGISTER_CHECK,
		promise: ReRegisterCheck(eventUuid, code)
	};
}

export const REGISTRATION_SIGN_IN = "REGISTRATION_SIGN_IN";
export function registrationSignIn(
	eventUuid: string,
	email: string,
	password: string,
	source: string,
	lang: string,
	timezone: string
): AsyncAction {
	return {
		type: REGISTRATION_SIGN_IN,
		promise: RegistrationSignIn(
			eventUuid,
			email,
			password,
			source,
			lang,
			timezone
		)
	};
}

export const REGISTRATION_PASSWORD_RESET = "REGISTRATION_PASSWORD_RESET";
export function requestRegistrationPasswordReset(email: string, language: LanguagesAbbr, eventUuid: string): AsyncAction {
	return {
		type: REGISTRATION_PASSWORD_RESET,
		promise: RequestRegistrationPasswordReset(email, language, eventUuid)
	};
}

export const UPDATE_REGISTRATION_PASSWORD = "UPDATE_REGISTRATION_PASSWORD";
export function updateRegistrationPassword(
	eventUuid: string,
	code: string,
	password: string,
	source: string,
	lang: string,
	timezone: string
): AsyncAction {
	return {
		type: UPDATE_REGISTRATION_PASSWORD,
		promise: UpdateRegistrationPassword(
			eventUuid,
			code,
			password,
			source,
			lang,
			timezone
		)
	};
}

export const SET_LIVE_SESSIONS = "SET_LIVE_SESSIONS";
export function setLiveSessions(playbackUrls: Dictionary): AsyncAction {
	return {
		type: SET_LIVE_SESSIONS,
		promise: CheckPlaybackUrls(playbackUrls)
	};
}

export const SET_LIVE_SESSIONS_SYNC = "SET_LIVE_SESSIONS_SYNC";
export function setLiveSessionsSync(sessions: string[]): Action {
	return {
		type: SET_LIVE_SESSIONS_SYNC,
		payload: sessions
	};
}

export const RESET_REGISTRATION_STEP = "RESET_REGISTRATION_STEP";
export function resetRegistrationStep(step = 0): Action {
	return {
		type: RESET_REGISTRATION_STEP,
		payload: step
	};
}

export const SEARCH_PROFILES = "SEARCH_PROFILES";
export function searchProfiles(
	channelId: number,
	eventId: number,
	criteria: string,
	paginationLimit: number,
	paginationOffset: number,
	token: string,
	filters?: any[] | null,
	columns?: Pick<RegistrationQuestion, 'registration_question' | 'type'>[] | null,
	sortColumn?: RegistrationQuestion["registration_question"] | null,
	sortOrder?: PGSortOrder | null,
): AsyncAction {
	return {
		type: SEARCH_PROFILES,
		promise: SearchProfiles(channelId, eventId, criteria, paginationLimit, paginationOffset, token, filters, columns, sortColumn, sortOrder)
	};
}

export const BOOKMARK_PROFILE = "BOOKMARK_PROFILE";
export function bookmarkProfile(channel: number, profileUuid: string, token: string): AsyncAction {
	return {
		type: BOOKMARK_PROFILE,
		promise: BookmarkProfile(channel, profileUuid, token)
	};
}

export const DISPLAY_LOADING_PROFILE_MODAL = "DISPLAY_LOADING_PROFILE_MODAL";
export function setDisplayLoadingProfileModal(loading: boolean): Action {
	return {
		type: DISPLAY_LOADING_PROFILE_MODAL,
		payload: loading,
	};
}

export const DISPLAY_PROFILE_MODAL = "DISPLAY_PROFILE_MODAL";
export function setDisplayProfileModal(profile: BLChannelProfile | null): Action {
	return {
		type: DISPLAY_PROFILE_MODAL,
		payload: profile
	};
}

export const UPDATE_TEST_SESSION_STREAM = "UPDATE_TEST_SESSION_STREAM";
export function updateTestSessionStream(isTest: boolean, sessionUuid: string): Action {
	return {
		type: UPDATE_TEST_SESSION_STREAM,
		payload: {
			isTest,
			sessionUuid
		}
	};
}

export const UPDATE_MY_LIST_SESSIONS = "UPDATE_MY_LIST_SESSIONS";
export function updateMyListSessions(token: string, eventUuid: string, sessionUuids: string[]): AsyncAction {
	return {
		type: UPDATE_MY_LIST_SESSIONS,
		promise: UpdateUserSavedSessions(token, eventUuid, sessionUuids),
		meta: { payload: sessionUuids }
	};
}

export const TOGGLE_ATTENDEE_IN_PERSON_MODE = "TOGGLE_ATTENDEE_IN_PERSON_MODE";
export function toggleAttendeeInPersonMode(isEnabled: boolean): Action {
	return {
		type: TOGGLE_ATTENDEE_IN_PERSON_MODE,
		payload: {
			isEnabled
		}
	};
}

interface IValidSessionsUpdate {
	validSessions: number[];
	validPasscodeLists?: number[];
	sessions: Session[];
	registration_bypass?: boolean;
}

export const UPDATE_VALID_SESSIONS = "UPDATE_VALID_SESSIONS";
export function updateValidSessions(data: IValidSessionsUpdate): Action {
	return {
		type: UPDATE_VALID_SESSIONS,
		payload: data
	};
}

export const UPDATE_LOADING_VALID_SESSIONS = "UPDATE_LOADING_VALID_SESSIONS";
export function updateLoadingValidSessions(loading: boolean): Action {
	return {
		type: UPDATE_LOADING_VALID_SESSIONS,
		payload: loading
	};
}

export const SET_ERROR_BOUNDARY_COMPONENTS = "SET_ERROR_BOUNDARY_COMPONENTS";
export function addErrorBoundaryComponent(components: IMultiSelectOption): Action {
	return {
		type: SET_ERROR_BOUNDARY_COMPONENTS,
		payload: components
	};
}

export const FORCE_COMPONENT_ERROR = "FORCE_COMPONENT_ERROR";
export function forceComponentError(id: string): Action {
	return {
		type: FORCE_COMPONENT_ERROR,
		payload: id
	};
}

export const CLEAR_ERROR_BOUNDARY_COMPONENTS = "CLEAR_ERROR_BOUNDARY_COMPONENTS";
export function clearErrorBoundaryComponents(): Action {
	return {
		type: CLEAR_ERROR_BOUNDARY_COMPONENTS,
		payload: null
	};
}

export const SET_TRANSLATION_UPDATES = "SET_TRANSLATION_UPDATES";
export function setTranslationUpdates(updateNum: number): Action {
	return {
		type: SET_TRANSLATION_UPDATES,
		payload: updateNum
	};
}

export const UPDATE_SESSION_CUSTOM_URL = "UPDATE_SESSION_CUSTOM_URL";
export function updateSessionCustomUrl(sessionUuid: string, enabled: boolean, customUrl: string): Action {
	return {
		type: UPDATE_SESSION_CUSTOM_URL,
		payload: { sessionUuid, enabled, customUrl }
	};
}

export const SEARCH_LEADERBOARD = "SEARCH_LEADERBOARD";
export function searchLeaderboard(
	token: string,
	eventUUID: string,
	paginationLimit: number,
	paginationOffset: number,
	searchCriteria: string,
): AsyncAction {
	return {
		type: SEARCH_LEADERBOARD,
		promise: SearchLeaderboard(token, eventUUID, paginationLimit, paginationOffset, searchCriteria)
	};
}

export const LOAD_LEADERBOARD_PROFILE_PAGINATED = "LOAD_LEADERBOARD_PROFILE_PAGINATED";
export function loadLeaderboardProfilePaginated(
	token: string,
	eventUuid: string,
	paginationLimit: number,
	profileUuid: string
): AsyncAction {
	return {
		type: LOAD_LEADERBOARD_PROFILE_PAGINATED,
		promise: GetLeaderboardForUser(token, eventUuid, paginationLimit, profileUuid)
	};
}

export const GET_LEADERBOARD_DATA_FOR_PROFILE = "GET_LEADERBOARD_DATA_FOR_PROFILE";
export function getLeaderboardDataForProfile(
	token: string,
	eventUUID: string,
	blProfileUUID: string
): AsyncAction {
	return {
		type: GET_LEADERBOARD_DATA_FOR_PROFILE,
		promise: GetLeaderboardDataForProfile(token, eventUUID, blProfileUUID)
	};
}

export const CLEAR_LEADERBOARD_DATA_FOR_PROFILE = "CLEAR_LEADERBOARD_DATA_FOR_PROFILE";
export function clearLeaderboardDataForProfile(): Action {
	return {
		type: CLEAR_LEADERBOARD_DATA_FOR_PROFILE,
		payload: null,
	};
}

export const SET_LEADERBOARD_IS_ON = "SET_LEADERBOARD_IS_ON";
export function setLeaderboardIsOn(isOn: boolean): Action {
	return {
		type: SET_LEADERBOARD_IS_ON,
		payload: isOn
	};
}

export const GET_MARKETING_EMAIL_SELECTION = "GET_MARKETING_EMAIL_SELECTION";
export function getMarketingEmailSelection(
	token: string,
	eventUUID: string,
	blProfile: number
): AsyncAction {
	return {
		type: GET_MARKETING_EMAIL_SELECTION,
		promise: GetMarketingEmailSelection(token, eventUUID, blProfile)
	};
}

export const GET_SESSION_USER_SURVEY_RATING = "GET_SESSION_USER_SURVEY_RATING";
export function getSessionUserSurveyRating(sessionUuid: string, userProfileUuid: string, token: string): AsyncAction {
	return {
		type: GET_SESSION_USER_SURVEY_RATING,
		promise: GetUserSurveySessionRating(sessionUuid, userProfileUuid, token)
	};
}

export const SET_SESSION_USER_SURVEY_RATING = "SET_SESSION_USER_SURVEY_RATING";
export function setSessionUserSurveyRating(sessionUuid: string, rating: number): Action {
	return {
		type: SET_SESSION_USER_SURVEY_RATING,
		payload: { sessionUuid, rating }
	};
}

export const FORCE_RECHECK_COOKIE_STATUS = "FORCE_RECHECK_COOKIE_STATUS";
export function forceRecheckCookieStatus(): Action {
	return {
		type: FORCE_RECHECK_COOKIE_STATUS,
		payload: null,
	};
}

export const CONFIRM_GDPR_COOKIES = "CONFIRM_GDPR_COOKIES";
export function confirmGDPRCookies(token: string, registrationUUID: string, data: IRegistrationGDPR): AsyncAction {
	return {
		type: CONFIRM_GDPR_COOKIES,
		promise: ConfirmGDPRCookies(token, registrationUUID, data),
	};
}

export const CLEAR_REGISTRATION_ERROR = "CLEAR_REGISTRATION_ERROR";
export function clearRegistrationError(): Action {
	return {
		type: CLEAR_REGISTRATION_ERROR,
		payload: null,
	};
}

export const TOGGLE_GOOGLE_MEET_EMBED_VIEW = "TOGGLE_GOOGLE_MEET_EMBED_VIEW";
export function toggleGoogleMeetEmbedView(room: string | null): Action {
	return {
		type: TOGGLE_GOOGLE_MEET_EMBED_VIEW,
		payload: room
	};
}

export const SET_LIVE_EVENT_SESSION_PLAYER_TYPE = "SET_LIVE_EVENT_SESSION_PLAYER_TYPE";
export function setLiveEventSessionPlayerType(playerType: string): Action {
	return {
		type: SET_LIVE_EVENT_SESSION_PLAYER_TYPE,
		payload: playerType
	};
}

export const SET_SHOW_SESSION_USER_FEEDBACK_SURVEY_MODAL = 'SET_SHOW_SESSION_USER_FEEDBACK_SURVEY_MODAL';
export function setShowSessionUserFeedbackSurveyModal(sessionUuid: string | null): Action {
	return {
		type: SET_SHOW_SESSION_USER_FEEDBACK_SURVEY_MODAL,
		payload: sessionUuid
	};
}

export const ACKNOWLEDGE_POPUP_ANNOUNCEMENT_V2 = 'ACKNOWLEDGE_POPUP_ANNOUNCEMENT_V2';
export function acknowledgePopupAnnouncementV2(announcementId: number): Action {
	return {
		type: ACKNOWLEDGE_POPUP_ANNOUNCEMENT_V2,
		payload: announcementId
	};
}

export const GET_DB_ONDEMAND_WATCHTIME = 'GET_DB_ONDEMAND_WATCHTIME';
export function getDBOndemandWatchtime(sessionId: number, video_source: string): AsyncAction {
	return {
		type: GET_DB_ONDEMAND_WATCHTIME,
		promise: db.getOnDemandWatchTime(sessionId, video_source),
	};
}

export const SET_DB_ONDEMAND_WATCHTIME = 'SET_DB_ONDEMAND_WATCHTIME';
export function setDBOndemandWatchtime(sessionId: number, video_source: string, watchtime: number, duration: number): AsyncAction {
	return {
		type: GET_DB_ONDEMAND_WATCHTIME,
		promise: db.setOnDemandWatchTime(sessionId, video_source, watchtime, duration),
	};
}

export const SET_LIVE_STATES = "SET_LIVE_STATES";
export function setLiveStates(sessionUuid: string): AsyncAction {
	return {
		type: SET_LIVE_STATES,
		promise: GetSessionLiveState(sessionUuid)
	};
}

export const LIVE_STATE_CHANGE = "LIVE_STATE_CHANGE";
export function liveStageChange(liveStageChange: LiveStateChangePayload): Action {
	return {
		type: LIVE_STATE_CHANGE,
		payload: liveStageChange
	};
}

export const SET_EVENT_NAV_OPEN = "SET_EVENT_NAV_OPEN";
export function setEventNavOpen(open: boolean): Action {
	return {
		type: SET_EVENT_NAV_OPEN,
		payload: open
	};
}

export const SET_EVENT_BUNDLE = "SET_EVENT_BUNDLE";
export function setEventBundle(eventBundle: BrandliveEvent): Action {
	return {
		type: SET_EVENT_BUNDLE,
		payload: eventBundle
	};
}

export const SET_VIEWING_SESSION_UUID = "SET_VIEWING_SESSION_UUID";
export function setViewingSessionUuid(sessionUuid: string | null): Action {
	return {
		type: SET_VIEWING_SESSION_UUID,
		payload: sessionUuid
	};
}

export const SET_REGISTRATION_ERROR = "SET_REGISTRATION_ERROR";
export function setRegistrationError(message: RegFailureReasons): Action {
	return {
		type: SET_REGISTRATION_ERROR,
		payload: message
	};
}