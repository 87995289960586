import { useState, useRef, useEffect } from 'react';
import { BrandliveEvent, PageModuleType, Session } from 'types/working-model';
import surveyResultsListenerService from 'utils/survey-results-listener-service';

import {
	useFiresidesHostToken,
	useGetSession,
	useIsAboveTheFold,
	useIsBelowTheFold,
	useIsNewModuleGrouping,
} from "../../../../hooks/session.hooks";
import { useAppDispatch, useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import { EditorSizes } from "../../../../types/template-layouts";
import { FiresideSessionSettings } from 'types/working-model';
import { getGoogleMeetFsSettings } from 'store/actions/event/firesides-actions';

const ABOVE_THE_FOLD_CONTROL_BUTTONS = { editColors: false, addContent: true, changeSectionPosition: false };
const BELOW_THE_FOLD_CONTROL_BUTTONS = { editColors: false, addContent: true, changeSectionPosition: true };

type TUseGetControlButtons = (isOverview?: boolean) => { editColors: boolean, addContent: boolean, changeSectionPosition: boolean } | undefined;

export const useGetControlButtons: TUseGetControlButtons = (isOverview) => {
	const isModuleGroupingV2 = useIsNewModuleGrouping();
	const session = useGetSession();
	const isAboveTheFold = useIsAboveTheFold(session);
	const isBelowTheFold = useIsBelowTheFold(session);
	const editorSize = useTypedSelector(state => state.CreateEventReducer.editorSize);
	const isDesktop = editorSize === EditorSizes.desktop;

	if (isOverview) {
		return ABOVE_THE_FOLD_CONTROL_BUTTONS;
	}

	return isModuleGroupingV2 && isAboveTheFold && isDesktop
		? ABOVE_THE_FOLD_CONTROL_BUTTONS
		: isModuleGroupingV2 && (isBelowTheFold || isAboveTheFold && !isDesktop)
			? BELOW_THE_FOLD_CONTROL_BUTTONS
			: undefined;
};

type WordWidthCacheType = { [word: string]: number };

export const getTextWidths = (text: string, textElement: HTMLElement): number[] => {
	const ctx = document.createElement('canvas').getContext('2d');
	if (!ctx) return [];
	const wordWidthCache: Record<string, number> = {};
	const words = text.split(' ');
	const containerStyle = getComputedStyle(textElement);
	ctx.font = `${containerStyle.fontStyle} ${containerStyle.fontWeight} ${containerStyle.fontSize} ${containerStyle.fontFamily}`;

	return words.map(word => {
		if (wordWidthCache[word] !== undefined) {
			return wordWidthCache[word];
		} else {
			const metrics = ctx.measureText(word);
			const width = metrics.width;
			wordWidthCache[word] = width;
			return width;
		}
	});
};

export const useTextLinesCounter = (textToCheck: string) => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [linesCount, setLinesCount] = useState<number>(0);
	const wordWidthCache = useRef<WordWidthCacheType>({});

	const measureTextWidth = (textElement: Element, words: string[]): number[] => {
		const ctx = document.createElement('canvas').getContext('2d');
		if (!ctx) return [];

		const containerStyle = getComputedStyle(textElement);
		ctx.font = `${containerStyle.fontStyle} ${containerStyle.fontWeight} ${containerStyle.fontSize} ${containerStyle.fontFamily}`;

		return words.map(word => {
			if (wordWidthCache.current[word] !== undefined) {
				return wordWidthCache.current[word];
			} else {
				const metrics = ctx.measureText(word);
				const width = metrics.width;
				wordWidthCache.current[word] = width;
				return width;
			}
		});
	};

	useEffect(() => {
		if (!containerRef.current) return;

		const words = textToCheck.split(' ');
		const containerWidth = containerRef.current.clientWidth;
		const adminTextElement = containerRef.current.querySelector('.ql-editor')?.firstElementChild;
		const userTextElement = containerRef.current.querySelector('.session-description')?.firstElementChild;
		const textElement = adminTextElement || userTextElement;

		if (!textElement) return;

		const wordWidths = measureTextWidth(textElement, words);

		const result = wordWidths.reduce(({ currentLineWidth, linesCount }, wordWidth) => {
			if (currentLineWidth + wordWidth <= containerWidth) {
				return {
					currentLineWidth: currentLineWidth + wordWidth,
					linesCount
				};
			} else {
				return {
					currentLineWidth: wordWidth,
					linesCount: linesCount + 1
				};
			}
		}, { currentLineWidth: 0, linesCount: 1 });

		setLinesCount(result.linesCount);

	}, [textToCheck]);

	return { containerRef, linesCount };
};

type UseGoogleMeetFiresideSettingsProps = {
	session?: Session;
	settings: FiresideSessionSettings | undefined;
	blUserToken: string | undefined;
};
export const useGoogleMeetFiresideSettings =
	({ session, settings, blUserToken }: UseGoogleMeetFiresideSettingsProps):
		[FiresideSessionSettings | null | undefined, string | undefined] => {
		const fsSettings = useTypedSelector(state => state.FiresidesReducer.firesideSessionSettings);
		const [hostToken, ready] = useFiresidesHostToken(session);
		const dispatch = useAppDispatch();
		const hasFsSettings = useRef<boolean>(false);
		const sessionUuid = session?.uuid;

		useEffect(() => {
			if (!hasFsSettings.current && settings && blUserToken && ready && sessionUuid) {
				hasFsSettings.current = true;
				dispatch(getGoogleMeetFsSettings(sessionUuid, settings.settings?.google_meet_use_service_account, blUserToken));
			}
		}, [blUserToken, sessionUuid, settings, ready, dispatch]);

		return [fsSettings, hostToken];
	};

export const useSurveyPollQuizResults = (session: Session | undefined) => {
	const blProfileUserToken = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const event = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const [engagementIdString, setEngagementIdString] = useState('');
	const lastEngagementIdString = useRef<string>('');
	const hasLoaded = useRef<boolean>(false);
	const eventUuid = event?.uuid;
	const eventId = event?.event;

	useEffect(() => {
		// get ids of modules within enabled module groups
		const modulesInGroup = session?.module_grouping?.reduce<number[]>((ids, group) => {
			if (group.is_on) {
				ids = [...ids, ...group.modules];
			}

			return ids;
		}, []) ?? [];

		// get ids of enabled modules
		const modules = session?.modules.reduce<number[]>((ids, module) => {
			if (module.is_on && module.id && modulesInGroup.includes(module.id)) {
				ids = [...ids, module.id];
			}

			return ids;
		}, []) ?? [];

		const str = modules.join(',');

		// set as string so we can easily track survey changes
		setEngagementIdString(str);

		return () => {
			// keep ref to last string so we can more accurately track when things are added or removed
			lastEngagementIdString.current = str;
		};
	}, [session]);

	useEffect(() => {
		// if there are any engagements and we have never attempted to load them...
		if (engagementIdString.length && !hasLoaded.current) {
			surveyResultsListenerService.in(eventUuid, eventId, blProfileUserToken);
		}

		// there are any engagments AND we have loaded these previously
		else if (engagementIdString.length && engagementIdString !== lastEngagementIdString.current && hasLoaded.current) {
			surveyResultsListenerService.refresh();
		}

		// we previously loaded engagements and now there are none
		else if (!engagementIdString.length && hasLoaded.current) {
			surveyResultsListenerService.out();
		}
	}, [engagementIdString, eventUuid, eventId, blProfileUserToken]);

	useEffect(() => {
		return () => {
			surveyResultsListenerService.out();
		};
	}, []);
};
