import classNames from "classnames";
import { lazy, Suspense, useContext, useMemo } from "react";

import { setShowSessionUserFeedbackSurveyModal } from "../../../../../store/actions/event/event-actions";
import { OptionalComponent } from "../../../../../utils/optional-component";
import { ICONS } from "../../../../general-ui/icon";
import { StreamsActionButton } from "../../session-stream/video/components/streams-action-button";
import { handleCopyUrl } from "../session-details-modal/modal-social-buttons";
import * as Signals from '../../../../../utils/event-emitter';
import { BrandliveEvent, Session, SessionTypesEnum } from "../../../../../types/working-model";
import { useAppDispatch, useTypedSelector } from "../../../../../store/reducers/use-typed-selector";
import { displayAddToCalendarButton, getTemplateClassName } from "../../../../../utils/utils";
import { useMyListActive } from "../../session-stream/video/utils";
import { useTranslate } from "../../../../../i18n/useTranslationModules";
import { nonBubbling } from "../../../../../utils/non-bubbling-click-handler";
import useShowMyList from "hooks/show-my-list.hooks";
import { ThemeContext } from "components/live-event/theme-context";
import { EPaletteModes } from "types/theme-packs";
const FiresideHostControls = lazy(() => import("../../fireside/session-host"));

type ActionBarProps = {
	template: string;
	session: Session;
	event: BrandliveEvent;
	className: string;
	includePlay?: boolean;
	ended?: boolean;
	forceDark?: boolean;
	onClickPlay?: () => void;
	isFullscreen?: boolean;
	includeJoinStage?: boolean;
}
const ActionBar: React.FC<ActionBarProps> = ({
	session,
	event,
	template,
	className,
	includePlay,
	ended,
	forceDark,
	onClickPlay,
	isFullscreen,
	includeJoinStage
}) => {
	const { t } = useTranslate(["session", "homepage"]);
	const dispatch = useAppDispatch();
	const [
		,
		inMyList,
		toggleInMyList
	] = useMyListActive(session);
	const isFutureSession = session?.timestamp && session.timestamp > Date.now().valueOf();
	const showAddToCalBtn = event?.settings?.show_add_to_cal_btn;
	const isFireside = session?.session_type === SessionTypesEnum.fireside;
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const attendeeInPersonEnabled = useTypedSelector(state => state.LiveEventReducer.eventBundle?.settings?.attendeeInPersonEnabled);

	const showMyList = useShowMyList();

	const showAddToCalendarButton = useMemo(() => {
		if (!session) return false;

		return displayAddToCalendarButton(session, showAddToCalBtn);
	}, [session, showAddToCalBtn]);

	const [theme] = useContext(ThemeContext);
	const sessionSettingDarkMode = theme === EPaletteModes.Dark;
	const isDarkMode = forceDark || sessionSettingDarkMode;

	return (
		<div className={classNames(className, 'action-bar-container', { 'in-person-attendee-mode': attendeeInPersonEnabled })}>
			{includePlay && (
				<StreamsActionButton
					classNames={''}
					template={template}
					onClick={() => onClickPlay?.()}
					icon={ended ? ICONS.SEEK_START : ICONS.PLAY}
					popupText={ended ? t("session:Restart", 'Restart') : t("session:Play", 'Play')}
					darkIcon={!isDarkMode}
				/>
			)}

			{!isFullscreen && (
				<StreamsActionButton
					classNames=''
					onClick={() => Signals.broadcastSignal('session-details-modal:open')}
					icon={ICONS.INFO_I}
					popupText={t('session:Go to Overview', 'Go to Overview')}
					darkIcon={!isDarkMode}
				/>
			)}

			{(!isFullscreen && showAddToCalendarButton && isFutureSession) && (
				<StreamsActionButton
					classNames=''
					onClick={nonBubbling(() => Signals.broadcastSignal('open-add-to-calendar-modal'))}
					icon={ICONS.CALENDAR_PLUS}
					popupText={t('session:Add to Calendar', 'Add to Calendar')}
					darkIcon={!isDarkMode}
				/>
			)}

			<OptionalComponent display={showMyList}>
				<StreamsActionButton
					classNames=''
					onClick={nonBubbling(toggleInMyList)}
					icon={inMyList ? ICONS.DONE : ICONS.ADD}
					template={template}
					popupText={inMyList ? t('session:Remove from Watchlist', 'Remove from Watchlist') : t('session:Add to Watchlist', 'Add to Watchlist')}
					darkIcon={!isDarkMode}
				/>
			</OptionalComponent>

			{/* LIKE BUTTON FOR FEEDBACK */}
			<OptionalComponent display={!isFullscreen && !isFutureSession && !!session?.enable_feedback_survey}>
				<StreamsActionButton
					classNames={''}
					template={template}
					onClick={nonBubbling(() => dispatch(setShowSessionUserFeedbackSurveyModal(session.uuid)))}
					icon={ICONS.LIKE}
					popupText={t('session:Feedback', 'Feedback')}
					darkIcon={!isDarkMode}
				/>
			</OptionalComponent>

			{/* SHARE, COPY TO CLIPBOARD */}
			<OptionalComponent display={!isFullscreen && !!session?.enable_share}>
				<StreamsActionButton
					classNames={''}
					template={template}
					onClick={nonBubbling(() => handleCopyUrl({
						isDarkMode: sessionSettingDarkMode,
						message: t("homepage:Copied to clipboard", "Copied to clipboard")
					}))}
					icon={ICONS.SHARE}
					popupText={t("session:Share", "Share")}
					darkIcon={!isDarkMode}
				/>
			</OptionalComponent>

			<OptionalComponent display={includeJoinStage && isFireside}>
				<Suspense fallback={null}>
					<FiresideHostControls
						session={session}
						template={getTemplateClassName(template)}
						admin={!!workingSession}
						blockInteraction={!!workingSession}
					/>
				</Suspense>
			</OptionalComponent>
		</div>
	);
};

export default ActionBar;
