import classNames from "classnames";

import { useTranslate } from "../../../../../i18n/useTranslationModules";
import { showAlert } from "../../../../general-ui/alert/alert-service";
import { ICONS } from "../../../../general-ui/icon";
import { StreamsActionButton } from "../../session-stream/video/components/streams-action-button";
import { useGetSession } from "hooks/session.hooks";
import { CustomSocialLinks, ECustomSocialLinks } from "types/working-model";

import "./modal-social-buttons.scss";

function shareOnX(title: string, link: string) {
	return `http://x.com/share?url=${encodeURIComponent(link)}&text=${encodeURIComponent(title)}`;
}

function shareOnFB(title: string, link: string) {
	return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}&t=${encodeURIComponent(title)}`;
}

function shareOnLinkedin(title: string, link: string) {
	return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(link)}&title=${encodeURIComponent(title)}`;
}

export function handleCopyUrl({
	isDarkMode,
	message = "Copied to clipboard"
}: {
	isDarkMode?: boolean;
	message?: string;
}) {
	const eventLink = window.location.href;
	navigator.clipboard.writeText(eventLink);

	showAlert({
		message,
		description: `\`\`\`${eventLink}\`\`\``,
		duration: 3000,
		type: "POSITIVE",
		descriptionStyle: { whiteSpace: "pre-wrap", wordBreak: "break-all" },
		className: classNames('copy-url-alert', { 'light-mode': isDarkMode === false })
	});
}

interface ModalSocialButtonsProps {
	isPreview?: boolean;
	sessionTitle?: string;
	isDarkMode?: boolean;
	// Only in the editor
	setSelectedSocialButtonForEditing?: React.Dispatch<React.SetStateAction<keyof CustomSocialLinks | null>>;
}

export default function ModalSocialButtons({ sessionTitle, isDarkMode, setSelectedSocialButtonForEditing }: ModalSocialButtonsProps): JSX.Element {
	const { t } = useTranslate(["session", "homepage"]);
	const session = useGetSession();

	const customSocialLinks = session?.layout.custom_social_links;

	const eventLink = window.location.href;

	const SHARE_BUTTONS_CONFIG = [
		{
			id: 0,
			icon: ICONS.X_SOCIAL,
			title: ECustomSocialLinks.X,
			url: customSocialLinks?.X || shareOnX(sessionTitle || '', eventLink),
			size: 20
		},
		{
			id: 1,
			icon: ICONS.LINKEDIN,
			title: ECustomSocialLinks.LinkedIn,
			url: customSocialLinks?.LinkedIn || shareOnLinkedin(sessionTitle || '', eventLink),
			size: 18
		},
		{
			id: 2,
			icon: ICONS.FACEBOOK,
			title: ECustomSocialLinks.Facebook,
			url: customSocialLinks?.Facebook || shareOnFB(sessionTitle || '', eventLink),
			size: 18
		},
		{
			id: 3,
			icon: ICONS.HYPERLINK,
			title: ECustomSocialLinks.Share,
			url: customSocialLinks?.Share || null,
			size: 20
		}
	];

	return (
		<ul className="session-socials">
			{SHARE_BUTTONS_CONFIG.map(({ id, icon, url, title, size }) => (
				<li key={id}>
					{url ?
						<StreamsActionButton
							classNames=''
							url={setSelectedSocialButtonForEditing ? undefined : url}
							onClick={setSelectedSocialButtonForEditing ? () => setSelectedSocialButtonForEditing(title) : () => null}
							icon={icon}
							popupText={t(`session:${title || ''}`, title)}
							darkIcon={!isDarkMode}
							size={size}
							editable
						/> :
						<StreamsActionButton
							classNames=''
							onClick={() => setSelectedSocialButtonForEditing ? setSelectedSocialButtonForEditing(title) : handleCopyUrl({
								isDarkMode,
								message: t("homepage:Copied to clipboard", "Copied to clipboard")
							})}
							icon={icon}
							popupText={t(`session:${title || ''}`, title)}
							darkIcon={!isDarkMode}
							size={size}
							editable
						/>
					}
				</li>
			))}
		</ul>
	);
}
