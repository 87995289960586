import { BlProfile, IModeratorProfile, IQuestionSocket, ISocketCommentProps, LanguagesAbbr, TextAnswer, PaginatedQuestion, BanChatResults } from "../types/working-model";
import { Delete, Get, GetJson, Post, Put } from "./helpers";

export const FetchModeratorChatMessages = ({
	token,
	session_uuid,
	language,
	lastFetchId,
	limit = 0, // pass zero to fetch ALL
}: {
	token: string;
	session_uuid: string;
	language: LanguagesAbbr;
	lastFetchId: number | null;
	limit?: number;
}): Promise<any> => {
	const params = new URLSearchParams();

	if (lastFetchId) {
		params.set('from_id', String(lastFetchId));
	}

	if (limit) {
		params.set('limit', String(limit));
	} else {
		params.set('limit', '20');
	}

	return Get(`/v3/admin/moderator/chat/${session_uuid}/${language}?${params.toString()}`, token);
};

export const FetchQuestionsAndQuestionComments = ({
	token,
	session_uuid,
	language,
}: {
	token: string;
	session_uuid: string;
	language: LanguagesAbbr;
}): Promise<any> => {
	return Get(`/v3/admin/moderator/questions/${session_uuid}/${language}`, token);
};

export const ApproveChatMessage = (token: string, session_uuid: string, message: ISocketCommentProps | IQuestionSocket): Promise<any> => {
	return Post(`/v3/admin/moderator/chat/approve/${session_uuid}/${message.language}/${message.id}`, token, message);
};

export const UnapproveChatMessage = (token: string, session_uuid: string, message: ISocketCommentProps): Promise<any> => {
	return Post(`/v3/admin/moderator/chat/unapprove/${session_uuid}/${message.language}/${message.id}`, token, message);
};

export const ToggleSaveChatMessage = (token: string, session_uuid: string, message: ISocketCommentProps): Promise<any> => {
	return Post(`/v3/admin/moderator/chat/toggle-save/${session_uuid}/${message.language}/${message.id}`, token, message);
};

export const DeleteChatMessage = (token: string, session_uuid: string, language: string, message_id: number): Promise<any> => {
	return Delete(`/v3/admin/moderator/chat/${session_uuid}/${language}/${message_id}`, token);
};

export const UndeleteChatMessage = (token: string, session_uuid: string, language: string, message_id: number): Promise<any> => {
	return Post(`/v3/admin/moderator/chat/undelete/${session_uuid}/${language}/${message_id}`, token, {});
};

export const FetchModerationSettings = (token: string, event_uuid: string, sessionUuid: string, language: string): Promise<any> => {
	return Get(`/v3/admin/moderator/settings/${event_uuid}/${sessionUuid}/${language}`, token);
};

export const SaveCustomBadWords = (
	token: string,
	event_uuid: string,
	session_uuid: string,
	language: string,
	data: { customWords: { label: string; value: string; }[], shouldBlockWords: boolean },
): Promise<any> => {
	return Post(`/v3/admin/moderator/custom-bad-words/${event_uuid}/${session_uuid}/${language}`, token, data);
};

export const ApproveQuestion = (token: string, session_uuid: string, message: IQuestionSocket | PaginatedQuestion): Promise<any> => {
	return Post(`/v3/admin/moderator/question/approve/${session_uuid}/${message.language}/${message.id}`, token, message);
};

export const MarkQuestionsAsAnswered = ({
	token,
	session_uuid,
	language,
	question_ids,
	answered
}: {
	token: string,
	session_uuid: string,
	language: string,
	question_ids: number[],
	answered: boolean
}) => {
	return Put(`/v3/admin/moderator/question/answered/${session_uuid}/${language}`, token, { answered, question_ids });
};

export const UnapproveQuestion = (token: string, session_uuid: string, message: IQuestionSocket | PaginatedQuestion): Promise<any> => {
	return Post(`/v3/admin/moderator/question/unapprove/${session_uuid}/${message.language}/${message.id}`, token, message);
};

export const DeleteQuestion = (token: string, session_uuid: string, language: string, message_id: number): Promise<any> => {
	return Delete(`/v3/admin/moderator/question/${session_uuid}/${language}/${message_id}`, token);
};

export const UndeleteQuestion = (token: string, session_uuid: string, language: string, message_id: number): Promise<any> => {
	return Post(`/v3/admin/moderator/question/undelete/${session_uuid}/${language}/${message_id}`, token, {});
};

export const ApproveQuestionComment = (token: string, session_uuid: string, message: IQuestionSocket): Promise<any> => {
	return Post(`/v3/admin/moderator/question-comment/approve/${session_uuid}/${message.language}/${message.id}`, token, message);
};

export const UnapproveQuestionComment = (token: string, session_uuid: string, message: IQuestionSocket): Promise<any> => {
	return Post(`/v3/admin/moderator/question-comment/unapprove/${session_uuid}/${message.language}/${message.id}`, token, message);
};

export const DeleteQuestionComment = (token: string, session_uuid: string, language: string, message_id: number): Promise<any> => {
	return Delete(`/v3/admin/moderator/question-comment/${session_uuid}/${language}/${message_id}`, token);
};

export const UndeleteQuestionComment = (token: string, session_uuid: string, language: string, message_id: number): Promise<any> => {
	return Post(`/v3/admin/moderator/question-comment/undelete/${session_uuid}/${language}/${message_id}`, token, {});
};

export const GetModeratorVideo = (token: string, sessionUuid: string, language: string): Promise<{ live_video?: any, on_demand_video?: any }> => {
	return Get(`/v3/admin/moderator/video/${sessionUuid}/${language}`, token);
};

export const FetchModeratorEventsData = (token: string, event_uuid: string): Promise<any> => {
	return Get(`/v3/admin/moderator/channel-events/${event_uuid}`, token);
};

export const ModReplyToQuestion = (token: string, session_uuid: string, data: { [key: string]: any }): Promise<any> => {
	return Post(`/v3/admin/moderator/reply/question/${session_uuid}`, token, data);
};

export const ModReplyToQuestionComment = (token: string, session_uuid: string, data: { [key: string]: any }): Promise<any> => {
	return Post(`/v3/admin/moderator/reply/question-comment/${session_uuid}`, token, data);
};

export const SendToPresenter = (token: string, session_uuid: string, language: string, message_id: number, message_type: string): Promise<any> => {
	return Post(`/v3/admin/moderator/presenter/send/${session_uuid}/${language}/${message_id}/${message_type}`, token, {});
};

export const RemoveFromPresenter = (token: string, session_uuid: string, language: string, message_id: number, message_type: string): Promise<any> => {
	return Post(`/v3/admin/moderator/presenter/remove/${session_uuid}/${language}/${message_id}/${message_type}`, token, {});
};

// this is for session chat NOT event chat
export const BanChatUser = (token: string, sessionUUID: string, blProfile: number | null, blProfileNonReg: number | null, moderator: number): Promise<any> => {
	return Post(`/v3/admin/moderator/ban-user`, token, { sessionUUID, blProfile, blProfileNonReg, moderator });
};

export const UnbanChatUser = (token: string, sessionUUID: string, eventUUID: string, blProfile: number | null, blProfileNonReg: number | null, moderator: number): Promise<any> => {
	return Post(`/v3/admin/moderator/unban-user`, token, { sessionUUID, eventUUID, blProfile, blProfileNonReg, moderator });
};

export const UpdateModeratorProfile = (
	moderatorProfile: IModeratorProfile,
	profileId: number,
	channel: number,
	blProfileUserToken: string,
	adminToken: string,
	eventUUID: string,
): Promise<BlProfile> => {
	return Post(`/v3/admin/moderator/profile/${channel}/${profileId}/${eventUUID}`, adminToken, { moderatorProfile }, false, { 'bl-profile-token': blProfileUserToken });
};

interface GenerateModeratorBypassLinkResponse {
	link?: string
	error?: string;
}

export const generateModeratorBypassLink = (token: string, regUuid: string, eventUuid: string, channelUrl: string, eventUrl: string, defaultLanguage: string): Promise<GenerateModeratorBypassLinkResponse> => {
	return Post(`/v3/admin/moderator/event/join-event`, token, {
		regUuid,
		eventUuid,
		channelUrl,
		eventUrl,
		defaultLanguage
	});
};

type GetSurveyTextAnswersProps = {
	token: string,
	sessionUuid: string,
	channel: number,
	question_id: number,
	limit?: number
}
export const getSurveyTextAnswers = ({
	token,
	sessionUuid,
	channel,
	question_id,
	limit
}: GetSurveyTextAnswersProps) => {
	const params = new URLSearchParams();
	if (limit) {
		params.set('limit', String(limit));
	}
	return GetJson<{ total: number, results: TextAnswer[] }>({
		path: `/v3/admin/moderator/surveys/${channel}/${sessionUuid}/text-responses/${question_id}?${params.toString()}`,
		token
	});
};

export const GetBannedUsersForEvent = ({
	token,
	event_uuid,
	channel
}: {
	token: string,
	event_uuid: string,
	channel: number
}) => {
	return GetJson<BanChatResults[]>({
		path: `/v3/admin/moderator/banned-users/${channel}/${event_uuid}`,
		token
	});
};
