import { useCallback } from "react";
import { generatePath } from "react-router";
import { useParams } from "react-router-dom";
import { NavMainItems, CustomPage, NavProfileItems, PageModuleGroupModules, TranslateString } from "../types/working-model";

const sessionBaseRoute = "/admin/events/create/:id/session/:session/:language";
export const sessionPanelPathPattern = `${sessionBaseRoute}/content/:tabType/:customPath/:moduleKey/:page_module/:page_module_edit`;
export const sessionPanelCustomPathPattern = `${sessionBaseRoute}/content/:tabType/:customPath`;

// single source of truth for all routes
export const PATHNAMES = {
	Dashboard: "/admin",
	Events: "/admin/events",
	SelectEvent: "/admin/events/:id/:tab?",
	DetailsEventLink: (id: string): string => `/admin/events/${id}/audience-lists`,
	Analytics: "/admin/analytics/:tab?",
	AnalyticsLink: "/admin/analytics",
	People: "/admin/people",
	Content: "/admin/content",
	ContentSubLevels: {
		Documents: {
			All: "/admin/content/documents/all",
			Pdf: "/admin/content/documents/pdf",
			Word: "/admin/content/documents/word",
			Excel: "/admin/content/documents/excel",
			Powerpoint: "/admin/content/documents/powerpoint",
			Misc: "/admin/content/documents/misc"
		},
		Images: "/admin/content/images",
		Videos: {
			Recorded: "/admin/content/videos/recorded",
			Uploaded: "/admin/content/videos/uploaded",
			Archive: "/admin/content/videos/archive"
		},
		Fonts: "/admin/content/fonts",
		Palettes: "/admin/content/palettes",
		Themes: "/admin/content/themes",
		Templates: "/admin/content/templates",
	},
	Products: "/admin/products",
	Settings: "/admin/settings/:tab?",
	SettingsLink: "/admin/settings",
	AdminProfile: "/admin/profile",
	WhatsNew: "/admin/whats-new",
	Support: "https://help.brand.live/hc/en-us", //external link

	/*** Begin create event flow ***/
	CreateEventFlowEntryPoint: "/admin/events/create-flow",
	// create from default themes
	CreateEventFromTheme: "/admin/events/create-new/event/templates",
	CreateEventFromThemeInfo: "/admin/events/create-new/event/templates/:template",
	CreateEventFromThemeInfoLink: (template: number) => `/admin/events/create-new/event/templates/${template}`,
	// create from template events
	CreateEventFromTemplateEvent: "/admin/events/create-new/templates",
	CreateEventTemplateInfo: "/admin/events/create-new/templates/:templateUuid",
	CreateEventTemplateInfoLink: (templateUuid: string) => `/admin/events/create-new/templates/${templateUuid}`,
	/*** End create event flow ***/

	EventBasePath: "/admin/events/create/:id",
	AudienceList: {
		AudienceList: "/admin/events/create/:id/registration/:language/audience-list/:audienceID",
		AudienceListLink: (id: string, language: string, audienceID: string): string => `/admin/events/create/${id}/registration/${language}/audience-list/${audienceID}`
	},
	Event: {
		Landing: "/admin/events/create/:id/landing/:language?",
		LandingLink: (id: string, language?: string): string => `/admin/events/create/${id}/landing/${language || ""}`,
		Registration: "/admin/events/create/:id/registration/:language/:tab?",
		RegistrationLink: (id: string, language: string, tab?: string): string => `/admin/events/create/${id}/registration/${language}/${tab || ""}`,
		Availability: "/admin/events/create/:id/registration/:language/settings/availability",
		AvailabilityLink: (id: string, language: string): string => `/admin/events/create/${id}/registration/${language}/settings/availability`,
		Ticketing: "/admin/events/create/:id/registration/:language/settings/ticketing",
		TicketingLink: (id: string, language: string): string => `/admin/events/create/${id}/registration/${language}/settings/ticketing`,
		SSORegistrationSettings: "/admin/events/create/:id/registration/:language/settings/sso",
		SSORegistrationSettingsLink: (id: string, language: string): string => `/admin/events/create/${id}/registration/${language}/settings/sso`,
		Event: "/admin/events/create/:id/event/:language?",
		EventLink: (id: string, language?: string): string => `/admin/events/create/${id}/event/${language}`,
		EmailConfirmation: "/admin/events/create/:id/registration/:language/settings/email-confirmation",
		EmailConfirmationLink: (id: string, language: string): string => `/admin/events/create/${id}/registration/${language}/settings/email-confirmation`,
		// the following NavMainItems and NavProfileItems paths correspond to mapped menu items in the event panel
		HomePath: "/admin/events/create/:id/event/:language/home",
		[NavMainItems.Home]: (id: string, language: string): string => `/admin/events/create/${id}/event/${language}/home`,
		SessionsPath: "/admin/events/create/:id/event/:language/sessions/:tab?",
		[NavMainItems.Sessions]: (id: string, language: string, tab?: string): string => `/admin/events/create/${id}/event/${language}/sessions/${tab || ""}`,
		LeaderboardPath: "/admin/events/create/:id/social/:language/leaderboard",
		[NavMainItems.Leaderboard]: (id: string, language: string): string => `/admin/events/create/${id}/social/${language}/leaderboard`,
		// PeoplePath: "/admin/events/create/:id/event/people",
		// [NavMainItems.People]: (id: string): string => `/admin/events/create/${id}/event/people`,
		// the remaining NavMainItems paths are not included in mvp so they are just placeholders to make typescript happy
		[NavMainItems.TellyClub]: (id: string): string => `/admin/events/create/${id}/event`,
		[NavMainItems.Notifications]: (id: string): string => `/admin/events/create/${id}/event`,
		[NavMainItems.Catalog]: (id: string): string => `/admin/events/create/${id}/event`,
		TermsConditionsPath: "/admin/events/create/:id/event/:language/terms-conditions",
		[NavProfileItems.TermsAndConditions]: (id: string, language: string): string => `/admin/events/create/${id}/event/${language}/terms-conditions`,
		PrivacyPolicyPath: "/admin/events/create/:id/event/:language/privacy-policy",
		[NavProfileItems.PrivacyPolicy]: (id: string, language: string): string => `/admin/events/create/${id}/event/${language}/privacy-policy`,
		GDPRSettingsPath: "/admin/events/create/:id/event/:language/gdpr",
		[NavProfileItems.GDPRSettings]: (id: string, language: string): string => `/admin/events/create/${id}/event/${language}/gdpr`,
		// the remaining NavProfileItem path is not included in mvp so it is just a placeholder to make typescript happy
		[NavProfileItems.Profile]: (id: string): string => `/admin/events/create/${id}/event`,
		// conditional routing for theme so the panel can change but the editor view remains


		Theme: "/admin/events/create/:id/event/:language/theme",

		ThemePackEdit: "/admin/events/create/:id/event/:language/theme/packs/:pack",
		ThemePackEditLink: (id: string, language: string, pack: number): string => `/admin/events/create/${id}/event/${language}/theme/packs/${pack}`,
		ThemePackFont: "/admin/events/create/:id/event/:language/theme/packs/:pack/font",
		ThemePackFontLink: (id: string, language: string, pack: number): string => `/admin/events/create/${id}/event/${language}/theme/packs/${pack}/font`,
		ThemePackAddFont: "/admin/events/create/:id/event/:language/theme/packs/:pack/font/new",
		ThemePackAddFontLink: (id: string, language: string, pack: number): string => `/admin/events/create/${id}/event/${language}/theme/packs/${pack}/font/new`,
		ThemePackBorderRadius: "/admin/events/create/:id/event/:language/theme/packs/:pack/border-radius",
		ThemePackBorderRadiusLink: (id: string, language: string, pack: number): string => `/admin/events/create/${id}/event/${language}/theme/packs/${pack}/border-radius`,

		ThemePackAutoGenerate: "/admin/events/create/:id/event/:language/theme/packs/auto-generate",
		ThemePackAutoGenerateLink: (id: string, language: string): string => `/admin/events/create/${id}/event/${language}/theme/packs/auto-generate`,
		ThemePackAutoGenerateSelectPalette: "/admin/events/create/:id/event/:language/theme/packs/auto-generate/:hex/:opacity/palettes",
		ThemePackAutoGenerateSelectPaletteLink: (id: string, language: string, hex: string, opacity: string): string => `/admin/events/create/${id}/event/${language}/theme/packs/auto-generate/${hex}/${opacity}/palettes`,
		ThemePackAutoGenerateSelectFont: "/admin/events/create/:id/event/:language/theme/packs/auto-generate/font",
		ThemePackAutoGenerateSelectFontLink: (id: string, language: string): string => `/admin/events/create/${id}/event/${language}/theme/packs/auto-generate/font`,
		ThemePackAutoGenerateAddFont: "/admin/events/create/:id/event/:language/theme/packs/auto-generate/font/new",
		ThemePackAutoGenerateAddFontLink: (id: string, language: string): string => `/admin/events/create/${id}/event/${language}/theme/packs/auto-generate/font/new`,
		ThemePackAutoGenerateSelectRadius: "/admin/events/create/:id/event/:language/theme/packs/auto-generate/radius",
		ThemePackAutoGenerateSelectRadiusLink: (id: string, language: string): string => `/admin/events/create/${id}/event/${language}/theme/packs/auto-generate/radius`,
		ThemePackSetManually: "/admin/events/create/:id/event/:language/theme/packs/manual/:pack?",
		ThemePackSetManuallyLink: (id: string, language: string, pack?: number): string => `/admin/events/create/${id}/event/${language}/theme/packs/manual${pack || pack === 0 ? `/${pack}` : ""}`,
		ThemePackSetManualFont: "/admin/events/create/:id/event/:language/theme/packs/manual/:pack/font",
		ThemePackSetManualFontLink: (id: string, language: string, pack: number): string => `/admin/events/create/${id}/event/${language}/theme/packs/manual/${pack}/font`,
		ThemePackSetManualAddFont: "/admin/events/create/:id/event/:language/theme/packs/manual/:pack/font/new",
		ThemePackSetManualAddFontLink: (id: string, language: string, pack: number): string => `/admin/events/create/${id}/event/${language}/theme/packs/manual/${pack}/font/new`,
		ThemePackSetManualBorderRadius: "/admin/events/create/:id/event/:language/theme/packs/manual/:pack/border-radius",
		ThemePackSetManualBorderRadiusLink: (id: string, language: string, pack: number): string => `/admin/events/create/${id}/event/${language}/theme/packs/manual/${pack}/border-radius`,

		// TODO: likely delete all these old theme color routes and their corresponding components
		ThemeColorPaletteAutoGenerate: "/admin/events/create/:id/event/:language/theme/palette/auto-generate",
		ThemeColorPaletteAutoGenerateLink: (id: string, language: string): string => `/admin/events/create/${id}/event/${language}/theme/palette/auto-generate`,
		ThemeColorPaletteAutoGeneratedSelector: "/admin/events/create/:id/event/:language/theme/palette/auto-generate/:hex/:opacity",
		ThemeColorPaletteAutoGeneratedSelectorLink: (id: string, language: string, hex: string, opacity: string) => `/admin/events/create/${id}/event/${language}/theme/palette/auto-generate/${hex}/${opacity}`,
		ThemeColorPaletteAutoGenerateCustomize: "/admin/events/create/:id/event/:language/theme/palette/auto-generate/customize",
		ThemeColorPaletteAutoGenerateCustomizeLink: (id: string, language: string): string => `/admin/events/create/${id}/event/${language}/theme/palette/auto-generate/customize`,
		ThemeColorPaletteNew: "/admin/events/create/:id/event/:language/theme/palette/new",
		ThemeColorPaletteNewLink: (id: string, language: string): string => `/admin/events/create/${id}/event/${language}/theme/palette/new`,
		ThemeColorPaletteEdit: "/admin/events/create/:id/event/:language/theme/palette/edit/:color_pack",
		ThemeColorPaletteEditLink: (id: string, language: string, colorPack: number): string => `/admin/events/create/${id}/event/${language}/theme/palette/edit/${colorPack}`,
		ThemeSession: "/admin/events/create/:id/event/theme/session/:session/:language",
		ThemeLink: (id: string, language?: string, editor?: string): string => `/admin/events/create/${id}/event/${language}/theme/${editor || ""}`,
		CustomPage: "/admin/events/create/:id/pages/:customPath/:language",
		CustomPageLink: (id: string, customPath: string, language: string): string => (`/admin/events/create/${id}/pages/${customPath}/${language}`),
		Social: "/admin/events/create/:id/social/:language",
		SocialLink: (id: string, language: string): string => `/admin/events/create/${id}/social/${language}`,
		EmailList: "/admin/events/create/:id/emails/:language",
		EmailListLink: (id: string, language: string) => `/admin/events/create/${id}/emails/${language}`,
		// FUTURE DEV: EmailDetails isnt used, however keeping for now until we add content editing to emails and decide if we want to use the details page
		EmailDetails: "/admin/events/create/:id/emails/:language/:scheduleUuid/:tab?",
		EmailDetailsLink: (id: string, language: string, scheduleUuid: string, tab: 'content' | 'settings'): string => `/admin/events/create/${id}/emails/${language}/${scheduleUuid}/${tab}`,
		Directory: "/admin/events/create/:id/social/:language/directory",
		DirectoryLink: (id: string, language: string): string => `/admin/events/create/${id}/social/${language}/directory`,
		[NavMainItems.Social]: (id: string, language: string): string => `/admin/events/create/${id}/social/${language}/directory`,
		SocialProfile: "/admin/events/create/:id/social/:language/profile/:tab?",
		SocialProfileLink: (id: string, language: string, tab?: string): string => `/admin/events/create/${id}/social/${language}/profile/${tab || "sections"}`,
		DirectMessagingLink: (id: string, language: string): string => `/admin/events/create/${id}/social/${language}/messaging`,
		DirectMessaging: "/admin/events/create/:id/social/:language/messaging",
		InPersonAttendanceSettingsLink: (id: string, language: string): string => `/admin/events/create/${id}/registration/${language}/settings/in-person-attendance`,
		InPersonAttendanceSettings: "/admin/events/create/:id/registration/:language/settings/in-person-attendance",
	},
	SessionBasePath: "/admin/events/create/:id/session/:session/:language?",
	Session: {
		Session: `${sessionBaseRoute}/:tab?`,
		SessionLink: (id: string, sessionId: string, language: string, tab?: string): string => `/admin/events/create/${id}/session/${sessionId}/${language}/${tab || "content"}`,
		// Panels: sessionPanelRoutes
	},
};

/*

Description: this function will look up a link based on the CURRENT params.
This is useful for navigating through the event editor. There's no need to track the uuid, language, etc params
As this will track those params for you. You only need to pass the name of the path.

USAGE:

const adminUrl = useGetAdminUrl();
...
<Link to={adminUrl(PATHNAMES.CreateEventFromTheme)}>Event</Link>

*/
export type GetAdminUrlProps = {
	path: string;
	customUuid?: string;
	page_module?: number | string;
	customPath?: string;
	session?: string;
}

export const useGetAdminUrl = (): ((props: GetAdminUrlProps) => string) => {
	const params = useParams<Record<string, string>>();

	return useCallback(({ path, customUuid, page_module, customPath, session }) => {
		page_module = page_module ? Number(page_module) : undefined;
		try {
			return generatePath(path, { ...params, customUuid, page_module, customPath, session: (params.session as string | undefined) || session });
		} catch (e) {
			console.error(e);
			return location.pathname;
		}
	}, [params]);
};

export const getCurrentCustomPage = (pages: CustomPage[] | undefined, currentPath: string | null): CustomPage | null => {
	if (!pages || !currentPath) return null;
	const page = pages.find((page) => page.path === currentPath && !page.deleted);
	return page || null;
};

export const generateCustomPathForEditor = (
	pageModuleGroup: PageModuleGroupModules,
) => {
	const customPath = (pageModuleGroup.name as TranslateString).base;
	return customPath.replace(/[^a-zA-Z0-9-]/g, '-').replace(/^-+|-+$/g, '');
};
